import React from "react";
import { View } from "react-native";
import { NavBar } from "apps/crypto-quiz/components";
import { StyleSheet } from "utils/StyleSheet";
import { COLORS } from "@remitano/remitano-ui-kit";
import FullDescriptionPopup from "./components/FullDescriptionPopup";
import GamePassedTabBar from "./components/GamePassedTabBar";
import GamePassedWrapper from "./components/GamePassedWrapper";

import Locale from "locale";
import GamePassedProvider from "apps/crypto-quiz/contexts/GamePassedContext";

const GamePassedScreen = () => {
  return (
    <GamePassedProvider>
      <NavBar title={Locale.translate("passed_game")} />
      <View style={styles.container}>
        <GamePassedTabBar />
        <GamePassedWrapper />
      </View>
      <FullDescriptionPopup />
    </GamePassedProvider>
  );
};

export default GamePassedScreen;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: COLORS.violet1000,
  },
});
