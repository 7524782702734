import { DeviceAction } from "../actions";
import { GenericAction } from "../types/actions";

const initialState = {
  isRemiBrowser: false,
  isRemiLiteBrowser: false,
  statusBarHeight: 20,
  bottomPadding: 0,
  isCustomModalReady: false,
};

const device = (state = initialState, action: GenericAction) => {
  switch (action.type) {
    case DeviceAction.REMITANO_BROWSER_READY:
      return {
        ...state,
        isRemiBrowser: true,
        statusBarHeight: action.data?.status_bar_height,
        bottomPadding: action.data?.bottom_padding,
      };
    case DeviceAction.REMITANO_LITE_BROWSER_READY:
      return {
        ...state,
        isRemiBrowser: true,
        isRemiLiteBrowser: true,
        statusBarHeight: action.data?.status_bar_height,
        bottomPadding: action.data?.bottom_padding,
      };
    case DeviceAction.UPDATE_DEVICE:
      return {
        ...state,
        ...action.data,
      };
    case DeviceAction.SET_CUSTOM_MODAL_READY:
      return {
        ...state,
        isCustomModalReady: action.data,
      };
    case DeviceAction.SHOW_CUSTOM_ALERT:
      return {
        ...state,
        alert: action.data,
      };
    case DeviceAction.SHOW_CONFIRM_MODAL:
      return {
        ...state,
        confirm: action.data,
      };
    default:
      return state;
  }
};

export default device;
