import { useEffect, useState } from "react";
import GamePlayService from "../services/GamePlayService";

type Props = {
  firstLoad?: boolean;
};

const useIncreaseTurn = ({ firstLoad = false }: Props): any => {
  const [loading, setLoading] = useState(firstLoad);
  const fetch = async (cb?: (isSuccess: boolean) => void) => {
    setLoading(true);
    const response = await GamePlayService.increaseTurn();
    if (response && response?.is_first_time_shared_today) {
      return cb?.(true);
    }
    cb?.(false);
    setLoading(false);
  };

  useEffect(() => {
    if (firstLoad) {
      fetch();
    }
  }, []);

  return {
    fetch,
    loading
  };
};

export default useIncreaseTurn;
