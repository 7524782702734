import { StyleSheet } from "utils/StyleSheet";
import COLORS from "utils/CommonColors";

const styles = StyleSheet.create({
  backgroundLayer: {
    flex: 1,
    backgroundColor: COLORS.violet1000,
  },
  container: {
    flex: 1,
    backgroundColor: COLORS.violet1000,
    paddingHorizontal: 16,
    paddingBottom: 20,
  },
  switchBoard: {
    marginTop: 12,
    marginHorizontal: 12,
  },
  personalRanking: {
    marginTop: 24,
    marginBottom: 27,
  },
  dateBox: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: COLORS.violet900,
    height: 64,
    paddingHorizontal: 24,
  },
  dateContent: {
    lineHeight: 18,
  },
  wrapperEmpty: {
    marginTop: 50
  }
});

export default styles;
