import COLORS from "utils/CommonColors";
import { StyleSheet } from "utils/StyleSheet";

const styles = StyleSheet.create({
  textContent: {
    color: COLORS.white,
    fontSize: 14,
    lineHeight: 18,
  },
});

export default styles;
