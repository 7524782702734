import React from "react";
import { useGamePlayContext } from "apps/crypto-quiz/contexts/GamePlayContext";
import QuestionBoard from "./QuestionBoard";

const Container = () => {
  const {
    result,
    answerChosen,
    currentQuestion,
    dataQuestion,
    loadingQuestion,
    onChoiseAnwser,
  } = useGamePlayContext();

  const isTimeout = Boolean(result);

  const composeProps = {
    onChoiseAnwser,
    result,
    answerChosen,
    isTimeout,
    currentQuestion,
    dataQuestion,
    loadingQuestion,
  };

  return <QuestionBoard {...composeProps} />;
};

export default Container;
