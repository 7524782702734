import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { GlobalState } from "stores/types/store";
import { Image, View, ViewStyle, Text } from "react-native";
import Ticker from "react-ticker";
import Locale from "locale";
import { RenderWithReact17 } from "render-with-react17/dist/src/RenderWithReact17";
import LuckyDrawService from "services/LuckyDrawService";
import { getRewardName } from "apps/spin-wheel/screens/Home/components/utils";
import styles from "./styles";

interface Props {
  style?: ViewStyle;
}

const GetListFromAPI = () => {
  const [list, setList] = useState([]);
  useEffect(() => {
    async function fetchData() {
      const nowTimeStamp = Math.floor(Date.now() / 1000);
      const ratesFromAPI = await LuckyDrawService.getJustWon(nowTimeStamp);
      setList(ratesFromAPI);
    }
    fetchData();
  }, []);

  return (
    <Text style={styles.runningText}>
      {list.map((item, index) => (
        <Text key={index} style={styles.runningText}>
          [{item["username"]}] {Locale.translate("just_won")}
          <Text style={styles.rewardText}>
            {" "}
            {getRewardName(
              { gift_type: item["gift_type"], gift_name: item["gift_name"] },
              { amount: item["amount"], currency: item["currency"] },
              false
            )}
          </Text>
        </Text>
      ))}
    </Text>
  );
};

const RunningNoti: React.FC<Props> = (props) => {
  const { isNewVersionEnabled } = useSelector(
    (state: GlobalState) => state.campaign
  );

  const newPage = React.useMemo(() => {
    if (isNewVersionEnabled) {
      return {
        containerStyle: styles.newContainer,
      };
    }
    return {
      containerStyle: styles.container,
    };
  }, [isNewVersionEnabled]);

  return (
    <View style={[newPage.containerStyle, props.style]}>
      <Image
        source={require("assets/spin-wheel/speaker.png")}
        style={styles.speakerIcon}
      />
      <View style={styles.tickerContainer}>
        <RenderWithReact17>
          <Ticker offset="run-in" speed={10}>
            {() => <GetListFromAPI />}
          </Ticker>
        </RenderWithReact17>
      </View>
    </View>
  );
};

export default React.memo(RunningNoti);
