import { Spacing } from "apps/crypto-quiz/utils";
import COLORS from "utils/CommonColors";
import { StyleSheet } from "utils/StyleSheet";

const styles = StyleSheet.create({
  container: {
    backgroundColor: COLORS.violet900,
    padding: 10,
    borderRadius: 10,
    marginBottom: 15,
  },
  gameImage: {
    marginLeft: "auto",
    marginRight: "auto",
    marginBottom: 10,
    width: 200,
    height: 110,
  },
  spacingMedium: {
    marginTop: Spacing.M,
  },
  titleSmall: {
    fontWeight: "700",
  },
  answerText: {
    fontWeight: "700",
    fontSize: 16,
  },
  gameExplain: {
    backgroundColor: COLORS.violet1000,
    borderRadius: 10,
    marginTop: 15,
    flexDirection: "row",
  },
  explainText: {
    fontWeight: "400",
    fontSize: 14,
  },
  readMore: {
    textDecorationLine: "underline",
    width: "100%",
    alignItems: "flex-end",
    position: "absolute",
    bottom: 17,
    right: 7,
  },
  readMoreText: {
    flexDirection: "row",
    alignItems: "center",
    position: "absolute",
    top: -10,
    backgroundColor: COLORS.violet1000,
  },
  italicStyle: {
    fontStyle: "italic",
  },
  lineHeightText: {
    lineHeight: 18,
  },
});

export default styles;
