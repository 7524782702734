import React from "react";
import { View } from "react-native";
import { animated, useSpring } from "@react-spring/native";
import styles from "./styles";
import ValidGiftList from "./ValidGiftList";
import HistoryGiftList from "./HistoryGiftList";

const AnimatedView = animated(View);

export interface RewardBoardRef {
  setShowHistory(arg: boolean): void;
}

const RewardBoard = React.forwardRef<RewardBoardRef, any>((props, ref) => {
  const [isShowHistory, switchHistory] = React.useState(false);

  React.useImperativeHandle(ref, () => ({
    setShowHistory: (arg: boolean) => {
      switchHistory(arg);
    },
  }));

  const fade = useSpring({
    opacity: !isShowHistory ? 0 : 1,
  });

  const fade2 = useSpring({
    opacity: isShowHistory ? 0 : 1,
  });

  if (isShowHistory)
    return (
      <AnimatedView
        style={{
          ...styles.historyConatainer,
          ...fade,
        }}
      >
        <HistoryGiftList />
      </AnimatedView>
    );

  return (
    <AnimatedView
      style={{
        ...styles.container,
        ...fade2,
      }}
    >
      <ValidGiftList />
    </AnimatedView>
  );
});
RewardBoard.displayName = "RewardBoard";

export default RewardBoard;
