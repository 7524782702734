import Store from "stores";
import { CampaignAction, UserAction } from "stores/actions";
// import AlertHelper from "utils/AlertHelper";
import RequestHelper from "utils/RequestHelper";

class UserService {
  getCurrentUser = async () => {
    try {
      const res = await RequestHelper.get("/api/v1/users/me");
      if (res?.testing_features?.new_luckydraw) {
        Store.dispatch({
          type: CampaignAction.UPDATE_NEW_VERSION_RELEASE,
          isNewVersionEnabled: true,
        });
      }
      Store.dispatch({
        type: UserAction.UPDATE_USER_INFO,
        data: res,
      });
      return res;
    } catch (error: any) {
      // AlertHelper.showError(error);
    }
  };
}

export default new UserService();
