import React from "react";
import { View, Text } from "react-native";
import { SvgIcon } from "base-components";
import Locale from "locale";
import { QText } from "apps/crypto-quiz/components";

import styles from "./style";

interface Props {
  rating: number;
}

const PersonalRanking: React.FC<Props> = ({ rating }) => {
  const getMedal = (rating: number) => {
    const svgName = rating < 6 ? `rank${rating}` : "other_rank";
    return <SvgIcon name={svgName} width={rating < 6 ? 106 : 84} height={83} />;
  };

  return (
    <View>
      <QText style={[styles.text, styles.space]}>
        {Locale.translate("your_rank")}
      </QText>
      <View style={styles.medal}>
        {getMedal(rating)}

        <View style={styles.rank}>
          <QText style={styles.text}>{rating}</QText>
        </View>
      </View>
    </View>
  );
};

export default PersonalRanking;
