import React from "react";
import { Image, View } from "react-native";
import { AuraSpin, NavBar } from "base-components";
import UserRanks from "./components/UserRanks";
import { IOScrollView } from "react-native-intersection-observer";
import LeaderboardFooter, { LeaderboardFooterRef } from "./components/LeaderboardFooter";
import MedalBoard from "./components/MedalBoard";
import styles from "./styles";
import { useSelector } from "react-redux";
import LuckyDrawService from "services/LuckyDrawService";
import { GlobalState } from "stores/types/store";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import Locale from "locale";
import UserService from "services/UserService";
import SW_SCREENS from "../info";

const LeaderboardScreen: React.FC = () => {
  const [loading, setLoading] = React.useState(true);
  const leaderboardFooterRef = React.useRef<LeaderboardFooterRef>(null);
  const { campaign_name } = useSelector((state: GlobalState) => state.campaign);
  const [currentDate, setCurrentDate] = React.useState(moment());
  const navigate = useNavigate();

  const isNextDisabled = moment().isoWeek() === currentDate.isoWeek();
  const isPrevDisabled = moment().isAfter(moment(currentDate).add(1, "month"));

  React.useEffect(() => {
    const fetchData = async () => {
      if (campaign_name) {
        await Promise.all([
          LuckyDrawService.getTokenRanking(currentDate.isoWeek()),
          UserService.getCurrentUser()
        ]);
        setLoading(false);
      }
    };
    fetchData();
  }, [campaign_name, currentDate]);

  const handleShowCurrentUser = (inView: boolean) => {
    leaderboardFooterRef?.current?.setInView(inView);
  };

  const onControl = (isNext: boolean) => {
    if (isNext) {
      setCurrentDate(moment(currentDate).add(7, "days"));
    } else {
      setCurrentDate(moment(currentDate).subtract(7, "days"));
    }
  };

  return (
    <View style={styles.container}>
      <NavBar title={Locale.translate("ranking_chart")} onBack={() => navigate(SW_SCREENS.HomeScreen.path)} />
      {!loading && (
        <View style={styles.flex1}>
          <IOScrollView style={styles.flex1}>
            <View style={styles.mt20}>
              <View style={styles.gemBox}>
                <Image source={require("assets/spin-wheel/big-gem.png")} style={styles.gem} />
                <AuraSpin icon="aura2" width={144} height={132} style={styles.aura} />
              </View>
              <MedalBoard />
              <UserRanks handleShowCurrentUser={handleShowCurrentUser} currentDate={currentDate} />
            </View>
          </IOScrollView>
          <LeaderboardFooter
            ref={leaderboardFooterRef}
            onControl={onControl}
            currentDate={currentDate}
            isNextDisabled={isNextDisabled}
            isPrevDisabled={isPrevDisabled}
          />
        </View>
      )}
    </View>
  );
};
export default LeaderboardScreen;
