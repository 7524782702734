import React, { useCallback, useState } from "react";
import { COLORS } from "@remitano/remitano-ui-kit";
import { View, ViewProps } from "react-native";
import { animated, useSpring } from "@react-spring/native";
import { scale, StyleSheet } from "utils/StyleSheet";

type Props = {
  borderColor?: string;
  borderRadius?: number | string;
  borderWidth?: number | string;
  color?: string;
  height?: number | string;
  width?: number | string;
  fillColor?: string;
  rounded?: boolean;
  progress?: number;
  duration?: number;
} & ViewProps;

const AnimatedView = animated(View);
const ProgressBar = ({
  borderColor = "#ffffff",
  rounded = false,
  width = "100%",
  borderRadius = 0,
  borderWidth = scale(3),
  children,
  height = scale(12),
  style,
  fillColor = COLORS.primaryA,
  progress = 0,
  duration = 0,
  ...restProps
}: Props) => {
  const [valuesState, setValuesState] = useState({
    width: 0,
  });

  const containerStyle: any = {
    width,
    height,
    borderWidth,
    borderColor: borderColor || "#ffffff",
    borderRadius: rounded ? scale(valuesState.width / 2) : borderRadius,
    backgroundColor: "#ffffff",
  };

  const fillMapper = useSpring({
    width: Math.min(progress, 100) + "%",
    backgroundColor: fillColor || COLORS.primaryA,
  });

  const handleLayout = useCallback((event: any) => {
    setValuesState((currentValue) => ({
      ...currentValue,
      width: event.nativeEvent.layout.width,
    }));
  }, []);

  return (
    <View
      style={[containerStyle, style, styles.container]}
      onLayout={handleLayout}
      {...restProps}
    >
      <AnimatedView style={{ ...fillMapper, ...styles.fillContent }} />
      {children}
    </View>
  );
};

export default React.memo(ProgressBar);

const styles = StyleSheet.create({
  container: {
    overflow: "hidden",
  },
  fillContent: {
    ...StyleSheet.absoluteFillObject,
    // height: "100%",
  },
});
