import COLORS from "utils/CommonColors";
import { StyleSheet } from "utils/StyleSheet";
export const PADDING_BOARD = 3;
const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    backgroundColor: COLORS.backgroundPDark2,
    borderRadius: 100,
    justifyContent: "space-between",
    alignItems: "center",
    padding: PADDING_BOARD,
  },
  item: {
    height: 38,
    justifyContent: "center",
    alignItems: "center",
    flex: 1,
    borderRadius: 100,
  },
  itemFocus: {
    position: "absolute",
    zIndex: 0,
    right: 0,
    top: 0,
    margin: PADDING_BOARD,
  },
  itemFocusContent: {
    backgroundColor: COLORS.purple,
    borderColor: COLORS.lightPurple200,
    width: "97%",
    height: 34,
    borderWidth: 2,
    borderStyle: "solid",
    borderRadius: 100,
  },

  title: {
    lineHeight: 20,
    width: "90%",
    textAlign: "center",
  },
});
export default styles;
