import React from "react";
import { View } from "react-native";
import styles from "./style";

const CardView: React.FC<any> = ({ children, ...restProps }) => {
  return (
    <View style={styles.container} {...restProps}>
      {children}
    </View>
  );
};

export default CardView;
