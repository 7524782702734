import React, { useState } from "react";
import { View, ViewStyle } from "react-native";
import { scale } from "utils/StyleSheet";
import { useOnEventCallback } from "utils/HookHelper";
import CacheImageService from "services/CacheImageService";
import "./styles.css";

interface Props {
  style?: ViewStyle;
  onShowReward: (arg: boolean) => void;
  isNewVersionEnabled?: boolean;
}

export interface WheelRef {
  play(index: number): void;
}

const Wheel: React.ForwardRefRenderFunction<any, Props> = (props, ref) => {
  const [turn, setTurn] = useState(0);
  const [loading, setLoading] = useState(false);
  const [selectedItem, setSelectedItem] = useState<number>();

  React.useImperativeHandle(ref, () => ({
    play: (rewardIndex: number) => {
      onPlaySpin(rewardIndex);
    },
  }));

  const getPosition = () => {
    return Math.random() * 0.8 + 0.1;
  };

  const onPlaySpin = useOnEventCallback(async (rewardIndex: number) => {
    if (loading) return;
    setTurn((t) => t + 1);
    setLoading(true);
    setSelectedItem(rewardIndex + getPosition());
  });

  const onStopSpin = () => {
    props.onShowReward(true);
    setLoading(false);
  };

  const wheelSize = scale(319);
  const wheelVars: any = {
    "--wheel-size": wheelSize,
    "--nb-item": 8,
    "--selected-item": selectedItem,
    "--nb-turn": turn * 4,
  };
  const pointerW = scale(48);
  const pointerH = scale(68);
  const spinning = selectedItem !== undefined ? "spinning" : "";

  const pageImage = React.useMemo(() => {
    if (props.isNewVersionEnabled) {
      return {
        imageWheel: CacheImageService.getCachedImg("new-wheel"),
        pointer: CacheImageService.getCachedImg("new-pointer"),
      };
    }
    return {
      imageWheel: CacheImageService.getCachedImg("wheel"),
      pointer: CacheImageService.getCachedImg("pointer"),
    };
  }, [props.isNewVersionEnabled]);

  return (
    <View style={props.style} pointerEvents="none">
      <div
        className="wheel-container"
        style={{
          ...wheelVars,
          width: wheelSize,
          height: wheelSize,
        }}
      >
        <img
          style={{
            ...wheelVars,
            width: wheelSize,
            height: wheelSize,
          }}
          className={`wheel ${spinning}`}
          onTransitionEnd={onStopSpin}
          src={pageImage.imageWheel}
          alt=""
        />

        <a
          className="spinner-tick"
          style={{
            top: wheelSize / 2 - pointerH / 2,
            left: wheelSize / 2 - pointerW / 2,
          }}
        >
          <img
            src={pageImage.pointer}
            style={{
              width: pointerW,
              height: pointerH,
            }}
          />
        </a>
      </div>
    </View>
  );
};

export default React.forwardRef(Wheel);
