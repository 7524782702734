import { scale, StyleSheet } from "utils/StyleSheet";
import COLORS from "utils/CommonColors";

const styles = StyleSheet.create({
  title: {
    fontWeight: "700",
    color: "white",
    fontSize: 14,
    lineHeight: 16,
    textShadowColor: "rgba(0, 0, 0, 0.15)",
    textShadowOffset: {
      width: scale(1),
      height: scale(2),
    },
  },
  titleDisabled: {
    color: COLORS.violet800,
    textShadowOffset: {
      width: 0,
      height: 0,
    },
  },
  contentMask: {
    ...StyleSheet.absoluteFillObject,
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: COLORS.purple,
    borderRadius: 8,
    borderWidth: 2,
    borderColor: COLORS.lightPurple200,
    boxShadow: `0 ${scale(2)}px 0 ${COLORS.shadowBoxBorder}`,
  },
  newContentMask: {
    ...StyleSheet.absoluteFillObject,
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#CE8DFE",
    borderRadius: 8,
    borderWidth: 2,
    borderColor: "#D99FFD",
    boxShadow: `0 ${scale(2)}px 0 #944CCF`,
  },
  contentMaskDisabled: {
    ...StyleSheet.absoluteFillObject,
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: COLORS.violet500,
    borderRadius: 8,
    borderWidth: 2,
    borderColor: COLORS.shadowButton,
    boxShadow: `0 ${scale(2)}px 0 ${COLORS.shadowButtonBorder}`,
  },
  newContentMaskDisabled: {
    ...StyleSheet.absoluteFillObject,
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#707A8A",
    borderRadius: 8,
    borderWidth: 1,
    borderColor: "#3F424B",
  },
});

export default styles;
