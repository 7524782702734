import { scale, StyleSheet } from "utils/StyleSheet";
const styles = StyleSheet.create({
  title: {
    fontWeight: "700",
    color: "white",
    fontSize: 14,
    lineHeight: 16,
    textShadowColor: "rgba(0, 0, 0, 0.15)",
    textShadowOffset: {
      width: scale(1),
      height: scale(2),
    },
  },
  contentMask: {
    ...StyleSheet.absoluteFillObject,
    alignItems: "center",
    justifyContent: "center",
  },
});

export default styles;
