import React, { PropsWithChildren, useContext, useMemo } from "react";
import useTurnGamePlay from "../hooks/useTurnGamePlay";

export const LIMIT_TIMER = 15;
export const DELAY_NEXT_QUESTION = 10000;
export const DELAY_SHOW_HINT = 500;
export const GAME_PLAY_CACHING = "game-play-caching";

export type QuizGlobalContextValue = {
  turn: number;
  isHasTurn: boolean;
  loadingTurn: boolean;
  onFetchTurnNumber?: () => void;
  minusTurn?: () => void;
  plusTurn?: () => void;
};

export const QuizGlobalContext = React.createContext<QuizGlobalContextValue>({
  turn: 0,
  isHasTurn: false,
  loadingTurn: false,
});

type Props = PropsWithChildren;

const QuizGlobalProvider = ({ children }: Props) => {
  const {
    isHasTurn,
    turn,
    loading: loadingTurn,
    fetch: fetchTurnNumber,
    minusTurn,
    plusTurn,
  } = useTurnGamePlay({});
  const valueContext: QuizGlobalContextValue = useMemo(
    () => ({
      turn,
      isHasTurn,
      loadingTurn,
      onFetchTurnNumber: fetchTurnNumber,
      minusTurn,
      plusTurn,
    }),
    [turn, isHasTurn, loadingTurn]
  );
  return (
    <QuizGlobalContext.Provider value={{ ...valueContext }}>
      {children}
    </QuizGlobalContext.Provider>
  );
};

export default QuizGlobalProvider;

export const useQuizGlobalContext = () => useContext(QuizGlobalContext);
