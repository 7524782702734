
import { StyleSheet } from "react-native";

const styles = StyleSheet.create({
  wrapperEmpty: {
    marginTop: 50
  }
});

export default styles;
