import { convertRank } from "apps/spin-wheel/screens/Leaderboard/components/utils";
import Store from "stores";
import { RankingAction, UserAction } from "stores/actions";
import { ICampaignContent } from "stores/types/campaign";
import AlertHelper from "utils/AlertHelper";
import RequestHelper, { REMITANO_MINI_GAME_DOMAIN } from "utils/RequestHelper";

export enum IGiftType {
  cashback_on_fee = "cashback_on_fee",
  renec = "renec", //3
  voucher = "voucher", //0
  coin_random = "coin_random", // 1,2,4,5
  spin = "spin", //6
  token = "token", //3
  good_luck = "good_luck", // 7
  bonus_apr = "bonus_apr",
  coin = "coin",
}

export type Currency = "shib" | "kai" | "doge" | "usdt";

export interface RewardParam {
  amount: number;
  currency: Currency;
}

export interface LuckTradeGift {
  gift_name: string;
  gift_type: IGiftType;
}

export interface IStartSpinRes {
  ref: string;
  lucky_trade_campaign_id: number;
  user_id: number;
  reward_params: RewardParam;
  expiry_timestamp: number;
  created_at_timestamp?: number;
  status: string;
  lucky_trade_gift: LuckTradeGift;
}

export type ILuckyTradeSpinReward = IStartSpinRes;

export interface IListMetaData {
  current_page: number;
  next_page?: number;
  per_page: number;
  prev_page?: number;
  total_items: number;
  total_pages: number;
}

export interface IRewardsRes {
  lucky_trade_spin_rewards: Array<ILuckyTradeSpinReward>;
  meta: IListMetaData;
}

export type LimitType = "daily";
export type MissionType =
  | "swap"
  | "login"
  | "share"
  | "lending"
  | "fiat"
  | "quiz"
  | "imota_wallet"
  | "social_follow"
  | "loyalty"
  | "coin_to_zarr"
  | "collect_zarr_badges"
  | "quick_buy_sell"
  | "collect_ng_badges"
  | "login_continuously"
  | "ng_trade"
  | "coin_savi";

export interface ILuckyTradeMission {
  completed_mission: number;
  limit_amount: number;
  limit_type: LimitType;
  mission_type: MissionType;
  path: string;
  spin_amount: number;
  mission_params: {
    min_volume?: number;
  };
  disabled?: boolean;
}

export interface IMissionsRes {
  lucky_trade_missions: Array<ILuckyTradeMission>;
  meta: IListMetaData;
}
export interface ICampaignRes {
  campaign_content: ICampaignContent;
  campaign_name: string;
}
export const shareUrl = `${REMITANO_MINI_GAME_DOMAIN}`;

class LuckyDrawService {
  lastApiCall = 0;

  getSpinTurn = async () => {
    try {
      const res = await RequestHelper.get("/api/v1/game_lucky_trades/spin");
      if (res && res.lucky_trade_spin_balance !== undefined) {
        Store.dispatch({
          type: UserAction.UPDATE_SPIN_TURN,
          data: res.lucky_trade_spin_balance,
        });
      }
      return res;
    } catch (error: any) {
      // AlertHelper.showError(error);
    }
  };

  startSpin = async (
    lucky_trade_spin_balance: number,
    accept_bad_luck?: boolean
  ): Promise<IStartSpinRes | undefined | false> => {
    const res = await RequestHelper.post("/api/v1/game_lucky_trades/spin", {
      accept_bad_luck,
    });
    Store.dispatch({
      type: UserAction.UPDATE_SPIN_TURN,
      data: lucky_trade_spin_balance - 1,
    });
    return res;
  };

  getSpinRewards = async ({
    page,
    available,
  }: {
    page?: number;
    available?: Boolean;
  }): Promise<IRewardsRes | undefined> => {
    try {
      const data: any = {};
      if (page) data.page = page;
      if (available) data.available = true;
      const res = await RequestHelper.get(
        "/api/v1/lucky_trade_spin_rewards",
        data
      );
      return res;
    } catch (error: any) {
      AlertHelper.showError(error);
    }
  };

  useSpinReward = async ({
    ref,
  }: {
    ref?: string;
  }): Promise<IRewardsRes | undefined> => {
    try {
      const res = await RequestHelper.post(
        `/api/v1/lucky_trade_spin_rewards/${ref}/use`
      );
      return res;
    } catch (error: any) {
      AlertHelper.showError(error);
    }
  };

  getTokenBalance = async (calendar_week_number?: number) => {
    try {
      const data: any = {};
      if (calendar_week_number)
        data.calendar_week_number = calendar_week_number;
      const res = await RequestHelper.get(
        "/api/v1/lucky_trade_spin_rewards/reward_token_balance",
        data
      );
      if (res && res.lucky_trade_token_balance !== undefined) {
        Store.dispatch({
          type: UserAction.UPDATE_TOKEN_BALANCE,
          data: res.lucky_trade_token_balance,
        });
      }
      return res;
    } catch (error: any) {
      AlertHelper.showError(error);
    }
  };

  getTokenRanking = async (week: number) => {
    try {
      const res = await RequestHelper.get(
        "/api/v1/lucky_trade_tokens/ranking",
        {
          calendar_week_number: week,
          lucky_trade_campaign_id: 1,
        }
      );
      Store.dispatch({
        type: RankingAction.UPDATE_RANK_LIST,
        data: {
          ...res,
          week,
          ranking: convertRank(res),
        },
      });
      return res;
    } catch (error: any) {
      AlertHelper.showError(error);
    }
  };

  getCampaign = async (): Promise<ICampaignRes | boolean> => {
    try {
      const res = await RequestHelper.get("/api/v1/game_lucky_trades/campaign");
      return res;
    } catch (error: any) {
      // AlertHelper.showError(error);
      return false;
    }
  };

  getJustWon = async (nowTimestamp: number) => {
    try {
      if (nowTimestamp - this.lastApiCall < 10) return [];
      const res = await RequestHelper.get("/api/v1/game_lucky_trades/just_won");
      this.lastApiCall = nowTimestamp;
      return res;
    } catch (error: any) {
      return [];
    }
  };

  completeMission = async (MissionType: string) => {
    try {
      const res = await RequestHelper.post(
        "/api/v1/game_lucky_trades/mission",
        { mission_type: MissionType }
      );
      return res;
    } catch (error: any) {
      // AlertHelper.showError(error);
    }
  };

  getMissions = async ({
    page,
    available,
  }: {
    page?: number;
    available?: Boolean;
  }): Promise<IMissionsRes | undefined> => {
    try {
      const data: any = {};
      if (page) data.page = page;
      if (available) data.available = true;
      const res = await RequestHelper.get("/api/v1/lucky_trade_missions", data);
      return res;
    } catch (error: any) {
      AlertHelper.showError(error);
    }
  };
}

export default new LuckyDrawService();
