import React from "react";
import { ScrollView, View, Image } from "react-native";
import { NavBar } from "apps/crypto-quiz/components";
import { QuizImages } from "apps/crypto-quiz/utils";
import HowToPlayItem from "./components/HowToPlayItem";
import Locale from "locale";
import styles from "./style";

const HowToPlayScreen: React.FC = () => {
  const lang = Locale.currLocale;
  return (
    <View style={{ flex: 1 }}>
      <NavBar title={Locale.translate("how_to_play")} hiddenIconRight />

      <ScrollView style={styles.container}>
        <View style={styles.imgView}>
          <Image
            source={lang === "vi" ? QuizImages.bgHowToPlayVi : QuizImages.bgHowToPlayEn}
            style={styles.imgBackground}
          ></Image>
        </View>

        <View style={styles.cardView}>

          <HowToPlayItem />
        </View>
      </ScrollView>
    </View>
  );
};

export default HowToPlayScreen;
