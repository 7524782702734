import React, { useCallback, useEffect } from "react";
import { useSelector } from "react-redux";
import { GlobalState } from "stores/types/store";
import QUIZ_SCREENS from "apps/crypto-quiz/screens/info";
import { useNavigate } from "react-router-dom";
import LandingWrapper from "./LandingWrapper";
import { GAME_PLAY_CACHING } from "apps/crypto-quiz/contexts/GamePlayContext";
import QAlertHelper from "apps/crypto-quiz/utils/AlertHelper";
import useSyncDataGamePlay from "apps/crypto-quiz/hooks/useSyncDataGamePlay";
import { SHARE_KEY_STORE } from "apps/crypto-quiz/screens/Share/container";
import RemitanoBrowser from "utils/RemitanoBrowser";
import Locale from "locale";
import { useQuizGlobalContext } from "apps/crypto-quiz/contexts/QuizGlobalContext";
import { useQuizAuthContext } from "apps/crypto-quiz/contexts/QuizAuthContext";

const Container = () => {
  const navigate = useNavigate();

  const { isHasTurn, turn, loadingTurn, onFetchTurnNumber } =
    useQuizGlobalContext();
  const { isSyncSuccess } = useSyncDataGamePlay();
  const { isRemiBrowser } = useSelector((state: GlobalState) => state.device);

  const { userId, loading: loadingUser } = useQuizAuthContext();
  const lang = Locale.currLocale;

  const handleGoChart = useCallback(() => {
    navigate(QUIZ_SCREENS.RankingChartScreen.path);
  }, []);

  useEffect(() => {
    if (isSyncSuccess) {
      onFetchTurnNumber?.();
    }
  }, [isSyncSuccess]);

  const hanldePlayNow = useCallback(async () => {
    if (loadingTurn || loadingUser) return;
    if (userId) {
      if (isHasTurn) {
        navigate(QUIZ_SCREENS.GamePlayScreen.path);
      } else {
        navigate(QUIZ_SCREENS.ShareScreen.path);
      }
    } else {
      if (localStorage.getItem(GAME_PLAY_CACHING)) {
        QAlertHelper.showModalAlert({ actionKey: "login-required" });
      } else {
        navigate(QUIZ_SCREENS.GamePlayScreen.path);
      }
    }
  }, [isHasTurn, userId, loadingUser, loadingTurn]);

  const handleGoGamePassed = useCallback(() => {
    navigate(QUIZ_SCREENS.GamePassedScreen.path);
  }, []);

  const shareSuccess = Boolean(localStorage.getItem(SHARE_KEY_STORE));
  const handleBack = () => {
    if (isRemiBrowser) {
      RemitanoBrowser.closeBrowser();
    }
  };

  const composeProps = {
    onGoCharts: handleGoChart,
    onPlayNow: hanldePlayNow,
    onGoGamePassed: handleGoGamePassed,
    onBack: handleBack,
    userId,
    shareSuccess,
    lang,
    turn,
    loadingTurn,
    loadingUser,
  };

  return <LandingWrapper {...composeProps} />;
};

export default Container;
