const SW_SCREENS = {
  SplashScreen: {
    title: "Splash Screen",
    path: "/",
  },
  HomeScreen: {
    title: "Home Screen",
    path: "/",
  },
  MyRewardScreen: {
    title: "My reward Screen",
    path: "/my-reward",
  },
  LeaderboardScreen: {
    title: "Leaderboard Screen",
    path: "/leaderboard",
  },
  GameRulesScreen: {
    title: "Game Rules Screen",
    path: "/game-rules",
  },
};

export default SW_SCREENS;
