import React, { useMemo } from "react";
import { View } from "react-native";
import UserRankItem from "../UserRankItem";
import { InView } from "react-native-intersection-observer";
import styles from "./styles";
import { GlobalState } from "stores/types/store";
import { useSelector } from "react-redux";
import { Ranking } from "stores/types/ranking";
import { Moment } from "moment";

interface Props {
  handleShowCurrentUser: (inView: boolean) => void;
  currentDate: Moment;
}

const UserRanks: React.FC<Props> = ({ handleShowCurrentUser, currentDate }) => {
  const { ranking } = useSelector((state: GlobalState) => state.ranking);
  const currentWeek = currentDate.isoWeek();
  const { isNewVersionEnabled } = useSelector(
    (state: GlobalState) => state.campaign
  );

  const newPage = useMemo(() => {
    if (isNewVersionEnabled) {
      return {
        containerStyle: styles.newContainer,
      };
    }
    return {
      containerStyle: styles.container,
    };
  }, [isNewVersionEnabled]);

  return (
    <View style={newPage.containerStyle}>
      {ranking[currentWeek]?.map((item: Ranking, index: number) =>
        item.isLocal ? (
          <InView
            key={item.rating}
            onChange={(inView: boolean) => {
              handleShowCurrentUser(inView);
            }}
          >
            <UserRankItem
              rankInfo={item}
              index={index}
              currentDate={currentDate}
              isNewVersionEnabled={isNewVersionEnabled}
            />
          </InView>
        ) : (
          <UserRankItem
            rankInfo={item}
            key={item.rating}
            currentDate={currentDate}
            isNewVersionEnabled={isNewVersionEnabled}
          />
        )
      )}
    </View>
  );
};

export default UserRanks;
