import React from "react";
import { ImageBackground, View, ViewStyle } from "react-native";
import CacheImageService from "services/CacheImageService";
import styles from "./styles";
import "./style.css";
import { SText } from "base-components";
import { GlobalState } from "stores/types/store";
import { useSelector } from "react-redux";

interface Props {
  title?: string | null;
  children?: any;
  style?: ViewStyle;
  imageSource?: any;
}

const Board = (props: Props) => {
  const { title, children, style } = props;

  const { isNewVersionEnabled } = useSelector(
    (state: GlobalState) => state.campaign
  );
  const newPage = React.useMemo(() => {
    if (isNewVersionEnabled) {
      return {
        imageSource: CacheImageService.getCachedImg("new-table-header"),
        headerStyle: styles.newHeader,
        titleStyle: styles.newTitle,
        boardContainerClass: "new-board-container",
      };
    }
    return {
      imageSource: CacheImageService.getCachedImg("table-header"),
      headerStyle: styles.header,
      titleStyle: styles.title,
      boardContainerClass: "board-container",
    };
  }, [isNewVersionEnabled]);

  return (
    <View style={[styles.container, style]}>
      <View style={styles.hr} />
      <div className={newPage.boardContainerClass}>
        {title && (
          <ImageBackground
            source={{ uri: newPage.imageSource }}
            style={newPage.headerStyle}
          >
            <SText style={newPage.titleStyle} bold>
              {title}
            </SText>
          </ImageBackground>
        )}
        {children}
      </div>
    </View>
  );
};

export default Board;
