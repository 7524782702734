import React, { useMemo } from "react";
import { ActivityIndicator, FlatList } from "react-native";
import styles from "./styles";
import LuckyDrawService, {
  ILuckyTradeSpinReward,
} from "services/LuckyDrawService";
import HistoryGiftItem from "../HistoryGiftItem";
import { useSelector } from "react-redux";
import { GlobalState } from "stores/types/store";

interface Props {
  foo?: string;
}

const HistoryGiftList = (props: Props) => {
  const [loading, setLoading] = React.useState(true);
  const [rewards, setRewards] = React.useState<ILuckyTradeSpinReward[]>([]);
  const [nextPage, setNextPage] = React.useState<number | undefined>(0);
  const { campaign_name, isNewVersionEnabled } = useSelector(
    (state: GlobalState) => state.campaign
  );

  React.useEffect(() => {
    fetchData({});
  }, []);

  const fetchData = React.useCallback(
    async ({ page }: { page?: number }) => {
      if (!campaign_name) return;
      try {
        const res = await LuckyDrawService.getSpinRewards({ page });
        if (res?.lucky_trade_spin_rewards) {
          let newData = !page ? [] : rewards;
          newData = newData.concat(res?.lucky_trade_spin_rewards);
          setRewards(newData);
        }
        if (res?.meta) {
          setNextPage(res?.meta?.next_page);
        }
      } finally {
        setLoading(false);
      }
    },
    [campaign_name, rewards]
  );

  const newPage = useMemo(() => {
    if (isNewVersionEnabled) {
      return {
        containerStyle: styles.newHistoryContentContainer,
      };
    }
    return {
      containerStyle: styles.historyContentContainer,
    };
  }, [isNewVersionEnabled]);

  const onEndReached = React.useCallback(() => {
    if (nextPage) fetchData({ page: nextPage });
  }, [fetchData, nextPage]);

  const renderItem = ({
    item,
    index,
  }: {
    item: ILuckyTradeSpinReward;
    index: number;
  }) => {
    return (
      <HistoryGiftItem
        item={item}
        index={index}
        isNewVersionEnabled={isNewVersionEnabled}
      />
    );
  };

  if (loading) return <ActivityIndicator />;

  return (
    <FlatList
      data={rewards}
      style={newPage.containerStyle}
      keyExtractor={(_, index) => `history_gift_${index}`}
      renderItem={renderItem}
      onEndReached={onEndReached}
      onEndReachedThreshold={1}
      showsVerticalScrollIndicator={false}
    />
  );
};

export default HistoryGiftList;
