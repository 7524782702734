import React from "react";
import { ScrollView } from "react-native";
import { COLORS } from "@remitano/remitano-ui-kit";
import { ModalQuiz, QText } from "apps/crypto-quiz/components";
import DeviceInfo from "utils/DeviceInfo";
import { scale } from "utils/StyleSheet";

import styles from "./styles";


type Props = {
  hint?: string,
  onClose?: VoidFunction,
  isOpen?: boolean
};

const HintModal: React.FC<Props> = ({
  hint = "",
  onClose,
  isOpen = false
}) => {
  return (
    <ModalQuiz styleContent={styles.modal} width={DeviceInfo.CONTENT_WIDTH - scale(48)} isOpen={isOpen} onClose={onClose} bgContent={COLORS.violet1000} >
      <ScrollView style={styles.contentHint}>
        <QText>
          {hint}
        </QText>
      </ScrollView>
    </ModalQuiz>
  );
};

export default HintModal;
