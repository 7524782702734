import React, { useEffect, useState } from "react";
import { View, Text, ScrollView } from "react-native";
import { Spacing } from "../../utils";
import COLORS from "utils/CommonColors";
import { scale, StyleSheet } from "utils/StyleSheet";
import DeviceInfo from "utils/DeviceInfo";

import { SvgIcon } from "base-components";
import { QText } from "apps/crypto-quiz/components";
import { ProgressBar } from "../../components/Progess";
import ProcessCircle from "../../components/Progess/Circle";
import { ButtonQuiz } from "../../components/Button";
import { BoxView, ModalQuiz } from "../../components";

const OverviewScreen: React.FC<any> = () => {
  const [tick, setTick] = useState(0);
  const [isShow, setIsShow] = useState(false);

  useEffect(() => {
    const timeOut = setTimeout(() => {
      setTick((currentValue) => (currentValue += 2));
    }, 50);
    if (tick === 100) {
      clearTimeout(timeOut);
    }
    return () => clearTimeout(timeOut);
  }, [tick]);

  return (
    <View style={{ flex: 1 }}>
      <ScrollView style={styles.container}>
        <View style={{ height: Spacing.M }} />
        <QText color="#111111">Button</QText>
        <View style={{ height: Spacing.M }} />
        <ButtonQuiz title="Primary" />
        <View style={{ height: Spacing.M }} />
        <ButtonQuiz variant="dark" title="Darkness" />
        <View style={{ height: Spacing.M }} />
        <ButtonQuiz block variant="dark" title="Block Darkness" />
        <View style={{ height: Spacing.M }} />
        <ButtonQuiz block>
          <View
            style={{
              flex: 1,
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            Chơi ngay (-1 <SvgIcon name="heart" size={20} />)
          </View>
        </ButtonQuiz>
        <View style={{ marginTop: Spacing.M }} />
        <QText color="#111111" text="Progess bar" bold></QText>
        <View style={{ marginTop: Spacing.M }} />
        <View style={{ backgroundColor: "#dfdfdf", padding: 10 }}>
          <ProgressBar
            rounded
            width={"100%"}
            progress={tick}
            fillColor={COLORS.primaryA}
          />
        </View>
        <View style={{ marginTop: Spacing.M }} />
        <View style={{ flex: 1, alignItems: "center" }}>
          <ProcessCircle />
        </View>
        <View style={{ marginTop: Spacing.M }} />
        <QText color="#111111" bold>
          Modal
        </QText>
        <ButtonQuiz
          block
          onPress={() => {
            setIsShow(true);
          }}
          variant="dark"
          title="Open Modal"
        />
        <View style={{ marginTop: Spacing.M }} />
        <QText color="#111111" bold>
          Box View
        </QText>
        <Text style={styles.label}>Variant: None</Text>
        <BoxView
          onPress={() => {
            alert(3);
          }}
        >
          <Text style={styles.text}>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Quasi eius
            exercitationem dicta illo provident deleniti eaque vero! Provident
            repellat nemo deleniti cum maiores facere eius adipisci cupiditate.
            Accusantium, dicta minus?
          </Text>
        </BoxView>
        <Text style={styles.label}>Variant: Disable</Text>
        <BoxView variant="disabled">
          <Text style={styles.text}>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Quasi eius
            exercitationem dicta illo provident deleniti eaque vero! Provident
            repellat nemo deleniti cum maiores facere eius adipisci cupiditate.
            Accusantium, dicta minus?
          </Text>
        </BoxView>
        <View style={styles.space}></View>
        <Text style={styles.label}>Variant: active</Text>
        <BoxView variant="active">
          <Text style={styles.text}>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Quasi eius
            exercitationem dicta illo provident deleniti eaque vero! Provident
            repellat nemo deleniti cum maiores facere eius adipisci cupiditate.
            Accusantium, dicta minus?
          </Text>
        </BoxView>
        <View style={styles.space}></View>
        <Text style={styles.label}>Variant: error</Text>
        <BoxView variant="error">
          <Text style={styles.text}>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Quasi eius
            exercitationem dicta illo provident deleniti eaque vero! Provident
            repellat nemo deleniti cum maiores facere eius adipisci cupiditate.
            Accusantium, dicta minus?
          </Text>
        </BoxView>
        <View style={styles.space}></View>
        <Text style={styles.label}>Variant: normal</Text>
        <BoxView variant="normal">
          <Text style={styles.text}>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Quasi eius
            exercitationem dicta illo provident deleniti eaque vero! Provident
            repellat nemo deleniti cum maiores facere eius adipisci cupiditate.
            Accusantium, dicta minus?
          </Text>
        </BoxView>
        <View style={styles.space}></View>
        <Text style={styles.label}>Variant: success</Text>
        <BoxView variant="success">
          <Text style={styles.text}>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Quasi eius
            exercitationem dicta illo provident deleniti eaque vero! Provident
            repellat nemo deleniti cum maiores facere eius adipisci cupiditate.
            Accusantium, dicta minus?
          </Text>
        </BoxView>
      </ScrollView>
      <ModalQuiz
        overlayTriggerClose
        width={DeviceInfo.CONTENT_WIDTH - scale(30)}
        isOpen={isShow}
        onClose={() => {
          setIsShow(false);
        }}
        bgContent={COLORS.violet1000}
      >
        <ScrollView>
          <Text style={styles.text}>
            Bitcoin là một loại tiền mã hóa, được phát minh bởi một cá nhân hoặc
            tổ chức vô danh dùng tên Satoshi Nakamoto dưới dạng phần mềm mã
            nguồn mở từ năm 2009. Bitcoin có thể được trao đổi trực tiếp bằng
            thiết bị kết nối Internet mà không cần thông qua một tổ chức tài
            chính trung gian nào
          </Text>
        </ScrollView>
      </ModalQuiz>
    </View>
  );
};

export default OverviewScreen;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#ffffff",
    height: "100%",
    paddingBottom: 10,
    paddingHorizontal: 5,
  },
  label: {
    color: "#111111",
    fontSize: 15,
  },
  text: {
    color: "#ffffff",
    fontSize: 14,
  },
  space: {
    paddingVertical: Spacing.XS,
  },
});
