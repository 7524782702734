import { StyleSheet } from "react-native";
import DeviceInfo from "utils/DeviceInfo";
import { scale } from "utils/StyleSheet";
const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#938db3",
  },
  imageBg: {
    width: DeviceInfo.CONTENT_WIDTH,
    height: DeviceInfo.CONTENT_WIDTH * 1.92,
    resizeMode: "contain",
  },
  progressBar: {
    alignItems: "center",
    position: "absolute",
    bottom: scale(30),
    width: "100%",
  },
  bottomBg: {
    position: "absolute",
    bottom: scale(0),
    width: "100%",
    height: scale(190),
  },
});

export default styles;
