export const shareUrl = "https://crypto-quiz.remitano.com/";
// export const shareUrl = `${REMITANO_DOMAIN}/gamehub/2`;

declare let window: any;

function initFacebookSdk() {
  return new Promise(() => {
    // wait for facebook sdk to initialize before starting the react app
    window.fbAsyncInit = function () {
      window.FB.init({
        appId: process.env.REACT_APP_FACEBOOK_APP_ID || "1430730310625089",
        cookie: true,
        xfbml: true,
        version: "v8.0",
      });
    };

    // load facebook sdk script
    (function (d, s, id) {
      let js: any = d.getElementsByTagName(s)[0];
      const fjs: any = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {
        return;
      }
      js = d.createElement(s);
      js.id = id;
      js.src = "https://connect.facebook.net/en_US/sdk.js";
      fjs.parentNode.insertBefore(js, fjs);
    })(document, "script", "facebook-jssdk");
  });
}

function shareFacebook() {
  return new Promise((resolve) => {
    if (!window.FB) return resolve(false);
    window.FB.ui(
      {
        method: "share",
        href: shareUrl,
      },
      function (response: any) {
        if (response) {
          resolve(true);
        } else {
          resolve(false);
        }
      }
    );
  });
}

const SocialUtil = {
  initFacebookSdk,
  shareFacebook,
};

export default SocialUtil;

