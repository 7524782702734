import React from "react";
import { View } from "react-native";
import { ButtonQuiz, QText } from "apps/crypto-quiz/components";
import { PopupWithIcon } from "apps/crypto-quiz/components/popups";
import styles from "./styles";
import Locale from "locale";

type Props = {
  isOpen?: boolean;
  onClose: VoidFunction;
  onPlayNow: VoidFunction;
  onGoGamePassed: VoidFunction;
};

const ShareSuccessPopup: React.FC<Props> = ({
  isOpen,
  onClose,
  onPlayNow,
  onGoGamePassed,
}) => {
  return (
    <PopupWithIcon isOpen={isOpen} onClose={onClose}>
      <View style={styles.container}>
        <QText style={styles.congraText} bold>
          Chúc mừng bạn có lượt chơi mới!
        </QText>
        <ButtonQuiz
          height={44}
          style={styles.btnContinue}
          block
          title={Locale.translate("continue_play")}
          onPress={() => {
            onPlayNow();
            onClose();
          }}
        />
        <ButtonQuiz
          onPress={() => {
            onGoGamePassed();
            onClose();
          }}
          height={44}
          style={styles.btnReview}
          block
          title={Locale.translate("passed_game")}
          variant="text"
        />
      </View>
    </PopupWithIcon>
  );
};

export default React.memo(ShareSuccessPopup);
