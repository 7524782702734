import { SHARE_KEY_STORE } from "apps/crypto-quiz/screens/Share/container";
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ShareSuccessPopup from "./ShareSuccessPopup";

type Props = {
  isOpen: boolean;
  onPlayNow: VoidFunction;
  onGoGamePassed: VoidFunction;
};

const Container: React.FC<Props> = ({ isOpen, onPlayNow, onGoGamePassed }) => {
  const [isOpenLocal, setOpenLocal] = useState(false);

  useEffect(() => {
    setOpenLocal(isOpen);
  }, [isOpen]);

  const handleClose = useCallback(() => {
    localStorage.removeItem(SHARE_KEY_STORE);
    setOpenLocal(false);
  }, []);

  const composeProps = {
    isOpen: isOpenLocal,
    onClose: handleClose,
    onPlayNow,
    onGoGamePassed,
  };

  return <ShareSuccessPopup {...composeProps} />;
};

export default Container;
