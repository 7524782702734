import { StyleSheet } from "utils/StyleSheet";
import DeviceInfo from "utils/DeviceInfo";
const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  gemBox: {
    display: "flex",
    alignItems: "center",
  },
  gem: {
    width: 47,
    height: 68,
    zIndex: 1,
  },
  aura: {
    position: "absolute",
    zIndex: 0,
    alignItems: "center",
    top: -24,
    left: 0,
    right: 0,
  },
  flex1: {
    flex: 1,
  },
  mt20: {
    marginTop: 20,
  },
});
export default styles;
