import { StyleSheet } from "utils/StyleSheet";
import { StyleSheet as RNStyleSheet } from "react-native";

const styles = StyleSheet.create({
  container: {
    width: "100%",
    paddingTop: 30,
  },
  boardBg: {
    width: 329,
    height: 103,
    alignSelf: "center",
  },
  newBoardBg: {
    width: 375,
    height: 325,
    alignSelf: "center",
    marginTop: -30,
  },
  contentContainer: {
    ...RNStyleSheet.absoluteFillObject,
  },
  newContentContainer: {
    ...RNStyleSheet.absoluteFillObject,
    top: 128
  },
  footerTitle: {
    height: 17,
    alignItems: "center",
    justifyContent: "center",
  },
  playButtonBox: {
    flex: 1,
    paddingBottom: 6,
    alignItems: "center",
    justifyContent: "flex-end",
  },
  newPlayButtonBox: {
    alignItems: "center",
    justifyContent: "flex-end",
  },
});
export default styles;
