import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import UserService from "services/UserService";
import { GlobalState } from "stores/types/store";

const useAuth = ({ initLoad = false }: { initLoad?: boolean }) => {
  const { id: userId, username } = useSelector(
    (state: GlobalState) => state.user
  );
  const [loading, setLoading] = useState(initLoad);
  const fetch = async () => {
    setLoading(true);
    if (!userId) await UserService.getCurrentUser();
    setLoading(false);
  };

  useEffect(() => {
    if (initLoad) {
      fetch();
    }
  }, []);
  
  return {
    fetch,
    loading,
    userId,
    username,
  };
};

export default useAuth;
