import { COLORS } from "@remitano/remitano-ui-kit";
import { StyleSheet } from "utils/StyleSheet";

const styles = StyleSheet.create({
  content: {
    paddingHorizontal: 15,
    marginTop: 13,
    flex: 1,
  },
});

export default styles;
