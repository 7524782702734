import { Spacing } from "apps/crypto-quiz/utils";
import { StyleSheet } from "utils/StyleSheet";

const styles = StyleSheet.create({
  headWrapper: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
  headIndex: {
    alignItems: "center",
    flexDirection: "row",
  },
  wrapperImage: {
    flexDirection: "row",
    justifyContent: "center",
    marginTop: 24,
  },
  imageBg: {
    width: 200,
    height: 110,
  },
  questionWrapper: {
    marginTop: 24,
  },
  questionContent: {
    paddingTop: Spacing.S,
    fontWeight: "700",
  },
  questionIndex: {
    fontSize: 14,
    fontWeight: "700",
  },
  answerWrapperDone: {
    marginTop: 8,
  },
  answerItem: {
    marginTop: Spacing.M,
    paddingHorizontal: 16,
    paddingVertical: 14,
    minHeight: 76,
    justifyContent: "center",
  },
  answerText: {
    fontSize: 16,
    fontWeight: "700",
  },
  wrapperEmpty: {
    marginTop: 60,
  }
});

export default styles;
