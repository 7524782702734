import React, {
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
} from "react";
import { useContextState } from "@remitano/remitano-ui-kit";
import useGamePassedData from "apps/crypto-quiz/hooks/useGamePassed";

export type GamePassedContextValue = {
  isShowPopupFullDescription?: boolean;
  onShowPopupFullDescription?: (status: boolean) => void;
  activeTab?: number;
  onSetActiveTab?: (activeIndex: number) => void;
  onSetPopupContent?: (content: string) => void;
  popupContent: string;
} | null;

export const GamePassedContext =
  React.createContext<GamePassedContextValue>(null);

const GamePassedProvider = ({ children }: PropsWithChildren) => {
  const { values, updateKey } = useContextState({
    isShowPopuFullDescription: false,
    activeTab: 0,
    popupContent: "",
    passedsGame: [],
  });

  const { passedsGame, loading: loadingGamePassed } = useGamePassedData({});

  useEffect(() => {
    if (passedsGame && passedsGame.length) {
      updateKey("passedsGame", passedsGame);
    }
  }, [passedsGame]);

  const handlePopupFullDescription = useCallback((status: boolean) => {
    updateKey("isShowPopupFullDescription", status);
  }, []);

  const handleSetActiveTab = useCallback((activeIndex: number) => {
    updateKey("activeTab", activeIndex);
  }, []);

  const handleSetPopupContent = useCallback((content: string) => {
    updateKey("popupContent", content);
  }, []);

  const valueContext: GamePassedContextValue = useMemo(
    () => ({
      ...values,
      loadingGamePassed,
      onShowPopupFullDescription: handlePopupFullDescription,
      onSetActiveTab: handleSetActiveTab,
      onSetPopupContent: handleSetPopupContent,
    }),
    [values, loadingGamePassed]
  );
  return (
    <GamePassedContext.Provider value={valueContext}>
      {children}
    </GamePassedContext.Provider>
  );
};

export default GamePassedProvider;

export const useGamePassedContext = () => useContext<any>(GamePassedContext);
