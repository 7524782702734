import { StyleSheet } from "utils/StyleSheet";
import COLORS from "utils/CommonColors";

const styles = StyleSheet.create({
  view: {
    marginBottom: 12,
    paddingBottom: 20
  },
  title: {
    fontWeight: "bold",
    color: COLORS.white,
    fontSize: 16,
    marginBottom: 9,
    lineHeight: 18,
    marginHorizontal: 12,
    marginVertical: 16,
  },
  description: {
    color: COLORS.white,
    fontSize: 14,
    lineHeight: 18,
    marginHorizontal: 12,
    marginBottom: 5,
  },
  h10: {
    height: 10
  },
  italic: {
    fontStyle: "italic"
  }
});

export default styles;
