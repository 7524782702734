import React from "react";
import DeviceInfo from "utils/DeviceInfo";
import { scale } from "utils/StyleSheet";
import QuizModal from "../Modal";
import COLORS from "utils/CommonColors";
import QText from "../QText";
import usePopupAlert from "apps/crypto-quiz/hooks/commons/usePopupAlert";

type ModalProps = {
  children?: React.ReactNode;
  width?: number;
};

const PopupAlert: React.FC<ModalProps> = ({ children, width }) => {
  const { onClose, confirm } = usePopupAlert();
  return (
    <QuizModal
      onClose={onClose}
      bgContent={COLORS.violet1000}
      isOpen={confirm?.actionKey === "alert"  && Boolean(confirm && Object.keys(confirm).length)}
      width={width}
    >
      <>
        {Boolean(confirm?.title) && <QText bold>{confirm?.title}</QText>}
        {Boolean(confirm?.message) && <QText>{confirm?.message}</QText>}
      </>
      {children}
    </QuizModal>
  );
};

PopupAlert.defaultProps = {
  width: DeviceInfo.CONTENT_WIDTH - scale(48),
};

export default React.memo(PopupAlert);
