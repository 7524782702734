import { StyleSheet } from "utils/StyleSheet";
import COLORS from "utils/CommonColors";

const styles = StyleSheet.create({
  dateBox: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: COLORS.violet800,
    height: 64,
    paddingHorizontal: 24,
  },
  dateContent: {
    alignItems: "center",
    justifyContent: "center",
    fontSize: 16,
    fontWeight: "700",
    color: COLORS.white,
  },
});

export default styles;
