interface Rank {
  balance: number;
  username: string;
}

interface Ranking {
  current_rank: number;
  ranking: Rank[];
  current_balance: number;
}

export const convertRank = (data: Ranking) => {
  return data.ranking.map((rank: Rank, key: number) => ({
    rating: key + 1,
    name: rank.username,
    balance: rank.balance,
    isLocal: data.current_rank === key + 1,
  }));
};
