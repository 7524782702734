import { StyleSheet } from "utils/StyleSheet";
import COLORS from "utils/CommonColors";

const styles = StyleSheet.create({
  container: {
    backgroundColor: COLORS.violet900,
    borderRadius: 12,
    overflow: "hidden",
  }
});

export default styles;
