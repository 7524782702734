import { StyleSheet } from "utils/StyleSheet";
import COLORS from "utils/CommonColors";

const styles = StyleSheet.create({
  userName: {
    lineHeight: 20,
  },
  text: {
    fontSize: 16,
    fontWeight: "700",
    color: COLORS.white,
  },
  container: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    backgroundColor: COLORS.violet900,
    paddingBottom: 8,
    paddingTop: 8,
  },
  currentUser: {
    color: COLORS.black,
    backgroundColor: COLORS.yellow100,
    bottom: 0,
  },
  colorBlack: {
    color: COLORS.black,
  },
  content: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    paddingHorizontal: 12,
  },
  stone: {
    marginTop: 3,
    marginLeft: 3,
  },
  name: {
    width: 180,
    paddingLeft: 12,
  },
  itMe: {
    lineHeight: 25,
  },
  ratingContent: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexBasis: 27,
    flexGrow: 1
  },
  rating: {
    color: COLORS.lightPurple500,
    textAlign: "center",
    fontSize: 16,
  },
  currentRating: {
    color: COLORS.white,
  },
  devider: {
    borderBottomColor: COLORS.violet800,
    borderBottomWidth: StyleSheet.hairlineWidth,
    marginHorizontal: 12,
  },
});

export default styles;
