import COLORS from "utils/CommonColors";
import { scale, StyleSheet } from "utils/StyleSheet";

const styles = StyleSheet.create({
  container: {
    marginHorizontal: 16,
    marginTop: 16,
  },
  flex1: { flex: 1 },
  historyContentContainer: {
    flex: 1,
    backgroundColor: COLORS.violet1000,
    borderColor: COLORS.boxBorder,
    borderWidth: 1,
    borderRadius: 12,
    boxShadow: `inset 0px 0px ${scale(10)}px rgba(248, 115, 251, 0.5)`,
  },
  gameRule: {
    flexDirection: "row",
    marginTop: 20,
    justifyContent: "center",
    paddingBottom: 26,
    alignItems: "center",
  },
  gameRuleIcon: {
    marginRight: 5,
  },
  layout: {},
  newLayout: {
    ...StyleSheet.absoluteFillObject,
    top: 490,
  },
});

export default styles;
