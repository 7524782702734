import Store from "stores";
import { DeviceAction } from "stores/actions";
import { getConfig } from "utils/ConfigHelper";
import DeviceInfo from "utils/DeviceInfo";
import RemitanoBrowser from "utils/RemitanoBrowser";
import QAlertHelper from "./AlertHelper";

export async function initRemiBrowser() {
  let deviceConfig = {};
  try {
    if (DeviceInfo.appDeviceInfo) {
      deviceConfig = DeviceInfo.appDeviceInfo;
    } else {
      deviceConfig = await getConfig();
      if (deviceConfig === null) {
        return;
      }
    }
    Store.dispatch({
      type: DeviceAction.REMITANO_BROWSER_READY,
      data: deviceConfig,
    });
    RemitanoBrowser.config({
      barStyle: "light-content",
      hideNavBar: true,
    });
  } catch (error: any) {
    QAlertHelper.showModalAlert({ message: error });
  }
}

