import { RankingAction } from "../actions";
import { GenericAction } from "../types/actions";

const initialState = {
  ranking: {},
  current_rank: -1,
  current_balance: -1,
};

const ranking = (state = initialState, action: GenericAction) => {
  switch (action.type) {
    case RankingAction.UPDATE_RANK_LIST:
      return {
        ...state,
        ...action.data,
        ranking: { [action.data.week]: action.data.ranking },
      };
    default:
      return state;
  }
};

export default ranking;
