import React from "react";
import { useSelector } from "react-redux";
import { GlobalState } from "stores/types/store";
import DeviceInfo from "utils/DeviceInfo";
import { scale, StyleSheet } from "utils/StyleSheet";
import QuizModal from "../Modal";
import COLORS from "utils/CommonColors";
import QText from "../QText";
import { ButtonQuiz } from "../Button";
import usePopupAlert from "apps/crypto-quiz/hooks/commons/usePopupAlert";
import Locale from "locale";
import { loginPath } from "utils/RequestHelper";
import CommonHelper from "apps/crypto-quiz/utils/CommonHelper";

type ModalProps = {
  width?: number;
  isOpen?: boolean;
  afterClose?: VoidFunction;
  title?: string;
};

const PopupRequiredLogin: React.FC<ModalProps> = ({ width }) => {
  const { onClose, confirm } = usePopupAlert();
  const { isRemiBrowser } = useSelector((state: GlobalState) => state.device);
  const handleLogin = () => {
    CommonHelper.redirectUrl(isRemiBrowser, loginPath);
    onClose();
  };

  return (
    <QuizModal
      onClose={onClose}
      bgContent={COLORS.violet1000}
      isOpen={
        confirm?.actionKey === "login-required" &&
        Boolean(confirm && Object.keys(confirm).length)
      }
      width={width}
    >
      <QText>{Locale.translate("required_login")}</QText>
      <ButtonQuiz onPress={handleLogin} style={styles.btnContinue}>
        {Locale.translate("login")}
      </ButtonQuiz>
    </QuizModal>
  );
};

PopupRequiredLogin.defaultProps = {
  width: DeviceInfo.CONTENT_WIDTH - scale(48),
};

export default React.memo(PopupRequiredLogin);

const styles = StyleSheet.create({
  btnContinue: {
    marginTop: 20,
    marginBottom: 10,
  },
});
