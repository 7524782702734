import React from "react";
import DeviceInfo from "utils/DeviceInfo";
import { scale, StyleSheet } from "utils/StyleSheet";
import QuizModal from "../Modal";
import COLORS from "utils/CommonColors";
import { Image } from "react-native";
import { QuizImages } from "apps/crypto-quiz/utils";

type ModalProps = {
  children: React.ReactNode;
  width?: number;
  src?: string;
  isOpen?: boolean;
  onClose?: VoidFunction;
};

const PopupWithIcon: React.FC<ModalProps> = ({
  children,
  width,
  src,
  isOpen,
  onClose,
}) => {
  return (
    <QuizModal
      onClose={onClose}
      bgContent={COLORS.violet1000}
      isOpen={isOpen}
      width={width}
    >
      {
        <Image
          style={styles.iconImage}
          source={{ uri: src || QuizImages.huggingFace }}
        />
      }
      {children}
    </QuizModal>
  );
};

PopupWithIcon.defaultProps = {
  width: DeviceInfo.CONTENT_WIDTH - scale(48),
};

export default React.memo(PopupWithIcon);

const styles = StyleSheet.create({
  iconImage: {
    position: "absolute",
    top: -50,
    width: 88,
    height: 88,
  },
});
