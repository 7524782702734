import { StyleSheet } from "react-native";
import DeviceInfo from "utils/DeviceInfo";

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#341642",
  },
  imageBg: {
    width: DeviceInfo.CONTENT_WIDTH,
    height: (DeviceInfo.CONTENT_WIDTH / 1081) * 1921,
    resizeMode: "contain",
  },
  imageBg2: {
    width: DeviceInfo.CONTENT_WIDTH,
    height: DeviceInfo.HEIGHT,
  },
  content: {
    ...StyleSheet.absoluteFillObject,
    padding: 16,
    justifyContent: "space-between",
  },
  itemCenter: {
    marginTop: 20,
    alignSelf: "center",
  },
  progressBar: {
    alignItems: "center",
    marginBottom: 42,
  },
  appVersion: {
    alignSelf: "center",
    textAlign: "center",
    color: "white",
    marginTop: 10,
    fontSize: 12,
  },
});

export default styles;
