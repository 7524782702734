import React, { useMemo } from "react";
import { SText, SvgIcon } from "base-components";
import {
  View,
  TouchableOpacityProps,
  TouchableWithoutFeedback,
} from "react-native";
import { scale } from "utils/StyleSheet";
import styles from "./styles";

interface Props extends TouchableOpacityProps {
  width?: number;
  title?: string;
  isNewVersionEnabled?: boolean;
  isActiveAction?: boolean;
}

const PlayButton = (props: Props) => {
  const {
    width = 132,
    title,
    isNewVersionEnabled,
    isActiveAction,
    ...restProps
  } = props;
  const [isPress, setPress] = React.useState(false);

  const newPage = useMemo(() => {
    if (isNewVersionEnabled) {
      return {
        buttonPlay: "new_button_play",
        buttonPlayPress: "new_button_play_press",
        buttonMore: "new_more_button",
        bottomTitle: 20,
        bottomTitlePress: 17,
        buttonWidth: 143
      };
    }
    return {
      buttonPlay: "button_play",
      buttonPlayPress: "button_play_press",
      buttonMore: "button_play",
      bottomTitle: 18,
      bottomTitlePress: 13,
      buttonWidth: width
    };
  }, [isNewVersionEnabled, width]);

  return (
    <TouchableWithoutFeedback
      onPressIn={() => setPress(true)}
      onPressOut={() => setPress(false)}
      {...restProps}
    >
      <View key={`${title}`} style={styles.container} testID="123123">
        <SvgIcon
          name={
            !isActiveAction
              ? newPage.buttonMore
              : isPress
              ? newPage.buttonPlayPress
              : newPage.buttonPlay
          }
          width={newPage.buttonWidth}
          height={52}
        />
        <SText
          style={[
            styles.title,
            {
              bottom: scale(
                isPress ? newPage.bottomTitlePress : newPage.bottomTitle
              ),
            },
          ]}
        >
          {title?.toUpperCase()}
        </SText>
      </View>
    </TouchableWithoutFeedback>
  );
};

export default PlayButton;
