declare interface Window {
  onWebviewReady(): void,
  RemitanoBrowser: {
    handleSDK(type: string, options?: any): Promise<any>;
  }
  RemitanoLiteBrowser: {
    sendEvent?: (event: string, data: string | object) => void
    handleSDK(type: string, options?: any): Promise<any>
    callbackEvent?: (event: string, data: string | object) => void
  }
  addEventListener?: any,
  removeEventListener?: any,
  dispatchEvent?: any,
  parent?: any,
}
declare let window: Window;

interface IConfigOptions {
  title?: string;
  barStyle?: "dark-content" | "light-content";
  hideNavBar?: boolean;
}

export interface IDeviceInfo {
  device_id: string;
  device_name: string;
  device_platform: "ios" | "android";
  status_bar_height: number;
  bottom_padding: number;
}

const RemitanoBrowser = {
  initLiteBrowser: () => {
    if (window.RemitanoLiteBrowser) return;
    const genEventId = () => {
      let result = "";
      const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      const charactersLength = characters.length;
      for (let i = 0; i < 10; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
      }
      return result;
    };

    window.RemitanoLiteBrowser = {
      sendEvent: (event, data) => {
        const event_type = `${event}-${genEventId()}`;
        let post_event_data = event_type;

        if (data) {
          switch (typeof data) {
            case "object":
              post_event_data += `.${JSON.stringify(data)}`;
              break;
            case "string":
              post_event_data += `.${data}`;
              break;
            default:
              break;
          }
        }
        window.parent.postMessage(post_event_data, "*");
      },
      handleSDK: function (event, data) {
        return new Promise(function (resolve, reject) {
          const event_type = `${event}-${genEventId()}`;

          function resolveHandle(event: MessageEvent<any>) {
            window.removeEventListener("message", resolveHandle, false);
            if (event.data?.type !== event_type) return;
            const event_data = JSON.parse(event.data?.detail);
            try {
              if (typeof event_data !== "object") {
                return reject(new Error("Wrong type respone data"));
              }

              if (event_data.type === "reject") {
                if (!event_data.message) {
                  return reject(new Error("Missing message reject"));
                }

                return reject(new Error(event_data.message));
              }

              if (!event_data.payload) {
                return reject(new Error("Missing payload in resolve"));
              }

              if (typeof event_data.payload.object !== "undefined") {
                return resolve(event_data.payload.object);
              }

              if (typeof event_data.payload.string !== "undefined") {
                return resolve(event_data.payload.string);
              }

              if (typeof event_data.payload.number !== "undefined") {
                return resolve(event_data.payload.number);
              }

              if (typeof event_data.payload.boolean !== "undefined") {
                return resolve(event_data.payload.boolean);
              }

              return resolve(null);
            } catch (e) {
              return reject(e);
            }
          }

          let post_event_data = event_type;

          if (data) {
            switch (typeof data) {
              case "object":
                post_event_data += "." + JSON.stringify(data);
                break;
              case "string":
                post_event_data += "." + data;
                break;
              default:
                return reject(new Error("Invalid data type"));
            }
          }
          window.addEventListener("message", resolveHandle, false);
          window.parent.postMessage(post_event_data, "*");
        });
      },
      callbackEvent: function (event, data) {
        const customEvent = new CustomEvent(event, { detail: data });
        window.dispatchEvent(customEvent);
      }
    };
  },
  config: (options: IConfigOptions) => {
    if (window.RemitanoBrowser) {
      window.RemitanoBrowser.handleSDK("config", options);
    }
    if (window.RemitanoLiteBrowser) {
      window.RemitanoLiteBrowser.handleSDK("config", options);
    }
  },
  setItem: (key: string, value: string): Promise<boolean> => {
    if (window.RemitanoBrowser) {
      return window.RemitanoBrowser.handleSDK("config", {
        key,
        value,
      });
    }
    if (window.RemitanoLiteBrowser) {
      return window.RemitanoLiteBrowser.handleSDK("config", {
        key,
        value,
      });
    }
    throw new Error("Remitano Browser Undefined");
  },
  getItem: (key: string): Promise<any> => {
    if (window.RemitanoBrowser) {
      return window.RemitanoBrowser.handleSDK("config", {
        key,
      });
    }
    if (window.RemitanoLiteBrowser) {
      return window.RemitanoLiteBrowser.handleSDK("config", {
        key,
      });
    }
    throw new Error("Remitano Browser Undefined");
  },
  getDeviceInfo: (): Promise<IDeviceInfo> => {
    if (window.RemitanoBrowser) {
      return window.RemitanoBrowser.handleSDK("getDeviceInfo");
    }
    if (window.RemitanoLiteBrowser) {
      return window.RemitanoLiteBrowser.handleSDK("getDeviceInfo");
    }
    throw new Error("Remitano Browser Undefined");
  },
  getDeviceInfoAsync: (): Promise<IDeviceInfo> => {
    if (window.RemitanoBrowser) {
      return window.RemitanoBrowser.handleSDK("getDeviceInfoAsync");
    }
    if (window.RemitanoLiteBrowser) {
      return window.RemitanoLiteBrowser.handleSDK("getDeviceInfoAsync");
    }
    throw new Error("Remitano Browser Undefined");
  },
  shareScreen: (): Promise<object> => {
    if (window.RemitanoBrowser) {
      return window.RemitanoBrowser.handleSDK("shareScreen");
    }
    if (window.RemitanoLiteBrowser) {
      return window.RemitanoLiteBrowser.handleSDK("shareScreen");
    }
    throw new Error("Remitano Browser Undefined");
  },
  closeBrowser: () => {
    if (window.RemitanoBrowser) {
      return window.RemitanoBrowser.handleSDK("closeBrowser");
    }
    console.log("window.RemitanoLiteBrowser", window.RemitanoLiteBrowser);
    if (window.RemitanoLiteBrowser) {
      return window.RemitanoLiteBrowser.handleSDK("closeBrowser");
    }
  },
  openUrl: ({ url }: { url: string }): Promise<boolean> => {
    if (window.RemitanoBrowser) {
      return window.RemitanoBrowser.handleSDK("openUrl", {
        url
      });
    }
    if (window.RemitanoLiteBrowser) {
      return window.RemitanoLiteBrowser.handleSDK("openUrl", {
        url
      });
    }
    throw new Error("Remitano Browser Undefined");
  },
  axiosRequest: (data: any): Promise<any> => {
    if (window.RemitanoBrowser) {
      return window.RemitanoBrowser.handleSDK("axiosRequest", data);
    }
    if (window.RemitanoLiteBrowser) {
      return window.RemitanoLiteBrowser.handleSDK("axiosRequest", data);
    }
    throw new Error("Remitano Browser Undefined");
  }
};

export default RemitanoBrowser;
