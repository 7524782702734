import { StyleSheet } from "utils/StyleSheet";
const styles = StyleSheet.create({
  buttonTitle: {
    fontSize: 12,
    lineHeight: 16,
    textAlign: "center",
  },
  itemCenter: {
    alignItems: "center",
  },
});

export default styles;
