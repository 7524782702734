import Store from "stores";
import { DeviceAction } from "stores/actions";

class QAlertHelper {
  showModalAlert = ({ actionKey = "alert" ,type = "error", message = "" }) => {
    Store.dispatch({
      type: DeviceAction.SHOW_CONFIRM_MODAL,
      data: {
        actionKey,
        message,
        type,
      },
    });
  };
}

export default new QAlertHelper();
