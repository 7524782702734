import React from "react";
import { useNavigate } from "react-router-dom";
import { View, Image } from "react-native";
import { ButtonQuiz, QText, CardView } from "apps/crypto-quiz/components";
import { QuizImages } from "apps/crypto-quiz/utils";

import styles from "./style";
import QUIZ_SCREENS from "apps/crypto-quiz/screens/info";

type Props = {
  title?: string;
  subTitle?: string;
  titleButton?: string;
};

const Empty: React.FC<Props> = ({ title, subTitle, titleButton }) => {
  const navigate = useNavigate();

  const handlePlayNow = () => {
    navigate(QUIZ_SCREENS.LandingScreen.path);
  };

  return (
    <View style={styles.container}>
      <CardView>
        <Image source={QuizImages.imgHandclap} style={styles.img} />

        <QText style={styles.title}>{title}</QText>

        <QText style={styles.description}>{subTitle}</QText>

        <ButtonQuiz
          onPress={handlePlayNow}
          width={250}
          height={35}
          style={styles.btn}
          titleStyle={styles.btnTitle}
          title={titleButton}
        />
      </CardView>
    </View>
  );
};

export default Empty;
