import React from "react";
import { Text, TextProps, TextStyle } from "react-native";
import { scale } from "utils/StyleSheet";
import styles from "./styles";

interface Props extends TextProps {
  text?: string;
  children?: any;
  bold?: Boolean;
  style?: TextStyle | TextStyle[];
  color?: string;
  size?: number;
}

const QText: React.FC<Props> = (props) => {
  const { bold, text, children, style, color, size, ...restProps } = props;
  const bonusStyle: any = {};

  if (bold) bonusStyle.fontWeight = "bold";
  if (color) bonusStyle.color = color;
  if (size) bonusStyle.fontSize = scale(size);

  return (
    <Text style={[styles.text, style, bonusStyle]} {...restProps}>
      {text || children}
    </Text>
  );
};

export default QText;
