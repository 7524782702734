import { useCallback, useRef } from "react";

export function useOnEventCallback<T extends (...args: any[]) => any>(callback: T, options?: { event?: string }): T {
	const ref = useRef<T>((() => {}) as T);

	if (typeof callback === "function") {
		ref.current = callback;
	}

	return useCallback<T>(
		((...args) => {
			if (options && options.event) {console.log(options.event);}
			return ref.current(...args);
		}) as T,
		[],
	);
}
