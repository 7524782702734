import RequestHelper from "utils/RequestHelper";
import QAlertHelper from "apps/crypto-quiz/utils/AlertHelper";

class GamePassedService {
  getGamePassed = async (): Promise<GamePassed[] | undefined> => {
    try {
      const res = await RequestHelper.get(
        "/api/v1/quiz_user_answer_questions/answers_history"
      );
      if (res.data && res.code === 200) {
        return res?.data?.history || [];
      }
    } catch (error: any) {
      QAlertHelper.showModalAlert({ message: error?.message });
    }
  };
}

export default new GamePassedService();
