import { StyleSheet } from "utils/StyleSheet";
import COLORS from "utils/CommonColors";

const styles = StyleSheet.create({
  container: {
    padding: 12,
    paddingRight: 6,
    flexDirection: "row",
    borderTopColor: COLORS.violet800,
    borderTopWidth: 1,
    alignItems: "center",
  },
  firstItem: {
    borderTopWidth: 0,
  },
  coinLogo: {
    marginRight: 12,
  },
  giftContent: {
    marginRight: 12,
    flex: 1,
  },
  giftDescription: {
    color: COLORS.lightPurple600,
    lineHeight: 20,
  },
  giftTitle: {
    lineHeight: 20,
  },
});
export default styles;
