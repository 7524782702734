import { StyleSheet } from "utils/StyleSheet";
import COLORS from "utils/CommonColors";
const styles = StyleSheet.create({
  dateBox: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: COLORS.violet800,
    height: 64,
    paddingHorizontal: 24,
  },
  newDateBox: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "#1C002F",
    height: 64,
    paddingHorizontal: 24,
  },
  dateContent: {
    lineHeight: 18,
  },
  bg: {
    backgroundColor: "#9082F7",
  },
  newBg: {
    backgroundColor: "#9C3CE8",
  },
  overlay: {
    opacity: 0.5,
  },
});
export default styles;
