import { StyleSheet } from "utils/StyleSheet";

const styles = StyleSheet.create({
  container: {
    height: "100%",
    width: "100%",
    alignItems: "center",
  },
  content: {
    marginTop: "auto",
    borderTopLeftRadius: 20,
    borderTopRightRadius: 20,
    padding: 20,
    justifyContent: "flex-start",
    alignItems: "flex-start",
    backgroundColor: "#F5F5F5",
  },
  header: {
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
  },
  headerLeft: {
    flexDirection: "row",
  },
  campainName: {
    color: "#000",
    fontFamily: "Arial",
    lineHeight: 20,
  },
  campainLink: {
    color: "#99989B",
    fontFamily: "Arial",
    lineHeight: 18,
  },
  hr: {
    width: "100%",
    height: 1,
    backgroundColor: "#E5E5EA",
    marginTop: 20,
  },
  share: { flexDirection: "row", justifyContent: "space-between", width: "100%", marginTop: 20 },
  mr20: { marginRight: 20 },
  mr8: { marginRight: 8 },
  copy: {
    justifyContent: "space-between",
    flexDirection: "row",
    alignItems: "center",
    width: "100%",
    padding: 12,
    borderRadius: 9,
    backgroundColor: "white",
  },
  itemCenter: {
    alignItems: "center",
  },
  copyText: {
    fontFamily: "SF Pro Display",
    marginRight: 8,
  },
  widthFull: {
    width: "100%",
  },
  mt6: {
    marginTop: 6,
  },
});
export default styles;
