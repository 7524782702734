import { SText, SvgIcon } from "base-components";
import Locale from "locale";
import React from "react";
import { View, Modal, Pressable, ScrollView, Image } from "react-native";
import { useDispatch, useSelector } from "react-redux";
import { GlobalState } from "stores/types/store";
import { DeviceAction } from "stores/actions";
import CacheImageService from "services/CacheImageService";
import styles from "./styles";
import Board from "../Board";

const AlertModal = () => {
  const { alert } = useSelector((state: GlobalState) => state.device);
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch({
      type: DeviceAction.SET_CUSTOM_MODAL_READY,
      data: true,
    });
    return () => {
      dispatch({
        type: DeviceAction.SET_CUSTOM_MODAL_READY,
        data: false,
      });
    };
  }, []);

  const onClose = () => dispatch({
    type: DeviceAction.SHOW_CUSTOM_ALERT,
    data: undefined,
  });

  return (
    <Modal visible={Boolean(alert && Object.keys(alert).length)} transparent>
      <View style={styles.container}>
        <ScrollView>
          <View style={styles.rewardContent}>
            <Board title={Locale.translate(alert?.type === "success" ? "congratulation" : "error")}>
              <View style={styles.contentContainer}>
                {Boolean(alert?.icon) &&
                  <SvgIcon name={String(alert?.icon)} size={100} />
                }
                {Boolean(alert?.img) &&
                  <Image
                    source={{ uri: CacheImageService.getCachedImg(String(alert?.img)) }}
                    style={styles.headerImage}
                  />
                }
                <View style={styles.rewardBox}>
                  {Boolean(alert?.title) &&
                    <SText bold style={styles.title}>
                      {alert?.title}
                    </SText>
                  }
                  {Boolean(alert?.message) &&
                    <SText style={styles.rewardDescription}>
                      {alert?.message}
                    </SText>
                  }
                </View>
              </View>
            </Board>
            <Pressable style={styles.close} onPress={onClose}>
              <SvgIcon name="ic_close" size={32} />
            </Pressable>
          </View>
        </ScrollView>
      </View>
    </Modal>
  );
};

export default AlertModal;
