import { StyleSheet } from "utils/StyleSheet";

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  fakeWheel: {
    height: 319 - 46,
  },
  newFakeWheel: {
    height: 319 - 60,
  },
  absoluteWheel: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    zIndex: 999,
  },
  actionContainer: {
    paddingHorizontal: 10,
    flexDirection: "row",
    justifyContent: "space-between",
    position: "absolute",
    bottom: 0,
    left: 0,
    right: 0,
  },
  newActionContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    position: "absolute",
    bottom: -30,
    left: 0,
    right: 0,
  },
  gemTotal: {
    position: "absolute",
    left: 12,
    top: -6,
    height: 32,
    borderRadius: 16,
    paddingHorizontal: 8,
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: "rgba(255, 255, 255, 0.1)",
  },
  gem: {
    width: 14,
    height: 20,
    resizeMode: "contain",
    marginRight: 6,
  },
  flex1: {
    flex: 1,
  },
  mt20: {
    marginTop: 20,
  },
  upTo8: {
    marginTop: -8,
  },
  board: {
    marginHorizontal: 16,
    marginTop: 16,
  },
  gameRule: {
    flexDirection: "row",
    marginTop: 20,
    justifyContent: "center",
    marginBottom: 26,
    alignItems: "center",
  },
  gameRuleIcon: {
    marginRight: 5,
  },
});

export default styles;
