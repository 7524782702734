import { StyleSheet } from "utils/StyleSheet";
import COLORS from "utils/CommonColors";

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    marginHorizontal: 12,
    borderColor: COLORS.innerBorder,
    minHeight: 76,
    alignItems: "flex-start",
    paddingTop: 12,
  },
  missionName: {
    lineHeight: 20,
  },
  centerBox: {
    flex: 1,
    paddingHorizontal: 12,
    paddingBottom: 8,
  },
  count: {
    marginTop: 10,
  },
  itemCenter: { alignItems: "center" },
  icon: {
    width: 52,
    height: 52,
  }
});
export default styles;
