import user from "./user";
import device from "./device";
import ranking from "./ranking";
import campaign from "./campaign";

const reducers = {
  user,
  device,
  ranking,
  campaign,
};

export default reducers;
