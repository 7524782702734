import { SText, SvgIcon } from "base-components";
import React from "react";
import { View, Modal, TouchableOpacity, Clipboard, Pressable } from "react-native";
import { scale } from "utils/StyleSheet";
import {
  EmailShareButton,
  TelegramShareButton,
  TwitterShareButton,
  FacebookIcon,
  TwitterIcon,
  EmailIcon,
  TelegramIcon,
} from "react-share";
import DeviceInfo from "utils/DeviceInfo";
import Locale from "locale";
import { shareUrl } from "services/LuckyDrawService";
import { useSelector } from "react-redux";
import { GlobalState } from "stores/types/store";
import styles from "./styles";
import { shareFacebook } from "apps/spin-wheel/screens/Home/components/utils";

export interface ModalRef {
  showModal(): void;
}

const ShareModal = React.forwardRef<ModalRef, any>((props, ref) => {
  const [isShow, setShowModal] = React.useState(false);
  const [copied, setCopyStatus] = React.useState(false);
  const { campaign_name } = useSelector((state: GlobalState) => state.campaign);
  const title = Locale.translate("share_title");

  React.useImperativeHandle(ref, () => ({
    showModal: () => {
      setShowModal(true);
    },
  }));

  const onClose = React.useCallback(() => setShowModal(false), []);

  const onCopy = async () => {
    try {
      await Clipboard.setString(title);
      setCopyStatus(true);
    } catch (error) {}
  };

  const onShareFacebook = React.useCallback(() => shareFacebook(), []);

  return (
    <Modal animationType="slide" transparent={true} visible={isShow} onRequestClose={onClose}>
      <View style={styles.container}>
        <View style={[styles.content, { width: DeviceInfo.CONTENT_WIDTH }]}>
          <View style={styles.header}>
            <View style={styles.headerLeft}>
              <SvgIcon size={41} name="shareDefault" style={styles.mr8} />
              <View>
                <SText size={15} style={styles.campainName}>
                  {campaign_name}
                </SText>
                <SText size={13} style={styles.campainLink}>
                  {shareUrl}
                </SText>
              </View>
            </View>
            <TouchableOpacity onPress={onClose}>
              <SvgIcon size={30} name="ic_close2" />
            </TouchableOpacity>
          </View>
          <View style={styles.hr} />
          <View style={[styles.share, styles.widthFull]}>
            <TouchableOpacity style={styles.copy} onPress={onCopy}>
              <SText size={17} color="#000" style={styles.copyText}>
                {copied ? "Copied" : "Copy"}
              </SText>
              <SvgIcon size={26} name="copy" />
            </TouchableOpacity>
          </View>
          <View style={styles.hr} />
          <View style={styles.share}>
            <View style={[styles.mr20, styles.itemCenter]}>
              <Pressable onPress={onShareFacebook}>
                <FacebookIcon size={scale(60)} round />
              </Pressable>
              <SText size={11} color="#000" style={styles.mt6}>
                Facebook
              </SText>
            </View>
            <View style={[styles.mr20, styles.itemCenter]}>
              <TwitterShareButton url={shareUrl} title={title}>
                <TwitterIcon size={scale(60)} round />
              </TwitterShareButton>
              <SText size={11} color="#000" style={styles.mt6}>
                Twitter
              </SText>
            </View>
            <View style={[styles.mr20, styles.itemCenter]}>
              <EmailShareButton url={shareUrl} subject={title} body={title}>
                <EmailIcon size={scale(60)} round />
              </EmailShareButton>
              <SText size={11} color="#000" style={styles.mt6}>
                Email
              </SText>
            </View>
            <View style={[styles.mr20, styles.itemCenter]}>
              <TelegramShareButton url={shareUrl} title={title}>
                <TelegramIcon size={scale(60)} round />
              </TelegramShareButton>
              <SText size={11} color="#000" style={styles.mt6}>
                Telegram
              </SText>
            </View>
          </View>
        </View>
      </View>
    </Modal>
  );
});

ShareModal.displayName = "ShareModal";

export default ShareModal;
