import React, { useCallback, useEffect, useMemo, useRef } from "react";
import { TouchableOpacity } from "react-native";
import { QText } from "apps/crypto-quiz/components";
import GamePassedTabBar from "./GamePassedTabBar";


import styles from "./styles";
import { useGamePassedContext } from "apps/crypto-quiz/contexts/GamePassedContext";

export type TabsData = {
  id: string;
  title: string;
};
const Item = ({ title, onClick, isActive, isFirst, isLast }: any) => (
  <TouchableOpacity
    style={[
      styles.itemTab,
      isActive && styles.itemTabActive,
      isFirst && styles.itemTabFirst,
      isLast && styles.itemTabLast,
    ]}
    onPress={onClick}
  >
    <QText size={14} style={styles.tabText}>
      {title}
    </QText>
  </TouchableOpacity>
);

const Container = () => {
  const flatListRef: any = useRef();
  const { activeTab, passedsGame, onSetActiveTab } = useGamePassedContext();
  const tabsData: TabsData[] = useMemo(() => {
    return passedsGame?.map((passedGame: GamePassed, index: number) => {
      return {
        id: index,
        title: "Game " + passedGame?.gameStep,
      };
    });
  }, [passedsGame]);

  const renderItem = ({
    item,
    index,
    active,
  }: {
    item: any;
    index: number;
    active: number;
  }) => {
    return (
      <Item
        isActive={index === active}
        isFirst={index === 0}
        isLast={index + 1 === tabsData.length}
        title={item.title}
        onClick={() => handleClick(index)}
      />
    );
  };

  const handleClick = useCallback((index: number) => {
    onSetActiveTab?.(index);
  }, []);

  useEffect(() => {
    if (tabsData?.length) {
      setTimeout(() => {
        flatListRef.current?.scrollToIndex?.({
          animated: true,
          index: activeTab || 0,
          viewPosition: 0.5,
        });
      }, 100);
    }
  }, [activeTab, tabsData]);

  const composeProps = {
    activeTab,
    flatListRef,
    tabsData,
    renderItem,
  };

  return <GamePassedTabBar ref={flatListRef} {...composeProps} />;
};

export default Container;
