import React from "react";
import {
  Pressable,
  PressableProps,
  TextStyle,
  View,
  ViewStyle,
} from "react-native";
import { QText } from "apps/crypto-quiz/components";

import styles from "./styles";
import { scale } from "utils/StyleSheet";

type Variant = "primary" | "dark" | "text";

interface Props extends PressableProps {
  width?: number;
  height?: number;
  title?: string;
  style?: ViewStyle;
  disable?: Boolean;
  variant?: Variant;
  block?: boolean;
  titleStyle?: TextStyle;
}

const Button = (props: Props) => {
  const {
    width = 90,
    height = 30,
    title,
    style,
    disable,
    block = false,
    titleStyle = {},
    variant = "primary",
    children,
    ...restProps
  } = props;

  const { btnPrimary, btnDark, btn } = styles;

  let propStyleChange = { ...btn };
  switch (variant) {
    case "dark":
      propStyleChange = { ...propStyleChange, ...btnDark };
      break;
    case "text":
      propStyleChange = {
        ...btn,
        borderWidth: 0,
        shadowOffset: { width: 0, height: 0 },
        shadowRadius: 0,
      };
      break;
    default:
      propStyleChange = { ...propStyleChange, ...btnPrimary };
      break;
  }

  return (
    <Pressable
      key={`${title}${disable}`}
      style={{
        width: block ? "auto" : scale(width),
        height: scale(height),
        ...style,
      }}
      disabled={Boolean(disable)}
      {...restProps}
    >
      <View
        style={[
          disable ? styles.contentMaskDisabled : styles.contentMask,
          { ...propStyleChange },
        ]}
      >
        <QText
          style={{
            ...styles.title,
            ...titleStyle,
            ...(disable ? styles.titleDisabled : {}),
          }}
        >
          {title || children}
        </QText>
      </View>
    </Pressable>
  );
};

export default React.memo(Button);
