import { useCallback, useEffect, useState } from "react";
import GamePlayService from "../services/GamePlayService";

type Props = {
  firstLoad?: boolean;
};

const useTurnGamePlay = ({ firstLoad = true }: Props): any => {
  const [loading, setLoading] = useState(false);
  const [turn, setTurn] = useState(0);
  const fetch = async () => {
    setLoading(true);
    const response = await GamePlayService.updateTurnGamePlay();
    setTurn(response?.turn_number);
    setLoading(false);
  };

  const plusTurn = useCallback(()=>{
    setTurn((currentTurn) => currentTurn + 1);
  }, []);

  const minusTurn = useCallback(()=> {
    if(turn > 0){
      setTurn((currentTurn) => currentTurn - 1);
    }
  }, [turn]);

  useEffect(() => {
    if (firstLoad) {
      fetch();
    }
  }, []);

  return {
    fetch,
    turn,
    isHasTurn: turn > 0,
    loading,
    minusTurn,
    plusTurn,
  };
};

export default useTurnGamePlay;
