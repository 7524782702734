import React from "react";
import { RankingContextProvider } from "apps/crypto-quiz/contexts/RankingContext";
import RankingWrapper from "./components/RankingWrapper";

const RankingChartScreen: React.FC = () => {
  return (
    <RankingContextProvider>
      <RankingWrapper />
    </RankingContextProvider>
  );
};

export default RankingChartScreen;
