import React, { useEffect } from "react";
import { HashRouter as Router, Routes, Route } from "react-router-dom";
import DeviceInfo from "utils/DeviceInfo";
import { View, StyleSheet } from "react-native";
import {
  OverviewScreen,
  HowToPlayScreen,
  ShareScreen,
  LandingScreen,
  RankingChartScreen,
  GamePlayScreen,
  ResultScreen,
  GamePassedScreen,
  CoinlympiaSplashScreen,
} from "./screens";
import SCREENS from "./screens/info";
import PopupAlert from "./components/popups/PopupAlert";
import PopupRequiredLogin from "./components/popups/PopupRequiredLogin";
import QuizGlobalProvider from "./contexts/QuizGlobalContext";
import QuizAuthProvider from "./contexts/QuizAuthContext";

const QuizApp: React.FC = () => {
  useEffect(() => {
    document.title = "Coinlympia";
  }, []);

  return (
    <View style={styles.container}>
      <View style={styles.contentContainer}>
        <Router>
          <QuizGlobalProvider>
            <QuizAuthProvider>
              <Routes>
                <Route
                  path={SCREENS.OverviewScreen.path}
                  element={<OverviewScreen />}
                />
                <Route
                  path={SCREENS.HowToPlayScreen.path}
                  element={<HowToPlayScreen />}
                />
                <Route
                  path={SCREENS.ShareScreen.path}
                  element={<ShareScreen />}
                />
                <Route
                  path={SCREENS.LandingScreen.path}
                  element={<LandingScreen />}
                />
                <Route
                  path={SCREENS.RankingChartScreen.path}
                  element={<RankingChartScreen />}
                />
                <Route
                  path={SCREENS.ResultScreen.path}
                  element={<ResultScreen />}
                />
                <Route
                  path={SCREENS.CoinlympiaSplashScreen.path}
                  element={<CoinlympiaSplashScreen />}
                />
                <Route
                  path={SCREENS.GamePlayScreen.path}
                  element={<GamePlayScreen />}
                />
                <Route
                  path={SCREENS.ShareScreen.path}
                  element={<ShareScreen />}
                />
                <Route
                  path={SCREENS.GamePassedScreen.path}
                  element={<GamePassedScreen />}
                />
              </Routes>
            </QuizAuthProvider>
          </QuizGlobalProvider>
        </Router>
        <PopupRequiredLogin />
        <PopupAlert />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "black",
    alignItems: "center",
  },
  contentContainer: {
    width: DeviceInfo.CONTENT_WIDTH,
    height: "100%",
  },
});

export default QuizApp;
