class CacheImgService {
  blobUrlObj: any = {};

  loadImg = async (name: string) => {
    try {
      if (this.blobUrlObj[name]) return;
      const res = await fetch(`/images/${name}.png`);
      const imgBlod = await res.blob();
      this.blobUrlObj[name] = URL.createObjectURL(imgBlod);
    } catch (error) {}
  };

  preloadListImg = async (imageName: string[]) => {
    const imagesPromiseList: Promise<any>[] = [];
    for (const i of imageName) {
      imagesPromiseList.push(this.loadImg(i));
    }
    await Promise.all(imagesPromiseList);
  };

  getCachedImg = (name: string) => {
    return this.blobUrlObj[name];
  };
}

export default new CacheImgService();
