import Locale from "locale";
import { get, upperCase } from "lodash";
import moment, { Moment } from "moment";
import RemitanoBrowser from "utils/RemitanoBrowser";
import {
  Currency,
  IGiftType,
  IStartSpinRes,
  LuckTradeGift,
  RewardParam,
  shareUrl,
} from "services/LuckyDrawService";
import {
  REMITANO_DOMAIN,
  rewardCenterPath,
  walletsPath,
} from "utils/RequestHelper";
import Store from "stores";
import { DeviceAction } from "stores/actions";
import DeviceInfo from "utils/DeviceInfo";
import { getConfig } from "utils/ConfigHelper";
import AlertHelper from "utils/AlertHelper";

const isSafariOnIOS =
  navigator.userAgent.match(/iPad|iPhone|iPod/i) &&
  !navigator.userAgent.match(/CriOS|FxiOS|OPiOS|mercury/i) &&
  navigator.userAgent.match(/WebKit/i);

const mapGiftType = {
  voucher: 0,
  token: 3,
  spin: 6,
  good_luck: 7,
  coin_random: {
    doge: 1,
    kai: 2,
    shib: 4,
    usdt: 5,
  },
  renec: 3,
  bonus_apr: undefined,
  cashback_on_fee: undefined,
  coin: undefined,
};

export const getRewardIndex = (rewardInfo: IStartSpinRes) => {
  const type = rewardInfo.lucky_trade_gift.gift_type;
  if (type === IGiftType.coin_random) {
    const coinType: Currency = get(rewardInfo.reward_params, "currency");
    return mapGiftType[type][coinType];
  }
  return mapGiftType[type];
};

export const getRewardIcon = (
  gifttype: IGiftType,
  coinType?: Currency,
  isNewVersionEnabled?: boolean
) => {
  const prefix = isNewVersionEnabled ? "new_" : "";
  switch (gifttype) {
    case IGiftType.voucher:
    case IGiftType.spin:
    case IGiftType.good_luck:
      return `${prefix}${gifttype}`;
    case IGiftType.token:
      return `${prefix}token`;
    case IGiftType.renec:
      return `${prefix}mystery-box`;

    case IGiftType.coin_random:
      return `${prefix}${coinType}`;

    default:
      return "";
  }
};

export const getRewardName = (
  lucky_trade_gift: LuckTradeGift,
  reward_params: RewardParam,
  with_amount = true
) => {
  const amount =
    with_amount && reward_params.amount ? `${reward_params.amount} ` : "";

  if (
    lucky_trade_gift.gift_type === IGiftType.coin_random ||
    lucky_trade_gift.gift_type === IGiftType.renec
  ) {
    return `${amount}${upperCase(reward_params.currency)}`;
  }
  if (lucky_trade_gift.gift_type === IGiftType.good_luck)
    return Locale.translate("good_luck_gift");
  if (lucky_trade_gift.gift_type === IGiftType.spin)
    return Locale.translate("spin_gift");

  return `${amount}${lucky_trade_gift.gift_name}`;
};

export const getRewardAction = (giftType: IGiftType, status: string) => {
  const isUsed = status === "used";
  switch (giftType) {
    case IGiftType.voucher:
      return {
        label: "use_now",
        url: isUsed ? `${REMITANO_DOMAIN}${rewardCenterPath}` : "use",
      };
    case IGiftType.token:
    case IGiftType.good_luck:
    case IGiftType.spin:
      return {
        label: "spin_now",
        url: "/",
      };
    case IGiftType.coin_random:
    case IGiftType.renec:
      return {
        label: isUsed ? "open_wallet" : "receive",
        url: isUsed ? `${REMITANO_DOMAIN}${walletsPath}` : "use",
      };
    default:
      return {
        label: "",
        url: "",
      };
  }
};

export const getWeekInfo = (currentDate: Moment) => {
  const firstDay = (
    currentDate.day() === 0
      ? currentDate.clone().weekday(-6)
      : currentDate.clone().weekday(1)
  ).format("DD/MM");
  const endDay = (
    currentDate.day() === 0
      ? currentDate.clone().weekday(0)
      : currentDate.clone().weekday(7)
  ).format("DD/MM");
  return `${Locale.translate(
    "week"
  )} ${currentDate.isoWeek()} (${firstDay} - ${endDay})`;
};

export const redirectUrl = (isRemiBrowser: boolean, url: string) => {
  if (isRemiBrowser) {
    RemitanoBrowser.openUrl({ url: url });
  } else if (window.location) {
    window.location.href = `${REMITANO_DOMAIN}${url}`;
  }
  return;
};

export const openUrl = (isRemiBrowser: boolean, url: string) => {
  if (isRemiBrowser) {
    RemitanoBrowser.openUrl({ url: url });
  } else if (url.includes("http")) {
    window.open(url, "_blank");
  } else {
    window.open(`${REMITANO_DOMAIN}${url}`, "_blank");
  }
  return;
};

declare let window: any;

export function initFacebookSdk() {
  return new Promise(() => {
    // wait for facebook sdk to initialize before starting the react app
    window.fbAsyncInit = function () {
      window.FB.init({
        appId: process.env.REACT_APP_FACEBOOK_APP_ID,
        cookie: true,
        xfbml: true,
        version: "v8.0",
      });
    };

    // load facebook sdk script
    (function (d, s, id) {
      let js: any = d.getElementsByTagName(s)[0];
      const fjs: any = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {
        return;
      }
      js = d.createElement(s);
      js.id = id;
      js.src = "https://connect.facebook.net/en_US/sdk.js";
      fjs.parentNode.insertBefore(js, fjs);
    })(document, "script", "facebook-jssdk");
  });
}

export function shareFacebook(): Promise<boolean> {
  return new Promise((resolve) => {
    if (!window.FB) return resolve(false);
    window.FB.ui(
      {
        method: "share",
        href: shareUrl,
      },
      function (response: any) {
        if (response || isSafariOnIOS) {
          resolve(true);
        } else {
          resolve(false);
        }
      }
    );
  });
}

export async function initRemiBrowser() {
  let deviceConfig = {};
  try {
    const isRemitanoLite = window.location.href?.includes("remi-lite=1");
    if (isRemitanoLite) {
      RemitanoBrowser.initLiteBrowser();
    }
    if (DeviceInfo.appDeviceInfo) {
      deviceConfig = DeviceInfo.appDeviceInfo;
    } else {
      deviceConfig = await getConfig();
      if (deviceConfig === null) {
        return;
      }
    }
    if (isRemitanoLite) {
      Store.dispatch({
        type: DeviceAction.REMITANO_LITE_BROWSER_READY,
        data: deviceConfig,
      });
    } else {
      Store.dispatch({
        type: DeviceAction.REMITANO_BROWSER_READY,
        data: deviceConfig,
      });
    }
    RemitanoBrowser.config({
      barStyle: "light-content",
      hideNavBar: true,
    });
  } catch (error) {
    AlertHelper.showError(error);
  }
}

type Time = Date | Moment | number;

export function checkCampaign(checkTime: Time, startTime: Time, endTime: Time) {
  const campaignStartDate = moment(startTime);
  const campaignEndDate = moment(endTime);
  const checkDate = moment(checkTime);
  return (
    checkDate.isAfter(campaignStartDate) && checkDate.isBefore(campaignEndDate)
  );
}
