import { useGamePassedContext } from "apps/crypto-quiz/contexts/GamePassedContext";
import React, { useEffect, useRef } from "react";
import DeviceInfo from "utils/DeviceInfo";
import GamePassedWrapper from "./GamePassedWrapper";

const Container = () => {
  const { activeTab, passedsGame, loadingGamePassed } = useGamePassedContext();
  const wrapperRef: any = useRef();

  useEffect(() => {
    if (passedsGame?.length) {
      setTimeout(() => {
        wrapperRef.current.scrollTo({
          x: DeviceInfo.CONTENT_WIDTH * activeTab,
          animated: true,
        });
      }, 200);
    }
  }, [activeTab]);

  const composeProps = {
    passedsGame,
    loadingGamePassed
  };

  return <GamePassedWrapper ref={wrapperRef} {...composeProps} />;
};

export default Container;
