import React, { useState } from "react";
import { View, Image } from "react-native";
import { useSelector } from "react-redux";
import Locale from "locale";

import { SText } from "base-components";
import COLORS from "utils/CommonColors";
import CacheImageService from "services/CacheImageService";
import { GlobalState } from "stores/types/store";
import { PlayButton } from "apps/spin-wheel/components";
import { kycPath, loginPath } from "utils/RequestHelper";

import { openUrl, redirectUrl } from "../utils";
import styles from "./styles";
interface Props {
  onPlay?(): void;
}

const ControlBoard: React.FC<Props> = ({ onPlay }) => {
  const [isActiveAction, setActiveAction] = useState(false);
  const { lucky_trade_spin_balance: luckyTradeSpinBalance } = useSelector(
    (state: GlobalState) => state.user
  );
  const { campaign_name, kyc, isNewVersionEnabled } = useSelector(
    (state: GlobalState) => state.campaign
  );
  const { isRemiBrowser } = useSelector((state: GlobalState) => state.device);

  const newPage = React.useMemo(() => {
    if (isNewVersionEnabled) {
      return {
        board: CacheImageService.getCachedImg("new-board"),
        boardStyle: styles.newBoardBg,
        playButtonBoxStyle: styles.newPlayButtonBox,
        contentContainerStyle: styles.newContentContainer,
        descriptionColor: COLORS.orange100,
      };
    }
    return {
      board: CacheImageService.getCachedImg("board"),
      boardStyle: styles.boardBg,
      playButtonBoxStyle: styles.playButtonBox,
      contentContainerStyle: styles.contentContainer,
      descriptionColor: COLORS.purplel800,
    };
  }, [isNewVersionEnabled]);

  const btnTitle = React.useMemo(() => {
    if (!campaign_name) return Locale.translate("login");
    if (!kyc) return Locale.translate("kyc");
    if (!luckyTradeSpinBalance) return Locale.translate("more_spin");
    setActiveAction(true);
    return isNewVersionEnabled
      ? Locale.translate("spin_now_button")
      : Locale.translate("spin");
  }, [campaign_name, isNewVersionEnabled, kyc, luckyTradeSpinBalance]);

  const description = React.useMemo(() => {
    if (!campaign_name) return Locale.translate("login_to_continue");
    if (!kyc) return Locale.translate("kyc_to_continue");
    if (!luckyTradeSpinBalance) return Locale.translate("out_of_spin_turn");
    return (
      <SText color={COLORS.purplel800}>
        {Locale.translate("you_have")}
        <SText bold color={COLORS.yellow500}>
          {" "}
          {String(luckyTradeSpinBalance).padStart(2, "0")}
        </SText>{" "}
        {Locale.translate("spin_turn")}
      </SText>
    );
  }, [campaign_name, kyc, luckyTradeSpinBalance]);

  const onPress = React.useCallback(() => {
    if (!campaign_name) {
      return redirectUrl(isRemiBrowser, loginPath);
    }
    if (!kyc) {
      return openUrl(isRemiBrowser, kycPath);
    }

    onPlay && onPlay();
  }, [campaign_name, isRemiBrowser, kyc, onPlay]);

  return (
    <View style={styles.container}>
      <Image source={{ uri: newPage.board }} style={newPage.boardStyle} />
      <View style={newPage.contentContainerStyle}>
        <View style={newPage.playButtonBoxStyle}>
          <PlayButton
            title={btnTitle}
            onPress={onPress}
            isNewVersionEnabled={isNewVersionEnabled}
            isActiveAction={isActiveAction}
          />
        </View>
        <View style={styles.footerTitle}>
          <SText
            color={newPage.descriptionColor}
            {...(isNewVersionEnabled ? { ...{ size: 12 } } : {})}
          >
            {description}
          </SText>
        </View>
      </View>
    </View>
  );
};

export default ControlBoard;
