import { useGamePassedContext } from "apps/crypto-quiz/contexts/GamePassedContext";
import React, { useCallback } from "react";
import FullDescriptionPopup from "./FullDescriptionPopup";

const Container = () => {
  const {
    isShowPopupFullDescription: isShow,
    onShowPopupFullDescription,
    popupContent,
  } = useGamePassedContext();

  const handleClosePopup = useCallback(() => {
    onShowPopupFullDescription?.(false);
  }, []);

  const composeProps = {
    isShow,
    onCloseModal: handleClosePopup,
    content: popupContent,
  };
  return <FullDescriptionPopup {...composeProps} />;
};

export default Container;
