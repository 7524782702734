import { GAME_CRYPTO_QUIZ, GAME_MERRY_CHRISMAS, GAME_WORLD_CUP } from "constants/commons";
import React from "react";

const App = () => {

  const pickGame = (gameType: string | undefined) => {
    switch(gameType){
      case GAME_CRYPTO_QUIZ: 
        return require("./crypto-quiz").default;
      case GAME_WORLD_CUP: 
        return require("./world-cup").default;
      case GAME_MERRY_CHRISMAS: 
        return require("./merry-christmas").default;  
      default:
        return require("./spin-wheel").default;
    }
  };

  const MainApp = React.useMemo(() => {
    return pickGame(process.env.REACT_APP_GAME);
  }, [process.env.REACT_APP_GAME]);

  return <MainApp />;
};

export default App;
