import { isProdEnv, isDevEnv } from "utils/ConfigEnv";
import { init, track } from "@amplitude/analytics-browser";
import firebase from "firebase/app";
import "firebase/analytics";

let isAmplitudeReady = false;
let isFirebaseAnalyticsReady = false;

if (process.env.REACT_APP_AMPLITUDE_API_KEY) {
  init(process.env.REACT_APP_AMPLITUDE_API_KEY);
  isAmplitudeReady = true;
}

const firebase_api_key = process.env.REACT_APP_FIREBASE_ANALYTICS_API_KEY;
const firebase_project_id = process.env.REACT_APP_FIREBASE_ANALYTICS_PROJECT_ID;
const firebase_app_id = process.env.REACT_APP_FIREBASE_ANALYTICS_APP_ID;

if (firebase_api_key && firebase_project_id && firebase_app_id) {
  const firebaseConfig = {
    apiKey: firebase_api_key,
    projectId: firebase_project_id,
    appId: firebase_app_id,
  };

  firebase.initializeApp(firebaseConfig);
  isFirebaseAnalyticsReady = true;
}

export const sendEventLog = (eventName: string, params?: any) => {
  if (!eventName) return;

  if (!isProdEnv) {
    if (isDevEnv) console.log("sendEventLog", eventName, params);
    return;
  }

  try {
    if (isAmplitudeReady) {
      track(eventName, params);
    }
  } catch (e) {}
  try {
    if (isFirebaseAnalyticsReady) {
      firebase.analytics().logEvent(eventName, params);
    }
  } catch (e) {}
};

export default { sendEventLog };
