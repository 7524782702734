import { StyleSheet } from "utils/StyleSheet";

const styles = StyleSheet.create({
  contentHint: {
    paddingHorizontal: 10,
  },
  modal: {
    paddingHorizontal: 5,
  }
});

export default styles;
