import RankingService from "apps/crypto-quiz/services/RankingService";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useQuizAuthContext } from "../contexts/QuizAuthContext";

const useRankingData = () => {
  const [rankingType, setRankingType] = useState<RankingType>("daily");
  const [loadingRanking, setLoadingRanking] = useState<boolean>(true);
  const cachingResult = useRef<Ranking[]>([]);
  const cachingWeekly = useRef<String[]>([]);
  const cachingRange = useRef<string>("");
  const [weekNumber, setCurrentWeekly] = useState<number>(-1);
  const [totalNumberofWeeks, setTotalNumberofWeeks] = useState<number>(-1);

  const { username, loading: loadingAuth } = useQuizAuthContext();

  const firstTimeRef = useRef(true);

  const [leaderBoardByTime, setLeaderBoardByTime] = useState<{
    daily: Array<Ranking> | undefined;
    weekly:
      | { [key: string]: { data: Array<Ranking>; rangeTime: string } }
      | undefined;
  }>({
    daily: [],
    weekly: {},
  });

  const numOfWeek: any = weekNumber === -1 ? totalNumberofWeeks : weekNumber;

  const rankingData: Ranking[] = useMemo(() => {
    if (!loadingRanking) {
      rankingType === "weekly"
        ? (cachingResult.current =
            leaderBoardByTime.weekly?.[numOfWeek]?.data || [])
        : (cachingResult.current = leaderBoardByTime[rankingType] || []);
    }
    return cachingResult.current;
  }, [rankingType, leaderBoardByTime, numOfWeek, loadingRanking]);

  if (!loadingRanking) {
    cachingRange.current = leaderBoardByTime?.weekly?.[numOfWeek]
      ?.rangeTime as string;
  }

  const rangeTime =
    leaderBoardByTime?.weekly?.[numOfWeek]?.rangeTime || cachingRange.current;

  const rankOfUser = useMemo(
    () =>
      rankingData?.find((ranking: any) => ranking.name === username)?.rating,
    [username, rankingData]
  );

  const setDataWeekly = (data: RankingResponse, numOfWeek: number) => {
    setLeaderBoardByTime((currentValue) => {
      return {
        ...currentValue,
        weekly: {
          ...currentValue.weekly,
          [numOfWeek]: {
            data: data?.leaderboard,
            rangeTime: data?.timeRange,
          },
        },
      };
    });
  };

  const setDataDaily = (data: RankingResponse) => {
    setLeaderBoardByTime((currentValue) => ({
      ...currentValue,
      daily: data?.leaderboard,
    }));
  };

  const fetchRanking = async (rankingType: RankingType, weeklyNum = -1) => {
    setLoadingRanking(true);
    let params = {};
    if (rankingType === "weekly") {
      params = {
        number_of_periods:
          weeklyNum === totalNumberofWeeks ? -1 : weeklyNum - 1,
      };
    }
    const data: RankingResponse | undefined =
      await RankingService.getLeaderBoard({
        ...params,
        username,
        type: rankingType,
      });
    if (data) {
      if (rankingType === "daily") {
        setDataDaily(data);
      } else {
        const numOfWeek = weeklyNum === -1 ? data?.timeNumber : weeklyNum;
        if (totalNumberofWeeks == -1) {
          setTotalNumberofWeeks(data?.timeNumber);
        }
        setDataWeekly(data, numOfWeek);
      }
    }
    setLoadingRanking(false);
  };

  const handleSetRankingType = (type: RankingType) => {
    setRankingType(type);
    if (firstTimeRef.current) {
      firstTimeRef.current = false;
      fetchRanking(type);
    }
  };

  useEffect(() => {
    if (!loadingAuth) {
      if (!cachingWeekly.current.includes(rankingType)) {
        fetchRanking(rankingType);
        cachingWeekly.current.push(rankingType);
      }
    }
  }, [loadingAuth, rankingType]);

  const handleSetCurrentWeekly = useCallback(
    (weeklyNumber: number) => {
      setCurrentWeekly(weeklyNumber);
      if (!(weeklyNumber in (leaderBoardByTime.weekly as any))) {
        fetchRanking("weekly", weeklyNumber);
      }
    },
    [leaderBoardByTime.weekly]
  );

  return {
    rankingData,
    loadingRanking,
    rankingType,
    rangeTime,
    totalNumberofWeeks,
    rankOfUser,
    weekNumber: weekNumber === -1 ? totalNumberofWeeks : weekNumber,
    onSetRankingType: handleSetRankingType,
    onSetCurrentWeekly: handleSetCurrentWeekly,
  };
};

export default useRankingData;
