import React from "react";
import { StyleSheet, View } from "react-native";
import { HashRouter as Router, Routes, Route } from "react-router-dom";

import MC_SCREENS from "./screens/info";
import { HomeScreen } from "apps/world-cup/screens";
import DeviceInfo from "utils/DeviceInfo";

const MainApp = React.memo(() => {
  return (
    <Router>
      <Routes>
        <Route
          path={MC_SCREENS.HomeScreen.path}
          element={
            <HomeScreen />
          }
        />
      </Routes>
    </Router>
  );
});

const Navigation = () => {
  return (
    <View style={styles.container}>
      <View style={styles.contentContainer}>
        <MainApp />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
  },
  contentContainer: {
    width: DeviceInfo.CONTENT_WIDTH,
    height: "100%",
  },
  flex1: {
    flex: 1,
  },
});

export default Navigation;
