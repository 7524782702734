import RequestHelper from "utils/RequestHelper";
import QAlertHelper from "apps/crypto-quiz/utils/AlertHelper";

export type RequestResult = {
  user_id?: string;
  is_first_time_played: boolean;
  results: {
    id: number;
    answer: string;
    time_remaining: number;
  }[];
};

class RankingService {
  getQuestions = async (lang: string): Promise<Questions | undefined> => {
    try {
      const res = await RequestHelper.get("api/v1/quiz_questions/questions", { lang });
      if (res.data && res.data.questions.length) {
        return res.data.questions;
      } else {
        QAlertHelper.showModalAlert({ message: res.message });
      }
    } catch (error: any) {
      QAlertHelper.showModalAlert({ message: error?.message });
    }
  };
  submitResult = async (params: RequestResult): Promise<any> => {
    try {
      const res = await RequestHelper.post(
        "api/v1/quiz_questions/submit_answers",
        { ...params }
      );
      if (res?.data) {
        return res?.data;
      }
    } catch (error: any) {
      QAlertHelper.showModalAlert({ message: error?.message });
    }
  };

  updateTurnGamePlay = async () => {
    try {
      const res = await RequestHelper.post("api/v1/quiz_users/turn_number");
      if (res?.data) {
        return res?.data;
      }
    } catch (error: any) { }
  };

  increaseTurn = async () => {
    try {
      const res = await RequestHelper.post("api/v1/quiz_users/increase_turn");
      return res?.data;
    } catch (error: any) { }
  };
}

export default new RankingService();
