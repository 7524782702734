import { StyleSheet } from "utils/StyleSheet";

const styles = StyleSheet.create({
  text: {
    fontFamily: "Grandstander",
    color: "white",
    fontSize: 14,
  },
});

export default styles;
