import DeviceInfo from "utils/DeviceInfo";
import { StyleSheet } from "utils/StyleSheet";

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "flex-start",
    backgroundColor: "rgba(0, 0, 0, 0.6)",
    backdropFilter: "blur(10px)",
    marginTop: 48,
    width: 375,
    margin: "auto",
    height: DeviceInfo.HEIGHT,
  },
  contentContainer: {
    alignItems: "center",
    marginBottom: 24,
    marginTop: 6,
  },
  boardContainer: {
    marginHorizontal: 16,
  },
  rewardBox: {
    width: 242,
    textAlign: "center",
  },
  rewardDescription: {
    marginTop: 12,
    fontSize: 16,
    fontWeight: "400",
    lineHeight: 24,
  },
  confirmBtn: {
    marginTop: 24,
  },
  cancelBtn: {
    marginTop: 12,
    width: 247,
    height: 40,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
  },
  cancelTitle: {
    fontWeight: "500",
    fontSize: 18,
    lineHeight: 16,
  },
  close: {
    marginTop: 24,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
  },
  rewardContent: {
    paddingTop: 61,
  },
  confirmTitle: {
    fontSize: 18,
  },
  closeOutSite: {
    position: "absolute",
    zIndex: 2,
    height: 60,
    width: "100%",
    top: -100,
  },
  headerImg: {
    width: 100,
    height: 100,
    resizeMode: "contain",
  },
});
export default styles;
