import { useCallback, useEffect, useRef, useState } from "react";

export type CountDownProps = {
  total?: number;
  duration?: number;
  jumpDown?: number;
  minCount?: number;
  isStart?: boolean;
};

const useCountDown = ({
  total = 10,
  duration = 1000,
  jumpDown = 1,
  minCount = 0,
  isStart = true,
}: CountDownProps) => {
  const [tick, setTick] = useState(total);
  const timer = useRef<any>();

  useEffect(() => {
    if (!isStart) {
      return;
    } else {
      timer.current = setTimeout(() => {
        setTick(tick - jumpDown);
      }, duration);
      if (tick === minCount) {
        clearTimeout(timer.current);
      }
    }

    return () => clearTimeout(timer.current);
  }, [tick, isStart]);

  const handleResetCountDown = useCallback(() => {
    clearTimeout(timer.current);
    setTick(total);
  }, []);

  const handleSetEndTime = useCallback(() => {
    clearTimeout(timer.current);
    setTick(0);
  }, []);

  return {
    tick,
    resetCountDown: handleResetCountDown,
    setEndTime: handleSetEndTime,
  };
};

export default useCountDown;
