import React from "react";
import { SText, SvgIcon } from "base-components";
import { View } from "react-native";
import styles from "./styles";

const MedalBoard: React.FC = () => {
  return (
    <View style={styles.container}>
      <View style={styles.item}>
        <SvgIcon name="medal2" size={32} />
        <SText size={16} style={styles.title}>
          20 AZA
        </SText>
        <SText size={14} style={styles.rating}>
          Top 2
        </SText>
      </View>
      <View style={styles.item}>
        <SvgIcon name="medal1" size={32} />
        <SText size={16} style={styles.title}>
          30 AZA
        </SText>
        <SText size={14} style={styles.rating}>
          Top 1
        </SText>
      </View>
      <View style={styles.item}>
        <SvgIcon name="medal3" size={32} />
        <SText size={16} style={styles.title}>
          10 AZA
        </SText>
        <SText size={14} style={styles.rating}>
          Top 3
        </SText>
      </View>
    </View>
  );
};

export default MedalBoard;
