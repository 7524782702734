const QuizImages = {
  bgLandingEn: require("assets/crypto-quiz/landing-en.gif"),
  bgLandingVi: require("assets/crypto-quiz/landing-vi.gif"),
  bottomLanding: require("assets/crypto-quiz/bottom-landing.png"),
  imgShare: require("assets/crypto-quiz/quiz-share.png"),
  imgHandclap: require("assets/crypto-quiz/quiz-handclap.png"),
  bgGamePlayDemo: require("assets/crypto-quiz/game-play-demo.png"),
  heartIcon: require("assets/crypto-quiz/heart.png"),
  winningResult: require("assets/crypto-quiz/quiz-winning-result.png"),
  finishingResult: require("assets/crypto-quiz/quiz-finishing-result.png"),
  coinQuestion: require("assets/crypto-quiz/coin-question.png"),
  coinQuestion1: require("assets/crypto-quiz/coin-question-1.jpg"),
  bgHowToPlayVi: require("assets/crypto-quiz/bg-how-to-play-vi.png"),
  bgHowToPlayEn: require("assets/crypto-quiz/bg-how-to-play-en.png"),
  bgIntro: require("assets/crypto-quiz/bg-intro.png"),
  huggingFace: require("assets/crypto-quiz/hugging-face.png"),
};

export default QuizImages;
