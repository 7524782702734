import React from "react";
import { View, TouchableOpacity } from "react-native";
import { SvgIcon } from "base-components";
import Locale from "locale";
import { QText } from "apps/crypto-quiz/components";

import styles from "./style";

interface Props {
  weekNumber: number;
  totalNumberOfWeeks: number;
  rangeTime: string;
  onPrev?: VoidFunction;
  onNext?: VoidFunction;
}
const RankingChartFooter: React.FC<Props> = (timeInfo) => {
  const { weekNumber, totalNumberOfWeeks, rangeTime, onPrev, onNext } =
    timeInfo;
  const isFirstWeek = weekNumber === 1;
  const isLastWeek = weekNumber === totalNumberOfWeeks;

  return (
    <View style={styles.dateBox}>
      <TouchableOpacity
        onPress={onPrev}
        disabled={isFirstWeek}
        style={{ opacity: isFirstWeek ? 0.2 : 1.0 }}
      >
        <SvgIcon name="prev" size={32} />
      </TouchableOpacity>

      <QText style={styles.dateContent}>
        {Locale.translate("week")} {weekNumber} ({rangeTime})
      </QText>

      <TouchableOpacity
        onPress={onNext}
        disabled={isLastWeek}
        style={{ opacity: isLastWeek ? 0.2 : 1.0 }}
      >
        <SvgIcon name="next" size={32} />
      </TouchableOpacity>
    </View>
  );
};

export default RankingChartFooter;
