import { StyleSheet } from "utils/StyleSheet";
import COLORS from "utils/CommonColors";

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: COLORS.violet1000,
  },
  viewImg: {
    flex: 1,
    alignItems: "center",
    marginTop: 80,
  },
  img: {
    width: 200,
    height: 220,
  },
  viewDescription: {
    width: 150,
    marginTop: 16,
  },
  viewBtn: {
    paddingBottom: 30,
  },
  description: {
    fontSize: 16,
    fontWeight: "700",
    lineHeight: 26,
    color: COLORS.white,
    textAlign: "center",
  },
  btn: {
    alignSelf: "center",
    marginBottom: 20,
  },
  btnShare: {
    color: COLORS.white,
    fontSize: 16,
    lineHeight: 24,
    fontWeight: "700",
  },
  btnReview: {
    color: COLORS.lightPurple500,
    fontSize: 16,
    lineHeight: 24,
    fontWeight: "500",
  },
});

export default styles;
