import React from "react";
import { ScrollView, View } from "react-native";
import DeviceInfo from "utils/DeviceInfo";
import GamePassedItem from "../GamePassedItem";

import styles from "./styles";
type Props = {
  onShowPopup?: (value?: any) => void;
  gamesPassed: GamePassed;
};

const GamesPassed = ({ onShowPopup, gamesPassed }: Props) => {
  return (
    <ScrollView
      showsVerticalScrollIndicator={false}
      showsHorizontalScrollIndicator={false}
    >
      <View
        style={[
          styles.content,
          { height: DeviceInfo.HEIGHT, width: DeviceInfo.CONTENT_WIDTH },
        ]}
      >
        {gamesPassed?.questions.map((question: any, index: number) => (
          <GamePassedItem
            onReadMore={onShowPopup}
            key={index}
            index={index + 1}
            question={question}
          />
        ))}
      </View>
    </ScrollView>
  );
};

export default React.memo(GamesPassed);
