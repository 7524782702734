import React from "react";
import { StyleSheet, View, ViewStyle } from "react-native";
import { animated, useSpring } from "@react-spring/native";
import SvgIcon from "../SvgIcon";
const AnimatedView = animated(View);

interface Props {
  icon?: string;
  style?: ViewStyle;
  width?: number;
  height?: number;
  size?: number;
}

const AuraSpin = ({ icon, style = {}, width, height, size }: Props) => {
  const { rotateZ } = useSpring({
    from: { rotateZ: "0deg" },
    rotateZ: "360deg",
    loop: true,
    reset: true,
    config: { duration: 5000 },
  });

  return (
    <AnimatedView
      style={{
        ...styles.aura,
        transform: [
          {
            rotateZ,
          },
        ],
        ...style,
      }}
    >
      <SvgIcon
        name={icon || "aura"}
        width={width}
        height={height}
        size={size}
      />
    </AnimatedView>
  );
};

const styles = StyleSheet.create({
  aura: {
    position: "absolute",
    alignItems: "center",
    zIndex: 0,
    left: 0,
    right: 0,
    top: 0,
  },
});

export default AuraSpin;
