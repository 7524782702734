import { StyleSheet } from "utils/StyleSheet";

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "space-around",
  },
  congraText: {
    width: "80%",
    marginLeft: "auto",
    marginRight: "auto",
    textAlign: "center",
    marginTop: 35,
    fontSize: 18,
  },
  btnContinue: {
    marginTop: 20,
  },
  btnReview: {
    marginTop: 10,
  },
});

export default styles;
