import { COLORS } from "@remitano/remitano-ui-kit";
import { StyleSheet } from "utils/StyleSheet";

const styles = StyleSheet.create({
  tabBar: {
    marginTop: 16,
  },
  itemTabFirst: {
    marginLeft: 15,
  },
  itemTabLast: {
    marginRight: 15,
  },
  itemTab: {
    backgroundColor: COLORS.violet900,
    paddingVertical: 12,
    paddingHorizontal: 24,
    borderRadius: 30,
    margin: 5,
  },
  itemTabActive: {
    backgroundColor: COLORS.primaryA,
  },
  tabText: {
    fontWeight: "700",
  },
});

export default styles;
