import React from "react";
import { View } from "react-native";
import { CardView, QText } from "apps/crypto-quiz/components";
import styles from "./style";
import Locale from "locale";

const HowToPlayItem: React.FC = () => {

  return (
    <View style={styles.view}>
      <CardView>
        <QText style={styles.title}>{Locale.translate("how_to_play")}</QText>
        <View style={styles.h10}></View>
        <QText style={styles.description}>{Locale.translate("how_to_step_1")}</QText>
        <QText style={styles.description}>{Locale.translate("how_to_step_2")}</QText>
        <QText style={styles.description}>{Locale.translate("how_to_step_3")}</QText>
        <QText style={styles.description}>{Locale.translate("how_to_step_4")}</QText>
        <QText style={styles.description}>{Locale.translate("how_to_step_5")}</QText>
        <QText style={styles.description}>{Locale.translate("how_to_step_6")}</QText>
        <QText style={styles.description}>{Locale.translate("how_to_step_7")}</QText>
        <View style={styles.h10}></View>
      </CardView>
    </View>
  );
};

export default React.memo(HowToPlayItem);
