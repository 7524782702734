import Locale from "locale";
import DeviceInfo from "./DeviceInfo";
import RemitanoBrowser from "./RemitanoBrowser";

export const getConfig = async () => {
  try {
    const config = await RemitanoBrowser.getDeviceInfoAsync();
    let deviceInfo: any = {};
    deviceInfo = config;
    if (deviceInfo.language) {
      Locale.setLang(deviceInfo.language);
    }
    DeviceInfo.setInfo(deviceInfo);
    return deviceInfo;
  } catch (error) {
    return null;
  }
};
