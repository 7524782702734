import React from "react";
import { SvgIcon } from "base-components";
import { View, Modal, TouchableOpacity, Clipboard } from "react-native";
import {
  EmailShareButton,
  TelegramShareButton,
  TwitterShareButton,
  FacebookIcon,
  TwitterIcon,
  EmailIcon,
  TelegramIcon,
  FacebookShareButton,
} from "react-share";
import DeviceInfo from "utils/DeviceInfo";
import QText from "../QText";
import { scale } from "utils/StyleSheet";
import styles from "./styles";
import Locale from "locale";
import { shareUrl } from "apps/crypto-quiz/utils/Social";

export interface ModalRef {
  showModal(): void;
  hideModal(): void;
}

const campaign_name = "Coinlympia";

type Props = null;

const ShareModal = React.forwardRef<ModalRef, any>(
  (props: Props, ref) => {
    const [isShow, setShowModal] = React.useState(false);
    const [copied, setCopyStatus] = React.useState(false);
    const title = Locale.translate("q_title_share") || campaign_name;

    React.useImperativeHandle(ref, () => ({
      showModal: () => {
        setShowModal(true);
      },
      hideModal: () => {
        setShowModal(false);
      },
    }));

    const onClose = () => setShowModal(false);

    const onCopy = async () => {
      try {
        await Clipboard.setString(title);
        setCopyStatus(true);
      } catch (error) { }
    };

    return (
      <Modal
        animationType="slide"
        transparent={true}
        visible={isShow}
        onRequestClose={onClose}
      >
        <View style={styles.container}>
          <View style={[styles.content, { width: DeviceInfo.CONTENT_WIDTH }]}>
            <View style={styles.header}>
              <View style={styles.headerLeft}>
                <SvgIcon size={41} name="shareDefault" style={styles.mr8} />
                <View>
                  <QText size={15} style={styles.campainName}>
                    {campaign_name}
                  </QText>
                  <QText size={13} style={styles.campainLink}>
                    {shareUrl}
                  </QText>
                </View>
              </View>
              <TouchableOpacity onPress={onClose}>
                <SvgIcon size={30} name="ic_close2" />
              </TouchableOpacity>
            </View>
            <View style={styles.hr} />
            <View style={[styles.share, styles.widthFull]}>
              <TouchableOpacity style={styles.copy} onPress={onCopy}>
                <QText size={17} color="#000" style={styles.copyText}>
                  {copied ? "Copied" : "Copy"}
                </QText>
                <SvgIcon size={26} name="copy" />
              </TouchableOpacity>
            </View>
            <View style={styles.hr} />
            <View style={styles.share}>
              <View style={[styles.itemCenter]}>
                <FacebookShareButton url={shareUrl} title={title}>
                  <FacebookIcon size={scale(60)} round />
                </FacebookShareButton>
                <QText size={11} color="#000" style={styles.mt6}>
                  Facebook
                </QText>
              </View>
              <View style={[styles.itemCenter]}>
                <TwitterShareButton url={shareUrl} title={title}>
                  <TwitterIcon size={scale(60)} round />
                </TwitterShareButton>
                <QText size={11} color="#000" style={styles.mt6}>
                  Twitter
                </QText>
              </View>
              <View style={[styles.itemCenter]}>
                <EmailShareButton url={shareUrl} subject={title} body={title}>
                  <EmailIcon size={scale(60)} round />
                </EmailShareButton>
                <QText size={11} color="#000" style={styles.mt6}>
                  Email
                </QText>
              </View>
              <View style={[styles.itemCenter]}>
                <TelegramShareButton url={shareUrl} title={title}>
                  <TelegramIcon size={scale(60)} round />
                </TelegramShareButton>
                <QText size={11} color="#000" style={styles.mt6}>
                  Telegram
                </QText>
              </View>
            </View>
          </View>
        </View>
      </Modal>
    );
  }
);

ShareModal.displayName = "ShareModal";

export default ShareModal;


