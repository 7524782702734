import React, { useMemo } from "react";
import { Pressable, Text, View } from "react-native";
import { useSelector } from "react-redux";
import { GlobalState } from "stores/types/store";
import RemitanoBrowser from "utils/RemitanoBrowser";
import { SvgIcon } from "base-components";
import styles from "./styles";
import { scale } from "utils/StyleSheet";
import { sendEventLog } from "utils/EventLogger";
import ShareModal, { ModalRef } from "./ShareModal";
import { gameHubPath } from "utils/RequestHelper";
import { openUrl } from "apps/spin-wheel/screens/Home/components/utils";

interface Props {
  title: string;
  onBack?(): void;
}

const NavBar: React.FC<Props> = ({ title, onBack }) => {
  const modalRef = React.useRef<ModalRef>(null);
  const { statusBarHeight, isRemiBrowser } = useSelector(
    (state: GlobalState) => state.device
  );
  const { isNewVersionEnabled } = useSelector(
    (state: GlobalState) => state.campaign
  );

  const newPage = useMemo(() => {
    if (isNewVersionEnabled) {
      return {
        iconGameHub: "new_ic_gamehub",
        iconShare: "new_ic_share",
        containerStyle: styles.newContainer,
      };
    }
    return {
      iconGameHub: "ic_gamehub",
      iconShare: "ic_share",
      containerStyle: styles.container,
    };
  }, [isNewVersionEnabled]);

  const onPressShare = () => {
    sendEventLog("click_share_luckydraw", { button_place: "navbar" });
    if (isRemiBrowser) return RemitanoBrowser.shareScreen();
    modalRef?.current?.showModal();
  };

  const onOpenGameHub = () => {
    openUrl(isRemiBrowser, gameHubPath);
  };

  return (
    <View style={newPage.containerStyle}>
      <View style={{ height: isRemiBrowser ? scale(statusBarHeight) : 0 }} />
      <View style={styles.centerItem}>
        <Pressable style={styles.icBack} onPress={onBack}>
          <SvgIcon name="ic_back" size={24} />
        </Pressable>
        <Pressable onPress={onOpenGameHub}>
          <SvgIcon name={newPage.iconGameHub} size={48} />
        </Pressable>
        <View style={styles.titleContainer}>
          <Text style={styles.title}>{title}</Text>
        </View>
        <Pressable onPress={onPressShare}>
          <SvgIcon name={newPage.iconShare} size={48} />
        </Pressable>
      </View>
      <ShareModal ref={modalRef} />
    </View>
  );
};

export default NavBar;
