import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { DeviceAction } from "stores/actions";
import { GlobalState } from "stores/types/store";

const usePopupAlert = () => {
  const { confirm } = useSelector((state: GlobalState) => state.device);
  const dispatch = useDispatch();
  
  const onClose = () =>
    dispatch({
      type: DeviceAction.SHOW_CONFIRM_MODAL,
      data: undefined,
    });

  return {
    onClose,
    confirm,
  };
};

export default usePopupAlert;
