import { AxiosResponse } from "axios";
import QAlertHelper from "apps/crypto-quiz/utils/AlertHelper";
import RequestHelper from "utils/RequestHelper";

class RankingService {
  getLeaderBoard = async ({
    type = "daily",
    limit = 5,
    number_of_periods = -1,
    username,
  }: {
    type?: RankingType;
    limit?: number;
    number_of_periods?: number;
    username?: string;
  }): Promise<RankingResponse | undefined> => {
    try {
      const params = { type, limit, number_of_periods, username };
      const res: AxiosResponse = await RequestHelper.get(
        "/api/v1/quiz_score_daily_snapshots/leaderboard",
        { ...params }
      );
      if (res.data) {
        return res.data;
      }
    } catch (error: any) {
      QAlertHelper.showModalAlert({ message: error?.message });
    }
  };
}

export default new RankingService();
