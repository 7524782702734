import React from "react";
import { FlatList, View } from "react-native";
import { TabsData } from "./container";
import styles from "./styles";

type Props = {
  activeTab?: number;
  tabsData: TabsData[];
  renderItem: (data: any) => any;
};

const GamePassedTabBar = React.forwardRef(
  ({ activeTab, tabsData = [], renderItem }: Props, ref: any) => {
    return (
      <>
        {tabsData.length ? (
          <View style={styles.tabBar}>
            <FlatList
              contentContainerStyle={{ flexGrow: 1 }}
              initialScrollIndex={activeTab}
              ref={ref}
              showsVerticalScrollIndicator={false}
              showsHorizontalScrollIndicator={false}
              horizontal
              data={tabsData}
              renderItem={(data) => renderItem({ ...data, active: activeTab })}
              keyExtractor={(item) => item.id}
            />
          </View>
        ) : <></>}
      </>
    );
  }
);

GamePassedTabBar.displayName = "GamePassedTabBar";

export default GamePassedTabBar;
