import React, {
  PropsWithChildren,
  useContext,
  useEffect,
  useMemo,
} from "react";
import { useNavigate } from "react-router-dom";
import useAuth from "../hooks/useAuth";
import QUIZ_SCREENS from "../screens/info";

export const LIMIT_TIMER = 15;
export const DELAY_NEXT_QUESTION = 10000;
export const DELAY_SHOW_HINT = 500;
export const GAME_PLAY_CACHING = "game-play-caching";

export type QuizAuthContextValue = {
  fetch?: () => void;
  loading?: boolean;
  userId?: string;
  username?: string;
};

export const QuizAuthContext = React.createContext<QuizAuthContextValue>({
  loading: false,
});

type Props = PropsWithChildren;

const QuizAuthProvider = ({ children }: Props) => {
  const navigation = useNavigate();
  const { fetch, loading, userId, username } = useAuth({ initLoad: true });

  useEffect(() => {
    if (localStorage.getItem(GAME_PLAY_CACHING)) {
      navigation(QUIZ_SCREENS.LandingScreen.path);
    }
  }, []);

  const valueContext: QuizAuthContextValue = useMemo(
    () => ({
      fetch,
      loading,
      userId,
      username,
    }),
    [loading, userId, username]
  );
  return (
    <QuizAuthContext.Provider value={{ ...valueContext }}>
      {children}
    </QuizAuthContext.Provider>
  );
};

export default QuizAuthProvider;

export const useQuizAuthContext = () => useContext(QuizAuthContext);
