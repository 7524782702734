import { StyleSheet } from "utils/StyleSheet";
import COLORS from "utils/CommonColors";

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 16,
    backgroundColor: COLORS.violet1000,
  },
  tabBar: {
    marginTop: 16,
    marginBottom: 26,
  },
  title: {
    fontSize: 18,
    fontWeight: "700",
    lineHeight: 24,
    color: COLORS.white,
    textAlign: "center",
    marginTop: 16,
    marginBottom: 8,
    paddingHorizontal: 24,
  },
  description: {
    fontSize: 16,
    fontWeight: "400",
    lineHeight: 24,
    color: COLORS.white,
    textAlign: "center",
    paddingHorizontal: 24,
  },
  img: {
    width: 100,
    height: 100,
    alignSelf: "center",
  },
  btn: {
    alignSelf: "center",
    marginTop: 22,
    marginBottom: 20,
  },
  btnTitle: {
    fontSize: 16,
    fontWeight: "500",
    lineHeight: 24,
    color: COLORS.white,
    textAlign: "center",
  },
});

export default styles;
