import Locale from "locale";
import Store from "stores";
import { DeviceAction } from "stores/actions";

class AlertHelper {
  showError = (error: any) => {
    if (!error) { return; }
    let message = "";
    if (typeof error === "string") {
      if (error.includes("JSON Parser error")) {
        message = Locale.translate("other_something_went_wrong");
      } else {
        message = error;
      }
    } else {
      if (typeof error.message === "string") { message = error.message; }
      else { message = JSON.stringify(error); }
    }
    if (Store.getState().device?.isCustomModalReady) {
      Store.dispatch({
        type: DeviceAction.SHOW_CUSTOM_ALERT,
        data: {
          message,
          type: "error",
          img: "pepe-stop",
        }
      });
    } else {
      alert(message);
    }
  };
}

export default new AlertHelper();
