import COLORS from "utils/CommonColors";
import DeviceInfo from "utils/DeviceInfo";
import { StyleSheet } from "utils/StyleSheet";

const defaultStyles = StyleSheet.create({
  whiteText: {
    color: COLORS.white,
  },
  whiteBoldText: {
    fontWeight: "bold",
    color: COLORS.white,
  },
  boldText: {
    fontWeight: "bold",
  },
});

const styles = StyleSheet.create({
  flex4: {
    flex: 4,
  },
  flex1: {
    flex: 1,
  },
  flex2: {
    flex: 2,
  },
  flex13: {
    flex: 13,
  },
  congratInfo: {
    alignItems: "center",
    justifyContent: "center",
  },
  banner: {
    width: 144,
    height: 144,
    marginBottom: 12,
  },
  lottie: {
    position: "absolute",
    zIndex: 1,
    pointerEvents: "none",
    top: 50,
    left: "50%",
    width: "100%",
    transform: [{ translateX: -DeviceInfo.CONTENT_WIDTH / 2 }]
  },
  container: {
    flex: 1,
    backgroundColor: COLORS.violet1000,
  },
  winningCongrat: {
    fontSize: 18,
    lineHeight: 26,
    ...defaultStyles.whiteBoldText,
  },
  textDetails: {
    fontWeight: "400",
    fontSize: 14,
    lineHeight: 20,
    marginTop: 12,
    ...defaultStyles.whiteText,
    alignItems: "center",
    flex: 1,
  },
  textCorrectAnswer: {
    fontWeight: "400",
    fontSize: 14,
    lineHeight: 20,
    marginTop: 12,
    marginBottom: 8,
    ...defaultStyles.whiteText,
  },
  totalAnswers: {
    fontSize: 24,
    lineHeight: 32,
    ...defaultStyles.whiteBoldText,
  },
  numberOfCorrectAnswer: {
    color: COLORS.green500,
    fontSize: 24,
    lineHeight: 32,
    ...defaultStyles.boldText,
  },
  pointContent: {
    alignItems: "center",
    flexDirection: "row",
    marginTop: 8,
  },
  points: {
    color: COLORS.yellow500,
    fontSize: 24,
    lineHeight: 32,
    marginRight: 6,
    ...defaultStyles.boldText,
  },
  playNow: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    fontSize: 16,
  },
  actionWrapper: {
    paddingHorizontal: 32,
  },
  btnPlayTitle: {
    fontSize: 16,
  },
  actionMore: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: 16,
  },
  btnMore: {
    flex: 1,
  },
  btnMoreText: {
    fontSize: 16,
  },
  actionMoreSpace: {
    width: 11,
  },
});

export default styles;
