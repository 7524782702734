import { StyleSheet } from "utils/StyleSheet";

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingHorizontal: 16,
    justifyContent: "center",
    backgroundColor: "rgba(0, 0, 0, 0.6)",
    backdropFilter: "blur(10px)",
    marginTop: 48,
    width: 375,
    margin: "auto",
  },
  title: {
    fontSize: 16,
    lineHeight: 24,
    textAlign: "center",
    marginTop: 16,
  },
  contentContainer: {
    alignItems: "center",
    marginBottom: 24,
    marginTop: 6,
  },
  rewardBox: {
    width: 242,
    textAlign: "center",
  },
  rewardDescription: {
    marginTop: 12,
    fontSize: 16,
    fontWeight: "400",
    lineHeight: 24,
  },
  close: {
    marginTop: 24,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    padding: 20,
  },
  rewardContent: {
    paddingTop: 61,
  },
  headerImage: {
    width: 100,
    height: 100,
    resizeMode: "contain",
  }
});
export default styles;
