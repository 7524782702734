import React from "react";
import { ActivityIndicator, ScrollView, View } from "react-native";
import GamesPassed from "../GamesPassed";
import { Empty } from "apps/crypto-quiz/components";
import Locale from "locale";
import styles from "./styles";

type Props = {
  passedsGame: any;
  loadingGamePassed: boolean;
};

const GamePassedWrapper = React.forwardRef(
  ({ passedsGame, loadingGamePassed }: Props, ref: any) => (
    <>
      {!loadingGamePassed ? (
        <>
          {passedsGame?.length ? (
            <ScrollView
              scrollEnabled={false}
              ref={ref}
              showsVerticalScrollIndicator={false}
              showsHorizontalScrollIndicator={false}
              horizontal
              pagingEnabled
            >
              {passedsGame?.map((gamePassed: GamePassed, index: number) => (
                <GamesPassed gamesPassed={gamePassed} key={index} />
              ))}
            </ScrollView>
          ) : (
            !loadingGamePassed && (
              <Empty
                title={Locale.translate("title_empty_game_passed")}
                subTitle={Locale.translate("sub_empty_game_passed")}
                titleButton={Locale.translate("play_now")}
              />
            )
          )}
        </>
      ) : (
        <View style={styles.wrapperEmpty}>
          <ActivityIndicator />
        </View>
      )}
    </>
  )
);

GamePassedWrapper.displayName = "GamePassedWrapper";

export default GamePassedWrapper;
