import React from "react";
import Locale from "locale";
import { useNavigate } from "react-router-dom";
import QUIZ_SCREENS from "apps/crypto-quiz/screens/info";

import RankingWrapper from "./RankingWrapper";
import { useRankingContext } from "apps/crypto-quiz/contexts/RankingContext";

const container = () => {
  const navigate = useNavigate();

  const {
    rankingData,
    loadingRanking,
    rankingType,
    weekNumber,
    rangeTime,
    totalNumberofWeeks,
    rankOfUser,
    onSetRankingType,
    onSetCurrentWeekly,
  } = useRankingContext();

  const TABS = [Locale.translate("daily"), Locale.translate("weekly")];

  const isWeeky = rankingType === "weekly";

  const handleSelectTab = (index: number) => {
    const typeChart = index === 0 ? "daily" : "weekly";
    onSetRankingType?.(typeChart);
  };

  const handleShare = () => {
    navigate(QUIZ_SCREENS.ShareScreen.path);
  };

  const handleNext = () => {
    onSetCurrentWeekly?.(weekNumber + 1);
  };

  const handlePrev = () => {
    onSetCurrentWeekly?.(weekNumber - 1);
  };

  const composeProps = {
    rankingData,
    loadingRanking,
    tabs: TABS,
    isWeeky,
    weekNumber,
    totalNumberofWeeks: totalNumberofWeeks,
    rangeTime,
    rankOfUser,
    onShare: handleShare,
    onSelectTab: handleSelectTab,
    handleNext,
    handlePrev,
  };

  return <RankingWrapper {...composeProps} />;
};

export default container;
