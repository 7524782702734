import COLORS from "utils/CommonColors";
import { StyleSheet } from "utils/StyleSheet";
const styles = StyleSheet.create({
  container: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    paddingBottom: 8,
    paddingTop: 10,
  },
  newContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    paddingBottom: 8,
    paddingTop: 10,
  },
  borderContainer: {
    borderBottomColor: COLORS.violet800,
    borderBottomWidth: 1,
  },
  newBorderContainer: {
    borderBottomColor: "#EAD6FE",
    borderBottomWidth: 2,
  },
  currentUser: {
    backgroundColor: "#9082F7",
    bottom: 0,
  },
  newCurrentUser: {
    backgroundColor: "#9C3CE8",
    bottom: 0,
  },
  content: {
    marginHorizontal: 12,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  newContent: {
    marginHorizontal: 12,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  gem: {
    width: 14,
    height: 20,
    resizeMode: "contain",
  },
  name: {
    marginLeft: 12,
    lineHeight: 36,
    width: 199,
  },
  coinNumber: {
    // lineHeight: 24,
    marginRight: 3,
  },
  rating: {
    color: COLORS.lightPurple500,
    lineHeight: 24,
    textAlign: "right",
    minWidth: 18,
  },
  currentRating: {
    color: COLORS.white,
  },
  flex1: {
    flex: 1,
  },
  boderTop: {
    borderTopLeftRadius: 12,
    borderTopRightRadius: 12,
  },
});

export default styles;
