import React, { useMemo } from "react";
import { SText, SvgIcon } from "base-components";
import { View, Image } from "react-native";
import styles from "./styles";
import { Ranking } from "stores/types/ranking";
import { Moment } from "moment";

interface Props {
  rankInfo: Ranking | undefined;
  index?: number;
  currentDate: Moment;
  isNewVersionEnabled?: boolean;
  outView?: boolean;
}

const UserRankItem: React.FC<Props> = ({
  rankInfo,
  index,
  currentDate,
  isNewVersionEnabled,
  outView,
}) => {
  if (!rankInfo) return <View style={styles.flex1} />;

  const medal = useMemo(() => {
    switch (rankInfo.rating) {
      case 1:
      case 2:
      case 3:
        return <SvgIcon name={`medal${rankInfo.rating}`} size={24} />;
      default:
        return (
          <SText
            size={16}
            style={{
              ...styles.rating,
              ...(rankInfo.isLocal && styles.currentRating),
            }}
          >
            {rankInfo.rating === -1 ? "-" : rankInfo.rating}
          </SText>
        );
    }
  }, [rankInfo.isLocal, rankInfo.rating]);

  const newPage = useMemo(() => {
    if (isNewVersionEnabled) {
      return {
        containerStyle: styles.newContainer,
        currentUserStyle: styles.newCurrentUser,
        contentStyle: styles.newContent,
        borderContainerStyle: styles.newBorderContainer,
      };
    }
    return {
      containerStyle: styles.container,
      currentUserStyle: styles.currentUser,
      contentStyle: styles.content,
      borderContainerStyle: styles.borderContainer,
    };
  }, [isNewVersionEnabled]);

  return (
    <View
      style={[
        newPage.containerStyle,
        rankInfo.isLocal && newPage.currentUserStyle,
        !outView && newPage.borderContainerStyle,
        index === 0 && styles.boderTop,
      ]}
    >
      <View style={newPage.contentStyle}>
        {medal}
        <SText style={styles.name} size={16} bold>
          {rankInfo.name}
          {rankInfo.isLocal && " (you)"}
        </SText>
      </View>
      <View style={styles.content}>
        <SText style={styles.coinNumber} size={16} bold>
          {rankInfo.balance}
        </SText>
        <Image
          source={require("assets/spin-wheel/big-gem.png")}
          style={styles.gem}
        />
      </View>
    </View>
  );
};

export default UserRankItem;
