import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import QUIZ_SCREENS from "../info";
import ResultScreen from "./ResultScreen";
import { loginPath } from "utils/RequestHelper";
import RemitanoBrowser from "utils/RemitanoBrowser";
import { useSelector } from "react-redux";
import { GlobalState } from "stores/types/store";
import { ModalRef } from "apps/crypto-quiz/components/ShareModal";
import CommonHelper from "apps/crypto-quiz/utils/CommonHelper";
import { useQuizGlobalContext } from "apps/crypto-quiz/contexts/QuizGlobalContext";
import { useQuizAuthContext } from "apps/crypto-quiz/contexts/QuizAuthContext";

type LocationState = {
  state: {
    score?: string;
    correctAnswers?: number;
    isWinning: boolean;
    total: number;
  };
};

const Container = () => {
  const navigate = useNavigate();
  const location = useLocation() as LocationState;
  const { isRemiBrowser } = useSelector((state: GlobalState) => state.device);

  const modalRef = React.useRef<ModalRef>(null);
  const { userId } = useQuizAuthContext();
  const { isHasTurn } = useQuizGlobalContext();

  const isLogin = Boolean(userId);

  const handleShare = () => {
    if (isRemiBrowser) return RemitanoBrowser.shareScreen();
    modalRef?.current?.showModal();
  };

  const handleGoReviewAnwser = () => {
    navigate(QUIZ_SCREENS.GamePassedScreen.path);
  };

  const handleGoRanking = () => {
    navigate(QUIZ_SCREENS.RankingChartScreen.path);
  };

  const handleContinuePlay = () => {
    if (!isLogin) {
      CommonHelper.redirectUrl(isRemiBrowser, loginPath);
      return;
    }
    if (isHasTurn) {
      navigate(QUIZ_SCREENS.GamePlayScreen.path);
    } else {
      navigate(QUIZ_SCREENS.ShareScreen.path);
    }
  };

  const { score, correctAnswers, isWinning, total } = location.state || {};

  const composeProps = {
    onShare: handleShare,
    onGoReviewAnwser: handleGoReviewAnwser,
    onGoRanking: handleGoRanking,
    onContinuePlay: handleContinuePlay,
    score,
    correctAnswers,
    isWinning,
    total,
    isLogin,
  };

  return <ResultScreen ref={modalRef} {...composeProps} />;
};

export default Container;
