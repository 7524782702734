import React from "react";
import { TouchableOpacity, TouchableOpacityProps, View } from "react-native";
import COLORS from "utils/CommonColors";
import { StyleSheet } from "utils/StyleSheet";

type Variant = "none" | "normal" | "active" | "success" | "error" | "disabled";

interface Props extends TouchableOpacityProps {
  variant?: Variant;
}

const BoxView: React.FC<Props> = ({
  children,
  style = {},
  variant,
  ...restProps
}) => {
  const {
    shadowBoxView,
    boxViewNormal,
    boxViewActive,
    boxView,
    boxViewSuccess,
    boxViewError,
    boxViewDisabled,
  } = defaultStyle;
  let combineStyles = {};
  switch (variant) {
    case "normal":
      combineStyles = StyleSheet.flatten([
        boxView,
        shadowBoxView,
        boxViewNormal,
        style,
      ]);
      break;
    case "active":
      combineStyles = StyleSheet.flatten([
        boxView,
        shadowBoxView,
        boxViewActive,
        style,
      ]);
      break;
    case "success":
      combineStyles = StyleSheet.flatten([
        boxView,
        shadowBoxView,
        boxViewSuccess,
        style,
      ]);
      break;
    case "error":
      combineStyles = StyleSheet.flatten([
        boxView,
        shadowBoxView,
        boxViewError,
        style,
      ]);
      break;
    case "disabled":
      combineStyles = StyleSheet.flatten([
        boxView,
        shadowBoxView,
        boxViewDisabled,
        style,
      ]);
      break;
    default:
      combineStyles = StyleSheet.flatten([boxView, style]);
      break;
  }

  const disabled = variant === "disabled";

  return (
    <TouchableOpacity style={combineStyles} disabled={disabled} {...restProps}>
      {children}
      {disabled && <View style={defaultStyle.overlay}></View>}
    </TouchableOpacity>
  );
};
export default BoxView;
const defaultStyle = StyleSheet.create({
  boxView: {
    borderRadius: 12,
    padding: 10,
    overflow: "hidden",
    backgroundColor: COLORS.violet1000,
  },
  shadowBoxView: {
    shadowOffset: { width: 0, height: 4 },
    shadowOpacity: 0.9,
    shadowRadius: 1,
    borderWidth: 1,
    borderBottomWidth: 0,
    elevation: 9,
  },
  boxViewActive: {
    borderColor: COLORS.primaryA,
    shadowColor: COLORS.primaryA,
    backgroundColor: COLORS.violet900,
  },
  boxViewNormal: {
    borderColor: COLORS.mulledWine,
    shadowColor: COLORS.mulledWine,
    backgroundColor: COLORS.violet1000,
  },
  boxViewSuccess: {
    borderColor: COLORS.green500,
    shadowColor: COLORS.green500,
    backgroundColor: COLORS.darkGreenBlue,
  },
  boxViewError: {
    borderColor: COLORS.red600,
    shadowColor: COLORS.red600,
    backgroundColor: COLORS.solidPink,
  },
  boxViewDisabled: {
    borderColor: COLORS.violet900,
    shadowColor: COLORS.violet900,
    backgroundColor: COLORS.brightGrey,
  },
  overlay: {
    ...StyleSheet.absoluteFillObject,
    backgroundColor: COLORS.black1000,
    opacity: 0.4,
  },
});
