import * as React from "react";
import { View } from "react-native";
import { QText } from "apps/crypto-quiz/components";
import { animated, useSpring } from "@react-spring/native";
import { scale, StyleSheet } from "utils/StyleSheet";
import Svg, { Circle, SvgProps } from "react-native-svg";

const AnimatedCircle = animated(Circle) as any;

type Props = {
  width?: number;
  height?: number;
  percent?: number;
  percentActive?: number;
  renderTextValue?: string | React.ReactNode;
  strokeOverlayColor?: string;
  strokeWidth?: number;
  stroke?: string;
  isAnimate?: boolean;
  duration?: number;
  reset?: boolean;
} & SvgProps;

const ProcessCircle = ({
  percent = 50,
  percentActive = percent,
  width = 90,
  height = 90,
  renderTextValue,
  isAnimate = true,
  strokeWidth = 2,
  strokeOverlayColor = "#eeeeee",
  stroke = "#27957c",
  children,
  reset = false,
  duration = 500,
  ...restProps
}: Props) => {
  const [delayPercent, setDelayPercent] = React.useState(0);

  React.useEffect(() => {
    setTimeout(() => {
      setDelayPercent(percent);
    }, 0);
  }, [percent]);
  const percentValue = 100 - delayPercent;

  const fillStyle = useSpring({
    from: { strokeDashoffset: 100 },
    to: { strokeDashoffset: percentValue },
    config: { duration },
    reset,
    immediate: !isAnimate,
  });

  return (
    <View
      style={[styles.container, { width: scale(width), height: scale(height) }]}
    >
      <Svg viewBox="-3 -3 38 38" {...restProps}>
        <Circle
          cx="16"
          cy="16"
          r="15.9155"
          fill="none"
          stroke={strokeOverlayColor}
          strokeWidth={scale(strokeWidth)}
        />
        <AnimatedCircle
          style={{ ...fillStyle, ...styles.boxAnimate }}
          cx="16"
          cy="16"
          r="15.9155"
          fill="none"
          strokeWidth={scale(strokeWidth)}
          stroke={stroke}
          strokeDasharray="100 100"
          strokeLinecap="round"
        ></AnimatedCircle>
      </Svg>
      <View style={styles.contentView}>
        <View style={styles.valueText}>
          {renderTextValue || children || (
            <QText color={"#111111"}>{percentActive || percentValue}</QText>
          )}
        </View>
      </View>
    </View>
  );
};

export default React.memo(ProcessCircle);

const styles = StyleSheet.create({
  container: {
    transform: [{ rotate: "-90deg" }],
  },
  contentView: {
    ...StyleSheet.absoluteFillObject,
    justifyContent: "center",
    alignItems: "center",
  },
  valueText: {
    transform: [{ rotate: "90deg" }],
    marginBottom: 1,
  },
  boxAnimate: {
    padding: 10,
  },
});
