import COLORS from "utils/CommonColors";
import DeviceInfo from "utils/DeviceInfo";
import { StyleSheet } from "utils/StyleSheet";

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: COLORS.violet1000,
  },
  imgBackground: {
    width: "100%",
    height: DeviceInfo.CONTENT_WIDTH * 180 / 375,
  },
  imgView: {
    height: 160,
    width: "100%",
  },
  cardView: {
    paddingHorizontal: 16,
    marginTop: -30,
  },
});

export default styles;
