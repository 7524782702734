import COLORS from "utils/CommonColors";
import { StyleSheet } from "utils/StyleSheet";

const styles = StyleSheet.create({
  container: {
    backgroundColor: "#4D2C5B",
    height: 24,
    flexDirection: "row",
    alignItems: "center",
  },
  newContainer: {
    backgroundColor: "#9C3CE8",
    height: 24,
    flexDirection: "row",
    alignItems: "center",
  },
  speakerIcon: {
    width: 15,
    height: 13,
    marginLeft: 6,
  },
  tickerContainer: {
    flex: 1,
  },
  runningText: {
    size: 12,
    lineHeight: 13,
    whiteSpace: "nowrap",
    paddingRight: 50,
    color: "#fff"
  },
  rewardText: {
    color: COLORS.yellow500,
  },
});

export default styles;
