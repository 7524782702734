import "./index.css";
import React from "react";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import Store from "stores";
import DeviceInfo from "utils/DeviceInfo";
import Apps from "apps";
import axios from "axios";
import { REMITANO_DOMAIN } from "utils/RequestHelper";
import Locale from "locale";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { isProdEnv } from "utils/ConfigEnv";

if (isProdEnv && process.env.REACT_APP_SENTRY_API_KEY) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_API_KEY,
    integrations: [new BrowserTracing()],
  });
}

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

declare interface CustomWindow extends Window {
  onWebviewReady(data: any): void;
  RemitanoBrowser: {
    handleSDK(type: string, options?: any): Promise<any>;
  };
}
declare let window: CustomWindow & typeof globalThis;

axios.defaults.baseURL = process.env.REACT_APP_REMITANO_DOMAIN_DEV || REMITANO_DOMAIN;

window.onWebviewReady = (data) => {
  let deviceInfo: any = {};
  try {
    deviceInfo = JSON.parse(data);
  } catch (error) { }
  if (deviceInfo.language) {
    Locale.setLang(deviceInfo.language);
  }
  DeviceInfo.setInfo(deviceInfo);
};

root.render(
  <React.StrictMode>
    <Provider store={Store}>
      <Apps />
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
