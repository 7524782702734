import { COLORS } from "@remitano/remitano-ui-kit";
import { StyleSheet } from "react-native";
import DeviceInfo from "utils/DeviceInfo";
import { scale } from "utils/StyleSheet";
const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: COLORS.violet1000,
  },
  imageBg: {
    width: DeviceInfo.CONTENT_WIDTH,
    height: DeviceInfo.CONTENT_WIDTH * 1.92,
    resizeMode: "contain",
  },
  bottomBg: {
    position: "absolute",
    bottom: scale(0),
    width: "100%",
    height: scale(190),
  },
  playNow: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    fontSize: 16,
  },
  actionWrapper: {
    position: "absolute",
    bottom: scale(44),
    width: "100%",
    paddingHorizontal: scale(32),
  },
  actionMore: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: scale(16),
  },
  playNowTitle: {
    fontSize: scale(16),
  },
  btnMore: {
    flex: 1,
  },
  btnMoreText: {
    fontSize: 16,
  },
  actionMoreSpace: {
    width: 11,
  },
  turnContent: {
    justifyContent: "center",
    alignItems: "center",
    marginBottom: 4,
  },
  turnMessage: {
    paddingVertical: 4,
    paddingHorizontal: 20,
    backgroundColor: "#000000CC",
    textAlign: "center",
    borderRadius: 100,
    fontSize: 14,
  },
  turn: {
    color: COLORS.orange300
  }
});

export default styles;
