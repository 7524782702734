import React from "react";
import { ActivityIndicator, Image, View } from "react-native";
import { BoxView, QText } from "apps/crypto-quiz/components";

import Locale from "locale";
import styles from "./styles";

type Props = {
  onChoiseAnwser?: (answerText: string) => void;
  loadingQuestion?: boolean;
  answerChosen?: string | null;
  result?: string | null;
  isTimeout?: boolean;
  currentQuestion: number;
  dataQuestion: Questions;
};

const QuestionBoard = ({
  onChoiseAnwser,
  result,
  answerChosen,
  isTimeout,
  currentQuestion,
  dataQuestion,
  loadingQuestion,
}: Props) => {
  const getVariantByStatus = (answerText: string) => {
    if (isTimeout) {
      if (result === answerText) {
        return "success";
      } else if (answerChosen !== result && answerText === answerChosen) {
        return "error";
      } else {
        return "disabled";
      }
    } else {
      if (answerText === answerChosen) {
        return "active";
      } else {
        return "normal";
      }
    }
  };

  if (!dataQuestion?.length && !loadingQuestion) {
    return <></>;
  }

  return (
    <>
      {loadingQuestion ? (
        <View style={styles.wrapperEmpty}>
          <ActivityIndicator />
        </View>
      ) : (
        <>
          <View style={styles.wrapperImage}>
            {dataQuestion?.[currentQuestion]?.question_image_url && (
              <Image
                source={{
                  uri: dataQuestion?.[currentQuestion]?.question_image_url,
                }}
                style={styles.imageBg}
              />
            )}
          </View>
          <View style={styles.questionWrapper}>
            <QText size={14} style={styles.questionIndex}>
              {Locale.translate("sentence")} {currentQuestion + 1} /{" "}
              {dataQuestion?.length}
            </QText>
            <QText size={18} style={styles.questionContent}>
              {dataQuestion?.[currentQuestion]?.question}
            </QText>
          </View>
          <View style={styles.answerWrapperDone}>
            {dataQuestion?.[currentQuestion]?.answers?.map(
              (question: Answer, index: number) => (
                <BoxView
                  key={index}
                  style={styles.answerItem}
                  variant={getVariantByStatus(question.text)}
                  onPress={() => onChoiseAnwser?.(question.text)}
                >
                  <QText style={styles.answerText}>{question.text}</QText>
                </BoxView>
              )
            )}
          </View>
        </>
      )}
    </>
  );
};

export default React.memo(QuestionBoard);
