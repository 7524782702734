import React from "react";
import { ActivityIndicator, ScrollView, View } from "react-native";

import { SvgIcon, TabBarAnimated } from "base-components";
import { CardView, Empty, NavBar } from "apps/crypto-quiz/components";
import UserRank from "../UserRank";
import RankingChartFooter from "../RankingChartFooter";
import PersonalRanking from "../PersonalRanking";

import Locale from "locale";
import styles from "./style";

type Props = {
  rankingData: Ranking[];
  loadingRanking?: boolean;
  isWeeky: boolean;
  tabs: string[];
  weekNumber: number;
  totalNumberofWeeks: number;
  rangeTime: string;
  rankOfUser?: number;
  onShare: VoidFunction;
  onSelectTab: (tabValue: number) => void;
  handleNext: VoidFunction;
  handlePrev: VoidFunction;
};

const RankingWrapper: React.FC<Props> = ({
  rankingData,
  loadingRanking,
  tabs,
  isWeeky,
  weekNumber,
  totalNumberofWeeks,
  rangeTime,
  rankOfUser,
  onShare,
  onSelectTab,
  handleNext,
  handlePrev,
}) => {
  const getLeaderBoard = () => {
    if (loadingRanking) {
      return (
        <View style={styles.wrapperEmpty}>
          <ActivityIndicator />
        </View>
      );
    }
    if (rankingData.length)
      return (
        <View>
          <View style={styles.personalRanking}>
            {rankOfUser && <PersonalRanking rating={rankOfUser} />}
          </View>

          <CardView>
            <UserRank rankingChart={rankingData} />
          </CardView>
        </View>
      );
    return (
      !loadingRanking && (
        <Empty
          title={Locale.translate("unrank_title")}
          subTitle={Locale.translate("unrank_desc")}
          titleButton={Locale.translate("play_now")}
        />
      )
    );
  };

  return (
    <View style={styles.backgroundLayer}>
      <NavBar
        title={Locale.translate("ranking_chart")}
        iconRight={<SvgIcon name="ic_share_quiz" size={32} />}
        onPressIconRight={onShare}
      />
      <ScrollView>
        <View style={styles.container}>
          <View style={styles.switchBoard}>
            <TabBarAnimated
              activeTab={isWeeky ? 1 : 0}
              onSelect={onSelectTab}
              tabs={tabs}
            />
          </View>

          {getLeaderBoard()}
        </View>
      </ScrollView>

      {isWeeky && totalNumberofWeeks > 0 && (
        <RankingChartFooter
          weekNumber={weekNumber}
          totalNumberOfWeeks={totalNumberofWeeks}
          rangeTime={rangeTime}
          onNext={handleNext}
          onPrev={handlePrev}
        />
      )}
    </View>
  );
};

export default RankingWrapper;
