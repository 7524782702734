import React from "react";
import { SText, SvgIcon } from "base-components";
import { View } from "react-native";
import {
  getRewardIcon,
  getRewardName,
} from "apps/spin-wheel/screens/Home/components/utils";
import { ILuckyTradeSpinReward } from "services/LuckyDrawService";
import Locale from "locale";
import TimeHelper from "utils/TimeHelper";
import styles from "./styles";

interface Props {
  item: ILuckyTradeSpinReward;
  index: number;
  isNewVersionEnabled?: boolean;
}

const HistoryGiftItem: React.FC<Props> = ({
  item,
  index,
  isNewVersionEnabled,
}) => {
  if (!item) return null;
  const giftIcon = getRewardIcon(
    item?.lucky_trade_gift?.gift_type,
    item?.reward_params?.currency,
    isNewVersionEnabled
  );
  const receiveTime = TimeHelper.getTimeAgo(item.created_at_timestamp);
  const giftName = getRewardName(item.lucky_trade_gift, item?.reward_params);

  return (
    <View style={[styles.container, index === 0 && styles.firstItem]}>
      <View style={styles.coinLogo}>
        <SvgIcon name={String(giftIcon)} size={52} />
      </View>
      <View style={styles.giftContent}>
        <SText style={styles.giftDescription}>{receiveTime}</SText>
        <SText style={styles.giftTitle}>
          {Locale.translate("you_received2")}
          <SText bold size={15}>
            {" "}
            {giftName}
          </SText>
        </SText>
      </View>
    </View>
  );
};

export default React.memo(HistoryGiftItem);
