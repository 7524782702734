import { StyleSheet } from "utils/StyleSheet";
import DeviceInfo from "utils/DeviceInfo";
import COLORS from "utils/CommonColors";
import { StyleSheet as RNStyleSheet } from "react-native";

export const RNStyle = RNStyleSheet.create({
  bannerHW: {
    width: DeviceInfo.CONTENT_WIDTH,
    height: (DeviceInfo.CONTENT_WIDTH / 375) * 303,
  },
  newBannerHW: {
    width: DeviceInfo.CONTENT_WIDTH,
    height: (DeviceInfo.CONTENT_WIDTH / 375) * 603,
  },
});

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  content: { ...StyleSheet.absoluteFillObject, top: 303 },
  banner: {
    resizeMode: "contain",
  },
  title: {
    fontFamily: "Arial",
  },
  flex1: {
    flex: 1,
  },
  board: {
    marginHorizontal: 16,
  },
  boardContent: {
    marginHorizontal: 12,
  },
  boardContentWithoutTitle: {
    marginHorizontal: 12,
    marginTop: 12,
  },
  boardText: {
    color: COLORS.white,
  },
});
export default styles;
