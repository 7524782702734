import React, { forwardRef } from "react";
import { View, Image } from "react-native";
import Lottie from "lottie-react";
import animationConfettifrom from "assets/animation-confetti.json";
import Locale from "locale";
import { QuizImages } from "apps/crypto-quiz/utils";
import { ButtonQuiz, QText } from "apps/crypto-quiz/components";
import styles from "./styles";
import { NavBar } from "apps/crypto-quiz/components";
import { SvgIcon } from "base-components";
import ShareModal from "apps/crypto-quiz/components/ShareModal";

type Props = {
  onShare: VoidFunction;
  onGoReviewAnwser: VoidFunction;
  onGoRanking: VoidFunction;
  onContinuePlay: VoidFunction;
  score?: number | string;
  correctAnswers?: number | string;
  isWinning: boolean;
  isLogin: boolean;
  total: number;
};

const ResultScreen = forwardRef(({
  onShare,
  onGoReviewAnwser,
  onGoRanking,
  onContinuePlay,
  score = 0,
  correctAnswers = 0,
  isWinning = false,
  total,
  isLogin = false,
}: Props, modelRef: any) => {
  return (
    <View style={styles.container}>
      <NavBar
        isBack={false}
        title={Locale.translate("result")}
        iconRight={<SvgIcon name="ic_share_quiz" size={32} />}
        onPressIconRight={onShare}
      />
      <View style={[styles.congratInfo, isLogin ? styles.flex4 : styles.flex13]}>
        <View style={styles.lottie}>
          <Lottie animationData={animationConfettifrom} />
        </View>
        <View style={{ alignItems: "center" }}>
          <Image
            source={
              isWinning ? QuizImages.winningResult : QuizImages.finishingResult
            }
            style={styles.banner}
          />
          <QText style={styles.winningCongrat}>
            {isWinning
              ? Locale.translate("winning_congrat")
              : Locale.translate("finishing_congrat")}
          </QText>
          <QText style={styles.textCorrectAnswer}>
            {Locale.translate("correct_answers")}
          </QText>
          <QText>
            <QText style={styles.numberOfCorrectAnswer}>{correctAnswers}</QText>
            <QText style={styles.totalAnswers}> / {total}</QText>
          </QText>
          <QText style={styles.textDetails}>
            {Locale.translate("you_received")}
          </QText>
          <View style={styles.pointContent}>
            <QText style={styles.points}>
              {score}
            </QText>
            <QText>
              <SvgIcon name="stone" size={24} />
            </QText>
          </View>
        </View>
      </View>
      <View style={[styles.actionWrapper, isLogin ? styles.flex1 : styles.flex2]}>
        <ButtonQuiz
          height={42}
          onPress={onContinuePlay}
          block
          titleStyle={styles.btnPlayTitle}
          title={
            isLogin
              ? Locale.translate("continue_play")
              : Locale.translate("login")
          }
        />
        {isLogin && (
          <View style={styles.actionMore}>
            <ButtonQuiz
              block
              height={42}
              onPress={onGoReviewAnwser}
              style={styles.btnMore}
              titleStyle={styles.btnMoreText}
              variant="dark"
              title={Locale.translate("passed_game")}
            />
            <View style={styles.actionMoreSpace} />
            <ButtonQuiz
              height={42}
              onPress={onGoRanking}
              style={styles.btnMore}
              titleStyle={styles.btnMoreText}
              variant="dark"
              title={Locale.translate("ranking_chart")}
            />
          </View>
        )}
      </View>
      <ShareModal ref={modelRef} />
    </View>
  );
});

ResultScreen.displayName = "ResultScreen";

export default ResultScreen;
