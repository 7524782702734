/* eslint-disable no-undef */
"use strict";

module.exports = {
  get NavBar() {
    return require("./NavBar").default;
  },
  get SvgIcon() {
    return require("./SvgIcon").default;
  },
  get SText() {
    return require("./SText").default;
  },
  get AuraSpin() {
    return require("./AuraSpin/AuraSpin").default;
  },
  get TabBarAnimated() {
    return require("./TabBarAnimated").default;
  },
  get SButton() {
    return require("./SButton").default;
  },
};
