import { useGamePassedContext } from "apps/crypto-quiz/contexts/GamePassedContext";
import React, { useCallback } from "react";
import GamesPassed from "./GamesPassed";
const Container = ({ gamesPassed }: { gamesPassed: GamePassed }) => {
  const { onSetPopupContent, onShowPopupFullDescription } =
    useGamePassedContext();

  const handleShowPopup = useCallback((content: string) => {
    onSetPopupContent?.(content);
    onShowPopupFullDescription?.(true);
  }, []);

  const composeProps = {
    onShowPopup: handleShowPopup,
    gamesPassed,
  };
  return <GamesPassed {...composeProps} />;
};

export default Container;
