import { UserAction } from "../actions";
import { GenericAction } from "../types/actions";

const initialState = {
  id: "",
  username: "",
  email: "",
  name: "",
  lang: "",
  doc_country: "",
  lucky_trade_token_balance: 0,
  lucky_trade_spin_balance: 0,
};

const user = (state = initialState, action: GenericAction) => {
  switch (action.type) {
    case UserAction.UPDATE_USER_INFO:
      return {
        ...state,
        ...action.data,
      };
    case UserAction.AUTHEN_FAILED:
      return {
        ...initialState,
      };
    case UserAction.UPDATE_SPIN_TURN:
      return {
        ...state,
        lucky_trade_spin_balance: action.data,
      };
    case UserAction.UPDATE_TOKEN_BALANCE:
      return {
        ...state,
        lucky_trade_token_balance: action.data,
      };
    default:
      return state;
  }
};

export default user;
