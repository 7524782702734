import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import SW_SCREENS from "apps/spin-wheel/screens/info";
import LuckyDrawService from "services/LuckyDrawService";
import { CampaignAction } from "stores/actions";
import { GlobalState } from "stores/types/store";

interface Props {
  children: JSX.Element;
}

const RequireAuth = ({ children }: Props): JSX.Element => {
  const { campaign_name, kyc } = useSelector((state: GlobalState) => state.campaign);
  const { isRemiBrowser } = useSelector((state: GlobalState) => state.device);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  React.useEffect(() => {
    function listenCookieChange(callback: any, interval = 1000) {
      let lastCookie = document.cookie;
      const cookieInterval = setInterval(async () => {
        const cookie = document.cookie;
        if (cookie !== lastCookie) {
          try {
            const isClear = await callback();
            if (isClear) clearInterval(cookieInterval);
          } finally {
            lastCookie = cookie;
          }
        }
      }, interval);
    }

    const authCheck = async () => {
      if (!campaign_name || !kyc) {
        const res = await LuckyDrawService.getCampaign();
        if (res) {
          dispatch({
            type: CampaignAction.UPDATE_CAMPAIGN,
            data: res,
          });
        } else {
          navigate(SW_SCREENS.HomeScreen.path);
          if (!isRemiBrowser) {
            listenCookieChange(async () => {
              const res = await LuckyDrawService.getCampaign();
              if (res) {
                dispatch({
                  type: CampaignAction.UPDATE_CAMPAIGN,
                  data: res,
                });
                return true;
              }
              return false;
            }, 1000);
          }
        }
      }
    };

    authCheck();
  }, [navigate]);

  return children;
};

export default RequireAuth;
