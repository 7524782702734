import React from "react";
import { useGamePlayContext } from "apps/crypto-quiz/contexts/GamePlayContext";
import HeaderBoard from "./HeaderBoard";
import { useQuizGlobalContext } from "apps/crypto-quiz/contexts/QuizGlobalContext";

const Container = () => {
  const { tick, isStart, isShowHint } = useGamePlayContext();
  const { turn } = useQuizGlobalContext();
  
  const composeProps = {
    tick,
    isStart,
    turn,
    isShowHint
  };
  return <HeaderBoard {...composeProps} />;
};

export default Container;
