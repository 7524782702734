import React from "react";
import { View } from "react-native";
import { SvgIcon } from "base-components";
import Locale from "locale";
import { QText } from "apps/crypto-quiz/components";

import styles from "./style";

interface Props {
  rankInfo: {
    rating: number;
    name: string;
    score: number;
    isCurrentUser: boolean;
  };
}
const UserRankItem: React.FC<Props> = ({ rankInfo }) => {
  const getMedal = (rating: number) => {
    return <View style={styles.ratingContent}>
      {rating < 4 ? (
        <SvgIcon name={`medal${rankInfo.rating}`} size={24} />
      ) : (
        <QText numberOfLines={1} style={styles.rating}>{rankInfo.rating}</QText>
      )
      }
    </View>;
  };

  return (
    <View>
      <View
        style={[styles.container, rankInfo.isCurrentUser && styles.currentUser]}
      >
        <View style={styles.content}>
          {getMedal(rankInfo.rating)}
          <QText
            style={[
              styles.text,
              styles.name,
              rankInfo.isCurrentUser ? styles.itMe : {},
              rankInfo.isCurrentUser ? styles.currentUser : {},
            ]}
          >
            <QText style={[styles.userName, rankInfo.isCurrentUser ? styles.colorBlack : {}]}>{rankInfo.name}</QText>
            <QText style={rankInfo.isCurrentUser ? styles.colorBlack : {}}>{rankInfo.isCurrentUser && ` (${Locale.translate("you")})`}</QText>
          </QText>
        </View>

        <View style={styles.content}>
          <QText
            style={[
              styles.text,
              rankInfo.isCurrentUser ? styles.currentUser : {},
            ]}
          >
            {rankInfo.score}
          </QText>
          <QText style={styles.stone}>
            <SvgIcon name="stone" size={15} />
          </QText>
        </View>
      </View>

      <View style={styles.devider}></View>
    </View>
  );
};

export default UserRankItem;
