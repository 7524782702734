import React from "react";
import { SText, SvgIcon } from "base-components";
import { Pressable, ViewStyle } from "react-native";
import styles from "./styles";

interface Props {
  title: string;
  icon: string;
  onPress?: any;
  size?: number;
  textStyle?: ViewStyle;
}

const ButtonWithIcon: React.FC<Props> = ({
  title,
  icon,
  onPress,
  size,
  textStyle = {},
}) => {
  return (
    <Pressable onPress={onPress} style={styles.itemCenter}>
      <SvgIcon name={icon} size={size || 52} />
      <SText bold style={[styles.buttonTitle, textStyle]}>
        {title}
      </SText>
    </Pressable>
  );
};

export default ButtonWithIcon;
