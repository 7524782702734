import React, { useContext, useMemo } from "react";
import useRankingData from "apps/crypto-quiz/hooks/useRankingData";

type RankingContextEntity = {
  rankingData: Ranking[];
  loadingRanking?: boolean;
  rankingType?: RankingType;
  weekNumber: number;
  rangeTime: string;
  rankOfUser?: number;
  totalNumberofWeeks: number;
  onSetRankingType?: (type: RankingType) => void;
  onSetCurrentWeekly?: (weekNumber: number) => void;
};

export const RankingContext = React.createContext<RankingContextEntity>({
  rankingData: [],
  weekNumber: -1,
  rangeTime: "",
  totalNumberofWeeks: 1,
});

type Props = {
  children: React.ReactNode;
};

export const RankingContextProvider: React.FC<Props> = ({ children }) => {
  const {
    rankingData,
    loadingRanking,
    rankingType,
    weekNumber,
    rangeTime,
    totalNumberofWeeks,
    rankOfUser,
    onSetRankingType,
    onSetCurrentWeekly,
  } = useRankingData();

  const contextValue = useMemo(
    () => ({
      rankingData,
      loadingRanking,
      rankingType,
      weekNumber,
      rangeTime,
      totalNumberofWeeks,
      rankOfUser,
      onSetRankingType,
      onSetCurrentWeekly,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      rankingData,
      loadingRanking,
      rankingType,
      weekNumber,
      rangeTime,
      totalNumberofWeeks,
      rankOfUser,
    ]
  );

  return (
    <RankingContext.Provider value={contextValue}>
      {children}
    </RankingContext.Provider>
  );
};

export const useRankingContext = () => useContext(RankingContext);
