import React, { useMemo } from "react";
import { Text, TextProps, TextStyle } from "react-native";
import { scale } from "utils/StyleSheet";
import styles from "./styles";
import { useSelector } from "react-redux";
import { GlobalState } from "stores/types/store";

interface Props extends TextProps {
  text?: string;
  children?: any;
  bold?: Boolean;
  style?: TextStyle | TextStyle[];
  color?: string;
  size?: number;
}

const SText: React.FC<Props> = (props) => {
  const { bold, text, children, style, color, size, ...restProps } = props;
  const { isNewVersionEnabled } = useSelector(
    (state: GlobalState) => state.campaign
  );

  const bonusStyle = useMemo(() => {
    const style: any = {};
    if (bold) style.fontWeight = "bold";
    if (color) style.color = color;
    if (size) style.fontSize = scale(size);
    if (isNewVersionEnabled) style.fontFamily = "IBM Plex Sans";
    return style;
  }, [bold, color, isNewVersionEnabled, size]);

  return (
    <Text style={[styles.text, style, bonusStyle]} {...restProps}>
      {text || children}
    </Text>
  );
};

export default SText;
