const QUIZ_SCREENS = {
  OverviewScreen: {
    title: "Overview",
    path: "/overview",
  },
  GamePassedScreen: {
    title: "How to play",
    path: "/game-passed",
  },
  HowToPlayScreen: {
    title: "How to play",
    path: "/howtoplay",
  },
  ShareScreen: {
    title: "Share",
    path: "/share",
  },
  LandingScreen: {
    title: "Coinlympia",
    path: "/landing",
  },
  RankingChartScreen: {
    title: "Ranking Chart",
    path: "/ranking-chart",
  },
  GamePlayScreen: {
    title: "How to play",
    path: "/game-play",
  },
  CoinlympiaSplashScreen: {
    title: "Coinlympia",
    path: "/",
  },
  ResultScreen: {
    title: "Result",
    path: "/result",
  },
};

export default QUIZ_SCREENS;
