import { SvgIcon } from "base-components";
import React from "react";
import { Pressable, View, ViewStyle } from "react-native";
import { animated, useSpring } from "@react-spring/native";
import COLORS from "utils/CommonColors";
import DeviceInfo from "utils/DeviceInfo";
import { scale, StyleSheet } from "utils/StyleSheet";

type ModalProps = {
  children?: React.ReactNode;
  overlayTriggerClose?: boolean;
  isOpen?: boolean;
  width?: number;
  transparent?: boolean;
  bgContent?: string;
  styleContent?: ViewStyle,
  onClose?: () => void;
};

const AnimatedView = animated(View) as any;

const Modal: React.FC<ModalProps> = ({
  children,
  isOpen,
  width,
  overlayTriggerClose,
  transparent,
  bgContent,
  styleContent = {},
  onClose,
  ...restProps
}) => {
  const styleAnimContainer = useSpring({
    display: isOpen ? "block" : "none",
  });
  const styleAnimContent = useSpring({
    opacity: isOpen ? 1 : 0,
    zIndex: 99,
    justifyContent: "center",
    alignItems: "center",
  });
  const handleOverlayClose = () => {
    if (overlayTriggerClose) {
      onClose?.();
    }
  };

  return (
    <AnimatedView
      style={{
        ...styles.container,
        ...styleAnimContainer,
        width: DeviceInfo.CONTENT_WIDTH,
      }}
      {...restProps}
    >
      <AnimatedView
        style={{ ...styleAnimContent, ...StyleSheet.absoluteFillObject }}
      >
        <Pressable
          style={{
            width,
            backgroundColor: bgContent || COLORS.primaryA,
            maxHeight: DeviceInfo.HEIGHT - scale(120),
            zIndex: 99,
            ...styles.content,
            ...styleContent
          }}
        >
          {children}
          <Pressable style={styles.closeIcon} onPress={onClose}>
            <SvgIcon size={28} name="ic_close" />
          </Pressable>
        </Pressable>
        {!transparent && (
          <Pressable
            onPress={handleOverlayClose}
            style={styles.overlay}
          ></Pressable>
        )}
      </AnimatedView>
    </AnimatedView>
  );
};

Modal.defaultProps = {
  width: DeviceInfo.CONTENT_WIDTH - scale(200),
  transparent: false,
};

export default React.memo(Modal);

const styles = StyleSheet.create({
  container: {
    ...StyleSheet.absoluteFillObject,
  },
  content: {
    borderRadius: 8,
    position: "relative",
    padding: 15,
    marginVertical: 50,
    justifyContent: "center",
    alignItems: "center",
  },
  overlay: {
    ...StyleSheet.absoluteFillObject,
    backgroundColor: "rgba(0,0,0,0.5)",
    zIndex: 3,
  },
  closeIcon: {
    bottom: -45,
    position: "absolute",
    color: COLORS.white,
    justifyContent: "center",
    alignItems: "center",
    fontWeight: "bold",
  },
});
