import React from "react";
import { Image, View } from "react-native";
import { QuizImages } from "apps/crypto-quiz/utils";
import { ButtonQuiz, QText, NavBar } from "apps/crypto-quiz/components";
import Locale from "locale";
import styles from "./styles";

type Props = {
  onPreviewAnwser: VoidFunction;
  onShare: VoidFunction;
  userId: string | undefined
};

const ShareScreen: React.FC<Props> = ({ onPreviewAnwser, onShare, userId }) => {
  return (
    <View style={styles.container}>
      <NavBar title={Locale.translate("result")} />
      <View style={styles.viewImg}>
        <Image source={QuizImages.imgShare} style={styles.img} />
        <View style={styles.viewDescription}>
          <QText style={styles.description}>
            {Locale.translate("share_description")}
          </QText>
        </View>
      </View>

      <View style={styles.viewBtn}>
        <ButtonQuiz
          onPress={onShare}
          width={280}
          height={44}
          style={styles.btn}
          titleStyle={styles.btnShare}
          title={Locale.translate("share_now")}
        />
        {userId ?
          <ButtonQuiz
            onPress={onPreviewAnwser}
            width={280}
            height={44}
            style={styles.btn}
            titleStyle={styles.btnReview}
            title={Locale.translate("passed_game")}
            variant="text"
          />
          : <></>}
      </View>
    </View>
  );
};


ShareScreen.displayName = "ShareScreen";

export default React.memo(ShareScreen);
