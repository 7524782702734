import React from "react";
import { SvgIcon } from "base-components";
import { Pressable, View } from "react-native";
import { StyleSheet } from "utils/StyleSheet";
import { useCallback } from "react";
import { useSelector } from "react-redux";
import { GlobalState } from "stores/types/store";
import QText from "../QText";
import { useNavigate } from "react-router-dom";
import QUIZ_SCREENS from "apps/crypto-quiz/screens/info";

const DEFAULT_LEFT_REDIRECT = QUIZ_SCREENS.LandingScreen.path;
const DEFAULT_RIGHT_REDIRECT = QUIZ_SCREENS.HowToPlayScreen.path;

type Props = {
  title?: string;
  hiddenIconLeft?: boolean;
  hiddenIconRight?: boolean;
  iconLeft?: React.ReactNode;
  onPressIconLeft?: () => void;
  onPressIconRight?: () => void;
  onPressBack?: () => void;
  iconRight?: React.ReactNode;
  isBack?: boolean;
};

const NavBar: React.FC<Props> = ({
  title,
  hiddenIconLeft = false,
  hiddenIconRight = false,
  iconLeft,
  onPressIconLeft,
  onPressIconRight,
  onPressBack,
  iconRight,
  isBack = true,
}) => {
  const navigate = useNavigate();
  const handleGoback = useCallback(() => {
    if (onPressBack) {
      return onPressBack?.();
    }
    navigate(-1);
  }, []);

  const { isRemiBrowser, statusBarHeight } = useSelector(
    (state: GlobalState) => state.device
  );
  const handlePressIconRight = () => {
    if (onPressIconRight) {
      return onPressIconRight?.();
    }
    navigate(DEFAULT_RIGHT_REDIRECT);
  };

  const handlePressIconLeft = () => {
    if (onPressIconLeft) {
      return onPressIconLeft?.();
    }

    navigate(DEFAULT_LEFT_REDIRECT);
  };

  return (
    <View style={styles.container}>
      <View style={{ height: isRemiBrowser ? statusBarHeight : 0 }} />
      <View style={styles.content}>
        <View style={styles.contentLeft}>
          {isBack && (
            <Pressable onPress={handleGoback} style={styles.icBack}>
              <SvgIcon name="ic_back" size={20} />
            </Pressable>
          )}
          {!hiddenIconLeft && (
            <Pressable style={styles.iconLeft} onPress={handlePressIconLeft}>
              {iconLeft || <SvgIcon name="ic_quiz_home" size={32} />}
            </Pressable>
          )}
        </View>
        <View style={styles.titleContainer}>
          <QText style={styles.title}>{title}</QText>
        </View>
        {!hiddenIconRight && (
          <Pressable onPress={handlePressIconRight}>
            {iconRight || <SvgIcon name="ic_handbook" size={32} />}
          </Pressable>
        )}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: "#27133A",
    padding: 8,
    paddingHorizontal: 12,
  },
  content: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  contentLeft: {
    flexDirection: "row",
    alignItems: "center",
  },
  icBack: {
    marginLeft: 2,
    paddingRight: 0,
  },
  iconLeft: {
    marginLeft: 12,
  },
  titleContainer: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    pointerEvents: "none",
    ...StyleSheet.absoluteFillObject,
  },
  title: {
    fontWeight: "700",
    fontSize: 16,
    color: "white",
    textTransform: "capitalize",
  },
});

export default NavBar;
