import { useEffect, useState } from "react";
import GamePassedService from "../services/GamePassedService";

const useGamePassed = ({ firstLoad = true }: { firstLoad?: boolean }) => {
  const [passedsGame, setPassedsGame] = useState<GamePassed[] | undefined>([]);
  const [loading, setLoading] = useState(firstLoad);
  const fetch = async () => {
    setLoading(true);
    const gamesPassed: GamePassed[] | undefined =
      await GamePassedService.getGamePassed();
    setLoading(false);
    setPassedsGame(gamesPassed);
  };

  useEffect(() => {
    if (firstLoad) {
      fetch();
    }
  }, []);

  return {
    fetch,
    loading,
    passedsGame,
  };
};

export default useGamePassed;
