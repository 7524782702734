import React, { useEffect } from "react";
import QUIZ_SCREENS from "apps/crypto-quiz/screens/info";
import { QuizImages } from "apps/crypto-quiz/utils";
import { Image, View } from "react-native";
import { useNavigate } from "react-router-dom";
import styles from "./styles";
import { ProgressBar } from "apps/crypto-quiz/components/Progess";
import { NavBar } from "apps/crypto-quiz/components";
import CacheImageService from "services/CacheImageService";
import Locale from "locale";
import { initRemiBrowser } from "apps/crypto-quiz/utils/ConfigHelper";

const PRELOAD_IMGS: any = [];
const PERCENT = 100 / 2;

const SplashScreen = () => {
  const navigate = useNavigate();
  const [progress, setProgress] = React.useState(0);

  const promiseApi = new Promise((resolve) => setTimeout(resolve, 1000));
  const lang = Locale.currLocale;

  React.useEffect(() => {
    initRemiBrowser();
  }, []);

  const loadData = async () => {
    let newProgress = 0;
    await Promise.all([
      CacheImageService.preloadListImg(PRELOAD_IMGS).then(() => {
        newProgress = newProgress + PERCENT;
        setProgress(newProgress);
      }),
      promiseApi.then(() => {
        newProgress = newProgress + PERCENT;
        setProgress(newProgress);
      }),
    ]);
    setTimeout(() => navigate(QUIZ_SCREENS.LandingScreen.path), 500);
  };

  useEffect(() => {
    loadData();
  }, []);

  return (
    <View style={styles.container}>
      <NavBar title="Coinlympia" />
      <Image
        source={{
          uri: lang === "vi" ? QuizImages.bgLandingVi : QuizImages.bgLandingEn,
        }}
        style={styles.imageBg}
      ></Image>
      <Image source={QuizImages.bottomLanding} style={styles.bottomBg} />
      <View style={styles.progressBar}>
        <ProgressBar progress={progress} borderRadius={10} width="80%" />
      </View>
    </View>
  );
};

export default SplashScreen;
