import { useEffect, useState } from "react";
import { GAME_PLAY_CACHING } from "../contexts/GamePlayContext";
import { useQuizAuthContext } from "../contexts/QuizAuthContext";
import GamePlayService, { RequestResult } from "../services/GamePlayService";

const useSyncDataGamePlay = () => {
  const [isSyncSuccess, setIsSyncSuccess] = useState(false);

  const { userId } = useQuizAuthContext();

  async function syncDataBeforeLogin(params: RequestResult) {
    const result: any = await GamePlayService.submitResult(params);
    if (result) {
      localStorage.removeItem(GAME_PLAY_CACHING);
      setIsSyncSuccess(true);
    }
  }

  useEffect(() => {
    if (userId) {
      const results = localStorage.getItem(GAME_PLAY_CACHING);

      if (results) {
        try {
          const params: RequestResult = {
            ...JSON.parse(results),
            is_first_time_played: true,
            user_id: userId,
          };
          syncDataBeforeLogin(params);
        } catch (err: any) {
          throw new Error(err);
        }
      } else {
        setIsSyncSuccess(false);
      }
    }
  }, [userId]);

  return {
    isSyncSuccess,
  };
};

export default useSyncDataGamePlay;
