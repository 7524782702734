import { StyleSheet as RNStyleSheet, Dimensions } from "react-native";
import DeviceInfo from "./DeviceInfo";
const { width } = Dimensions.get("window");
const CONTENT_WIDTH = Math.min(width, DeviceInfo.getMaxWidth());
const CONTENT_SCALE = CONTENT_WIDTH / 375;

const objectMap = (object: any, mapFn: any) => {
  return Object.keys(object).reduce((result: any, key) => {
    result[key] = mapFn(object[key]);
    return result;
  }, {});
};

const objectMap2 = (object: any, overload: any) => {
  return Object.keys(object).reduce((result: any, key) => {
    if (typeof object[key] === "number") {
      if (key.includes("flex") || key.includes("opacity")) {
        result[key] = object[key];
      } else {
        result[key] = scale(object[key]);
      }
    } else {
      result[key] = object[key];
    }

    return { ...overload, ...result };
  }, {});
};

export const scale = (number?: number) => {
  if (!number) return 0;
  const value = number * CONTENT_SCALE;
  return Number(value.toFixed(1));
};

export const StyleSheet: typeof RNStyleSheet = {
  ...RNStyleSheet,
  create: (styleSheet: any, overload = {}) =>
    RNStyleSheet.create(
      objectMap(styleSheet, (value: any) => {
        const style = objectMap2(value, overload);
        return style;
      })
    ),
};
