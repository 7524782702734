import React, { useEffect, useState } from "react";
import { Image, Pressable, View } from "react-native";
import { SvgIcon } from "base-components";
import { BoxView, QText } from "apps/crypto-quiz/components";

import styles from "./styles";
import Locale from "locale";
import { scale } from "utils/StyleSheet";

const OVERLOAD_NUMBER_OF_LINE = 1000;

export const Explain = ({
  content,
  numberOfLines = 2,
  onReadMore,
}: {
  content?: string;
  numberOfLines?: number;
  onReadMore?: (content?: string) => void;
}) => {

  const [height, setHeight] = useState(0);
  const [isReadMore, setIsReadMore] = useState(false);
  const [isDefaultNumberOfLine, setIsDefaultNumberOfLine] = useState(true);

  const lineHeight = scale(18);
  const padding = scale(10);
  const maxHeight = Math.round(numberOfLines * lineHeight + padding * 2);

  useEffect(() => {
    if (height > maxHeight) {
      setIsReadMore(true);
    }
  }, [height, maxHeight]);

  return (
    <View
      style={[styles.gameExplain, { padding }]}
      onLayout={(event) => {
        const { height } = event.nativeEvent.layout;
        setHeight(height);
        setIsDefaultNumberOfLine(false);
      }}
    >
      <QText
        numberOfLines={isDefaultNumberOfLine ? OVERLOAD_NUMBER_OF_LINE : numberOfLines}
        style={[{ lineHeight }, styles.explainText]}
      >
        {content}
      </QText>
      {isReadMore && (
        <View style={styles.readMore}>
          <Pressable
            onPress={() => onReadMore?.(content)}
            style={styles.readMoreText}
          >
            <QText style={styles.italicStyle}>... {Locale.translate("read_more")}</QText>
            <SvgIcon name="ic_arrow-right" size={12} />
          </Pressable>
        </View>
      )}
    </View>
  );
};

type Props = {
  onReadMore?: (explain?: string) => void;
  index: number;
  question?: {
    id: string;
    question: string;
    questionHint: string;
    questionImage?: string;
    answer?: string;
  };
};

const GamePassedItem = ({ onReadMore, index, question }: Props) => {
  return (
    <View style={styles.container}>
      {question?.questionImage && (
        <Image
          style={styles.gameImage}
          source={{ uri: question?.questionImage }}
        />
      )}
      <QText style={styles.titleSmall}>
        {Locale.translate("question")} {index}
      </QText>
      <QText style={[styles.spacingMedium, styles.lineHeightText]} bold>
        {question?.question}
      </QText>
      <QText style={[styles.spacingMedium, styles.titleSmall]}>
        {Locale.translate("answer")}
      </QText>
      <BoxView style={styles.spacingMedium} variant="success">
        <QText style={[styles.lineHeightText, styles.answerText]}>
          {question?.answer}
        </QText>
      </BoxView>
      <Explain
        content={question?.questionHint}
        onReadMore={onReadMore}
        numberOfLines={4}
      />
    </View>
  );
};

export default React.memo(GamePassedItem);
