import React, { useCallback, useEffect } from "react";
import SocialUtil from "apps/crypto-quiz/utils/Social";
import { useNavigate } from "react-router-dom";
import QUIZ_SCREENS from "../info";
import ShareScreen from "./ShareScreen";
import { useSelector } from "react-redux";
import { GlobalState } from "stores/types/store";
import RemitanoBrowser from "utils/RemitanoBrowser";
import { ModalRef } from "apps/crypto-quiz/components/ShareModal";
import useIncreaseTurn from "apps/crypto-quiz/hooks/useIncreaseTurn";
import { Share } from "react-native";
import AlertHelper from "apps/crypto-quiz/utils/AlertHelper";
import Locale from "locale";
import { useQuizAuthContext } from "apps/crypto-quiz/contexts/QuizAuthContext";
import { useQuizGlobalContext } from "apps/crypto-quiz/contexts/QuizGlobalContext";

export const SHARE_KEY_STORE = "plus-turn";
const Container = () => {
  const navigate = useNavigate();
  const { fetch: increaseTurn } = useIncreaseTurn({});
  const { plusTurn } = useQuizGlobalContext();

  const modalRef = React.useRef<ModalRef>(null);
  const { isRemiBrowser } = useSelector((state: GlobalState) => state.device);

  const { userId } = useQuizAuthContext();

  const handlePreviewAnwser = useCallback(() => {
    navigate(QUIZ_SCREENS.GamePassedScreen.path);
  }, []);

  const handleCallbackIncreaseTurn = (isSuccess: boolean) => {
    if (isSuccess) {
      plusTurn?.();
      localStorage.setItem(SHARE_KEY_STORE, "true");
      navigate(QUIZ_SCREENS.LandingScreen.path);
    } else {
      if (userId) {
        AlertHelper.showModalAlert({
          message: Locale.translate("finish_share"),
        });
      } else {
        navigate(QUIZ_SCREENS.LandingScreen.path);
      }
    }
  };

  const handleShare = async () => {
    let shared = false;
    if (isRemiBrowser) {
      const res: any = await RemitanoBrowser.shareScreen();
      if (res.action === Share.sharedAction) {
        if (res.activityType) {
          if (res.activityType.includes("PostToFacebook")) {
            shared = true;
          }
        } else {
          shared = true;
        }
      }
      if (shared) {
        increaseTurn(handleCallbackIncreaseTurn);
      }
      return;
    } else {
      SocialUtil.shareFacebook()
        .then((res) => {
          if (res) {
            modalRef?.current?.hideModal();
            increaseTurn(handleCallbackIncreaseTurn);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  useEffect(() => {
    SocialUtil.initFacebookSdk();
  }, []);

  const composeProps = {
    onPreviewAnwser: handlePreviewAnwser,
    onShare: handleShare,
    userId,
  };

  return <ShareScreen {...composeProps} />;
};

export default Container;
