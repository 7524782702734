import React from "react";
import { View } from "react-native";
import UserRankItem from "../UserRankItem";

interface Props {
  rankingChart: {
    rating: number;
    name: string;
    score: number;
    isCurrentUser: boolean;
  }[];
}

const UserRank: React.FC<Props> = ({ rankingChart }) => {
  return (
    <View>
      {rankingChart?.map((item) => (
        <UserRankItem key={item.rating} rankInfo={item} />
      ))}
    </View>
  );
};

export default UserRank;
