import { GAME_CRYPTO_QUIZ, GAME_MERRY_CHRISMAS, GAME_WORLD_CUP } from "constants/commons";

class DeviceInfo {
  getMaxWidth = () => {
    switch(process.env.REACT_APP_GAME){
      case GAME_CRYPTO_QUIZ:
        return 428;
      case GAME_WORLD_CUP:
      case GAME_MERRY_CHRISMAS:   
        return 970;    
      default: 
        return 600;
    }
  };

  WIDTH = window?.innerWidth || 712;
  HEIGHT = window?.innerHeight || 1080;
  CONTENT_WIDTH = Math.min(this.WIDTH, this.getMaxWidth());
  CONTENT_SCALE = this.CONTENT_WIDTH / 375;
  UNIQUE_ID = "";
  PLATFORM = "";
  appDeviceInfo = undefined;

  setInfo = (data: any) => {
    if (data.device_id) this.UNIQUE_ID = data.device_id;
    if (data.device_platform) this.PLATFORM = data.device_platform;
    this.appDeviceInfo = data;
  };
}

export default new DeviceInfo();
