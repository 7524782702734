import React from "react";
import { View, Modal, Pressable, TouchableOpacity, ScrollView, TouchableWithoutFeedback, Image } from "react-native";
import Locale from "locale";
import { SText, SvgIcon } from "base-components";
import { Board, ButtonWithBg } from "apps/spin-wheel/components";
import styles from "./styles";
import CacheImageService from "services/CacheImageService";

export interface ModalConfirmRef {
  show(content: ModalContent): void;
}

export interface ModalContent {
  title?: string;
  message?: string;
  icon?: string;
  confirmText?: string;
  cancelText?: string;
}

interface Props extends ModalContent {
  onConfirm?(): void;
  onCancel?(): void;
}

const ConfirmModal = React.forwardRef<ModalConfirmRef, Props>((props, ref) => {
  const [isShowModal, setShowModal] = React.useState(false);
  const [modalContent, setModalContent] = React.useState<ModalContent>({
    title: props?.title,
    message: props?.message,
    icon: props?.icon,
    confirmText: props?.confirmText,
    cancelText: props?.cancelText,
  });

  React.useImperativeHandle(ref, () => ({
    show: (content: ModalContent) => {
      setModalContent(content);
      setShowModal(true);
    },
  }));

  const onClose = () => setShowModal(false);

  const onCancel = () => {
    onClose();
    if (props.onCancel) props.onCancel();
  };

  const onConfirm = () => {
    onClose();
    if (props.onConfirm) props.onConfirm();
  };

  return (
    <Modal visible={isShowModal} transparent>
      <TouchableOpacity style={styles.container} onPress={onClose}>
        <ScrollView>
          <View style={styles.rewardContent}>
            <Board title={modalContent?.title || Locale.translate("warning")} style={styles.boardContainer}>
              <TouchableWithoutFeedback>
                <View style={styles.contentContainer}>
                  {modalContent?.icon
                    ? <SvgIcon name={modalContent?.icon} size={100} />
                    : <Image
                      source={{ uri: CacheImageService.getCachedImg("pepe-helmet") }}
                      style={styles.headerImg}
                    />
                  }
                  <View style={styles.rewardBox}>
                    <SText style={styles.rewardDescription}>{modalContent?.message}</SText>
                  </View>
                  <ButtonWithBg
                    onPress={onConfirm}
                    title={modalContent?.confirmText || Locale.translate("confirm")}
                    width={251}
                    height={46}
                    style={styles.confirmBtn}
                    titleStyle={styles.confirmTitle}
                  />
                  <TouchableOpacity style={styles.cancelBtn} onPress={onCancel}>
                    <SText style={styles.cancelTitle}>{modalContent?.cancelText || Locale.translate("cancel")}</SText>
                  </TouchableOpacity>
                </View>
              </TouchableWithoutFeedback>
            </Board>
            <Pressable style={styles.close} onPress={onClose}>
              <SvgIcon name="ic_close" size={32} />
            </Pressable>
          </View>
        </ScrollView>
      </TouchableOpacity>
    </Modal>
  );
});

ConfirmModal.displayName = "ConfirmModal";

export default ConfirmModal;
