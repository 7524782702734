import { useContextState } from "@remitano/remitano-ui-kit";
import Locale from "locale";
import { useCallback, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import {
  DELAY_NEXT_QUESTION,
  DELAY_SHOW_HINT,
  GAME_PLAY_CACHING,
  LIMIT_TIMER,
} from "../contexts/GamePlayContext";
import { useQuizAuthContext } from "../contexts/QuizAuthContext";
import { useQuizGlobalContext } from "../contexts/QuizGlobalContext";
import QUIZ_SCREENS from "../screens/info";
import GamePlayService from "../services/GamePlayService";
import useCountDown from "./commons/useCountDown";
import useQuestions from "./useQuestions";

const useGamePlayData = () => {
  const navigate = useNavigate();
  const { minusTurn } = useQuizGlobalContext();
  const mergeDataRef: any = useRef(null);
  const timoutQuestionRef: any = useRef(null);
  const timeoutHint: any = useRef(null);

  const { userId } = useQuizAuthContext();

  const { questions, loading: loadingQuestion } = useQuestions(Locale.currLocale);

  const { values, updateKey } = useContextState({
    dataQuestion: [],
    result: null,
    answerChosen: null,
    currentQuestion: 0,
    isStart: false,
    isHint: false
  });

  useEffect(() => {
    return () => resetTimeout();
  }, []);

  useEffect(() => {
    updateKey("dataQuestion", questions);
  }, [questions]);

  const { tick, resetCountDown, setEndTime } = useCountDown({
    total: LIMIT_TIMER,
    isStart: values.isStart,
  });

  const resetState = () => {
    updateKey("answerChosen", null);
    updateKey("result", null);
  };

  const isEndQuestion =
    values.currentQuestion === values.dataQuestion?.length - 1;
    
  useEffect(() => {
    if (questions?.length) {
      updateKey("isStart", true);
      updateKey("dataQuestion", questions);
    }
  }, [questions]);

  const cachingData = (data: {
    results: {
      id: number;
      answer: string;
      time_remaining: number;
    }[];
  }) => {
    localStorage.setItem(GAME_PLAY_CACHING, JSON.stringify(data));
  };

  const makeDataSaver = ({
    currentQuestion,
    dataQuestion,
    tick,
    answerValue,
  }: any) => {
    if (!dataQuestion?.length) {
      return;
    }
    const answerSaver = {
      answer: answerValue || null,
      id: dataQuestion?.[currentQuestion]?.id,
      time_remaining: tick || 0,
    };

    mergeDataRef.current = {
      ...mergeDataRef.current,
      [currentQuestion]: { ...answerSaver },
    };
  };

  useEffect(() => {
    makeDataSaver({
      currentQuestion: values.currentQuestion,
      dataQuestion: values.dataQuestion,
    });
  }, [values.currentQuestion, values.dataQuestion]);

  useEffect(() => {
    if (!values.dataQuestion?.length || !isEndQuestion) {
      return;
    }
    const answerSaver = {
      answer: null,
      id: values.dataQuestion?.[values.currentQuestion]?.id,
      time_remaining: 0,
    };

    mergeDataRef.current = {
      ...mergeDataRef.current,
      [values.currentQuestion]: { ...answerSaver },
    };
  }, [values.currentQuestion, values.dataQuestion]);

  const endQuestionAnswer = (currentQuestion: number) => {
    const result = (
      values.dataQuestion?.[values.currentQuestion] as Question
    ).answers?.find((answer: Answer) => answer.isCorrect)?.text;
    updateKey("result", result);
    const nextQuestionPos = currentQuestion + 1;
    setEndTime();
    updateKey("isStart", false);
    if (!timeoutHint.current) {
      timeoutHint.current = setTimeout(() => {
        updateKey("isHint", true);
        timoutQuestionRef.current = setTimeout(() => {
          if (isEndQuestion) {
            makeFinshGame();
          } else {
            makeNextQuestion(nextQuestionPos);
          }
        }, DELAY_NEXT_QUESTION);
      }, DELAY_SHOW_HINT);
    }
  };

  const makeNextQuestion = (nextQuestionPos: number) => {
    if (!isEndQuestion) {
      updateKey("currentQuestion", nextQuestionPos);
    } else {
      makeFinshGame();
    }
    resetCountDown();
    resetState();
    updateKey("isStart", true);
    updateKey("isHint", false);
    resetTimeout();
  };

  const resetTimeout = () => {
    clearTimeout(timoutQuestionRef.current);
    clearTimeout(timeoutHint.current);
    timoutQuestionRef.current = null;
    timeoutHint.current = null;
  };

  const makeFinshGame = async () => {
    updateKey("isStart", false);
    const results: Array<{
      id: number;
      answer: string;
      time_remaining: number;
    }> = Object.values(mergeDataRef.current || {}) as any;

    const responseScore = await GamePlayService.submitResult({
      results,
      is_first_time_played: false,
      user_id: userId,
    });
    if (!userId) {
      cachingData({ results });
    } else {
      minusTurn?.();
    }
    navigate(QUIZ_SCREENS.ResultScreen.path, {
      state: {
        score: responseScore?.score || 0,
        correctAnswers: responseScore?.correctAnswers || 0,
        isWinning: responseScore?.isWinning,
        total: questions?.length || 0,
      },
    });
  };

  useEffect(() => {
    if (tick === 0) {
      resetTimeout();
      if (!isEndQuestion) {
        endQuestionAnswer(values.currentQuestion);
      } else {
        timeoutHint.current = setTimeout(() => {
          updateKey("isHint", true);
          timoutQuestionRef.current = setTimeout(() => {
            makeFinshGame();
          }, DELAY_NEXT_QUESTION);
        }, DELAY_SHOW_HINT);
      }
    }
  }, [tick, values.currentQuestion]);

  const handleToggleHint = useCallback(() => {
    updateKey("isHint", false);
    makeNextQuestion(values.currentQuestion + 1);
    resetTimeout();
  }, [values.currentQuestion]);

  const handleChoiseAnwser = useCallback(
    (answerValue: string) => {
      updateKey("answerChosen", answerValue);
      makeDataSaver({
        currentQuestion: values.currentQuestion,
        dataQuestion: values.dataQuestion,
        tick,
        answerValue,
      });
      endQuestionAnswer(values.currentQuestion);
    },
    [values.currentQuestion, values.dataQuestion, tick]
  );

  return {
    tick,
    ...values,
    loadingQuestion,
    onChoiseAnwser: handleChoiseAnwser,
    onToggleHint: handleToggleHint
  };
};

export default useGamePlayData;
