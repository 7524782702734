import COLORS from "utils/CommonColors";
import { StyleSheet } from "utils/StyleSheet";

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: COLORS.violet1000,
    paddingHorizontal: 16,
    paddingVertical: 12,
  }
});

export default styles;
