import { StyleSheet } from "utils/StyleSheet";

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  flex1: {
    flex: 1,
  },
  mt20: {
    marginTop: 20,
  },
  pH24:{ 
    paddingHorizontal: 24 
  }
});

export default styles;
