import { StyleSheet } from "utils/StyleSheet";
import COLORS from "utils/CommonColors";

const styles = StyleSheet.create({
  medal: {
    justifyContent: "center",
    alignItems: "center",
    marginTop: 6,
  },
  text: {
    fontSize: 16,
    fontWeight: "700",
    lineHeight: 24,
    color: COLORS.white,
    textAlign: "center",
    textShadowColor: COLORS.vividOrange,
    textShadowOffset: { width: 2, height: 2 },
  },
  rank: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 8,
    justifyContent: "center",
  },
  space: {
    marginBottom: 12,
  },
});

export default styles;
