import React from "react";
import { View, Image, ActivityIndicator } from "react-native";
import { ButtonQuiz } from "apps/crypto-quiz/components/Button";
import { QuizImages } from "apps/crypto-quiz/utils";
import { NavBar, QText } from "apps/crypto-quiz/components";

import styles from "./styles";
import { ShareSuccessPopup } from "../ShareSuccessPopup";
import Locale from "locale";

type Props = {
  onGoCharts: () => void;
  onPlayNow: () => void;
  onGoGamePassed: () => void;
  onBack: VoidFunction;
  userId?: string;
  shareSuccess: boolean;
  lang: string;
  turn: number;
  loadingTurn?: boolean;
  loadingUser?: boolean;
};

const LandingWrapper = ({
  onGoCharts,
  onPlayNow,
  onGoGamePassed,
  onBack,
  userId,
  shareSuccess,
  lang,
  turn = 0,
  loadingTurn,
  loadingUser,
}: Props) => {
  return (
    <>
      <NavBar title="Coinlympia" onPressBack={onBack} />
      <View style={styles.container}>
        <Image
          style={styles.imageBg}
          source={{
            uri:
              lang === "vi" ? QuizImages.bgLandingVi : QuizImages.bgLandingEn,
          }}
        />
        <Image style={styles.bottomBg} source={QuizImages.bottomLanding} />

        <View style={styles.actionWrapper}>
          {!loadingTurn && !loadingUser ? (
            <>
              {userId && (
                <View style={styles.turnContent}>
                  <QText bold style={styles.turnMessage}>
                    {Locale.translate("q_you_have")}{" "}
                    <QText style={styles.turn}>{turn}</QText>{" "}
                    {Locale.translate("turn")}
                  </QText>
                </View>
              )}
              <ButtonQuiz
                titleStyle={styles.playNowTitle}
                onPress={onPlayNow}
                block
                height={42}
              >
                <View style={styles.playNow}>
                  {turn || !userId ? (
                    <QText>{Locale.translate("play_now")}</QText>
                  ) : (
                    <QText>{Locale.translate("share_to_play")}</QText>
                  )}
                </View>
              </ButtonQuiz>
              <View style={styles.actionMore}>
                {userId ? (
                  <>
                    <ButtonQuiz
                      onPress={onGoCharts}
                      height={42}
                      style={styles.btnMore}
                      titleStyle={styles.btnMoreText}
                      variant="dark"
                      title={Locale.translate("ranking_chart")}
                    />
                    <View style={styles.actionMoreSpace} />
                    <ButtonQuiz
                      onPress={onGoGamePassed}
                      height={42}
                      style={styles.btnMore}
                      titleStyle={styles.btnMoreText}
                      variant="dark"
                      title={Locale.translate("passed_game")}
                    />
                  </>
                ) : (
                  <ButtonQuiz
                    onPress={onGoCharts}
                    height={42}
                    style={styles.btnMore}
                    titleStyle={styles.btnMoreText}
                    variant="dark"
                    title={Locale.translate("ranking_chart")}
                  />
                )}
              </View>
            </>
          ) : (
            <ActivityIndicator />
          )}
        </View>

        <ShareSuccessPopup
          onPlayNow={onPlayNow}
          onGoGamePassed={onGoGamePassed}
          isOpen={shareSuccess}
        />
      </View>
    </>
  );
};

export default React.memo(LandingWrapper);
