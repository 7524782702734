import { StyleSheet } from "utils/StyleSheet";
const styles = StyleSheet.create({
  container: {
    display: "flex",
    flexDirection: "row",
    marginTop: 16,
  },
  item: {
    flex: 1,
    display: "flex",
    alignItems: "center",
    textAlign: "center",
  },
  title: {
    fontFamily: "Arial",
    fontWeight: "900",
    lineHeight: 22,
  },
  rating: {
    fontWeight: "900",
    lineHeight: 19,
  },
});

export default styles;
