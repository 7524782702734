import React from "react";
import { ScrollView } from "react-native";
import { NavBar } from "apps/crypto-quiz/components";
import HeaderBoard from "./components/HeaderBoard";
import QuestionBoard from "./components/QuestionBoard";

import styles from "./styles";
import GamePlayProvider from "apps/crypto-quiz/contexts/GamePlayContext";
import HintModal from "./components/HintModal";

const GamePlayScreen = () => {
  return (
    <>
      <NavBar hiddenIconLeft hiddenIconRight/>
      <GamePlayProvider>
        <ScrollView
          style={styles.container}
          showsVerticalScrollIndicator={false}
          showsHorizontalScrollIndicator={false}
        >
          <HeaderBoard />
          <QuestionBoard />
        </ScrollView>
        <HintModal />
      </GamePlayProvider>
    </>
  );
};

export default GamePlayScreen;
