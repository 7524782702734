import React from "react";
import { useGamePlayContext } from "apps/crypto-quiz/contexts/GamePlayContext";
import HintModal from "./HintModal";

const Container = () => {
  const { isShowHint: isOpen, dataQuestion, currentQuestion, onToggleHint } = useGamePlayContext();

  const handleClose = () => {
    onToggleHint?.(false);
  };

  const composeProps = {
    isOpen,
    hint: dataQuestion?.[currentQuestion]?.question_hint,
    onClose: handleClose,
  };

  return (
    <HintModal {...composeProps} />
  );
};

export default Container;
