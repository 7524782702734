import React from "react";
import { Image, View } from "react-native";
import styles from "./styles";
import { COLORS } from "@remitano/remitano-ui-kit";
import { ProcessCircle, QText } from "apps/crypto-quiz/components";
import { QuizImages } from "apps/crypto-quiz/utils";
import { LIMIT_TIMER } from "apps/crypto-quiz/contexts/GamePlayContext";

type Props = {
  tick?: number;
  isStart: boolean;
  turn: number;
  isShowHint?: boolean
};

const HeaderBoard = ({ tick = LIMIT_TIMER, isStart, turn, isShowHint }: Props) => {
  return (
    <View>
      <View style={styles.headWrapper}>
        <ProcessCircle
          width={48}
          reset={tick === LIMIT_TIMER}
          stroke={tick < 5 ? COLORS.red500 : COLORS.green500}
          height={48}
          isAnimate={tick !== 0}
          strokeWidth={3}
          duration={LIMIT_TIMER * 1000}
          percent={isStart || tick === 0 ? 100 : 0}
        >
          <QText size={24} bold>
            {tick}
          </QText>
        </ProcessCircle>
      </View>
      {turn ? (
        <View style={styles.headIndex}>
          <Image
            style={styles.iconHeart}
            source={{ uri: QuizImages.heartIcon }}
          />
          <QText style={styles.numberIndex}>{turn}</QText>
        </View>
      ) : (
        <></>
      )}
    </View>
  );
};

export default React.memo(HeaderBoard);
