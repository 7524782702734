import React, {
  PropsWithChildren,
  useContext,
  useEffect,
  useMemo,
} from "react";
import useGamePlayData from "apps/crypto-quiz/hooks/useGamePlayData";
import { useNavigate } from "react-router-dom";
import QUIZ_SCREENS from "../screens/info";

export const LIMIT_TIMER = 15;
export const DELAY_NEXT_QUESTION = 10000;
export const DELAY_SHOW_HINT = 500;
export const GAME_PLAY_CACHING = "game-play-caching";

export type GamePlayContextValue = {
  tick: number;
  result?: string | null;
  answerChosen?: string | undefined;
  currentQuestion: number;
  dataQuestion: Questions;
  loadingQuestion?: boolean;
  isStart: boolean;
  onChoiseAnwser?: (answerText: string) => void;
  isShowHint?: boolean;
  onToggleHint?: (status: boolean) => void;
};

export const GamePlayContext = React.createContext<GamePlayContextValue>({
  tick: LIMIT_TIMER,
  dataQuestion: [],
  isStart: false,
  currentQuestion: 0,
});

type Props = PropsWithChildren;

const GamePlayProvider = ({ children }: Props) => {
  const navigation = useNavigate();

  const {
    tick,
    answerChosen,
    result,
    isStart,
    dataQuestion,
    currentQuestion,
    onChoiseAnwser,
    isHint,
    onToggleHint,
    loadingQuestion,
  } = useGamePlayData();

  useEffect(() => {
    if (localStorage.getItem(GAME_PLAY_CACHING)) {
      navigation(QUIZ_SCREENS.LandingScreen.path);
    }
  }, []);

  const valueContext: GamePlayContextValue = useMemo(
    () => ({
      tick,
      answerChosen,
      result,
      isStart,
      currentQuestion,
      dataQuestion,
      onChoiseAnwser,
      isShowHint: isHint,
      onToggleHint,
      loadingQuestion,
    }),
    [
      tick,
      isStart,
      answerChosen,
      result,
      currentQuestion,
      dataQuestion,
      isHint,
      loadingQuestion,
    ]
  );
  return (
    <GamePlayContext.Provider value={{ ...valueContext }}>
      {children}
    </GamePlayContext.Provider>
  );
};

export default GamePlayProvider;

export const useGamePlayContext = () => useContext(GamePlayContext);
