import { Spacing } from "apps/crypto-quiz/utils";
import { StyleSheet } from "utils/StyleSheet";

const styles = StyleSheet.create({
  headWrapper: {
    flexDirection: "row",
    justifyContent: "center",
  },
  headIndex: {
    alignItems: "center",
    flexDirection: "row",
    position: "absolute",
    height: "100%",
    right: -5
  },
  numberIndex: {
    marginLeft: Spacing.XS,
  },
  iconHeart: {
    width: 24,
    height: 24,
  },
});

export default styles;
