import COLORS from "utils/CommonColors";
import { StyleSheet } from "utils/StyleSheet";

const styles = StyleSheet.create({
  title: {
    fontWeight: "700",
    color: "white",
    fontSize: 14,
    lineHeight: 16,
    textShadowColor: "rgba(0, 0, 0, 0.15)",
    textShadowOffset: {
      width: 1,
      height: 2,
    },
  },
  titleDisabled: {
    color: COLORS.violet800,
    textShadowOffset: {
      width: 0,
      height: 0,
    },
  },
  contentMask: {
    ...StyleSheet.absoluteFillObject,
    alignItems: "center",
    justifyContent: "center",
  },
  btn: {
    borderRadius: 8,
    borderBottomWidth: 0,
    borderWidth: 2,
    shadowOffset: { width: 0, height: 4 },
    shadowOpacity: 0.9,
    shadowRadius: 1,
    elevation: 9,
  },
  btnPrimary: {
    backgroundColor: COLORS.purple,
    borderColor: COLORS.lightPurple200,
    shadowColor: COLORS.shadowBoxBorder,
  },
  btnDark: {
    backgroundColor: COLORS.violet1000,
    borderColor: COLORS.shadowButtonDarkBorder,
    shadowColor:  COLORS.shadowButtonDarkBorder,
  },
  contentMaskDisabled: {
    ...StyleSheet.absoluteFillObject,
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: COLORS.violet500,
    borderRadius: 8,
    borderWidth: 2,
    borderColor: COLORS.shadowButton,
    shadowColor: COLORS.shadowButtonBorder,
  },
});

export default styles;
