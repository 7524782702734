import React from "react";
import { ScrollView } from "react-native";
import DeviceInfo from "utils/DeviceInfo";

import { ModalQuiz, QText } from "apps/crypto-quiz/components";

import styles from "./styles";

type Props = {
  isShow: boolean;
  onCloseModal: () => void;
  content: string;
};

const FullDescriptionPopup = ({
  isShow,
  onCloseModal,
  content = "",
}: Props) => {
  return (
    <ModalQuiz
      width={DeviceInfo.CONTENT_WIDTH - 80}
      isOpen={isShow}
      onClose={onCloseModal}
      bgContent={"#3c2b4e"}
    >
      <ScrollView>
        <QText style={styles.textContent}>{content}</QText>
      </ScrollView>
    </ModalQuiz>
  );
};

export default React.memo(FullDescriptionPopup);
