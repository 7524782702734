import RemitanoBrowser from "utils/RemitanoBrowser";
import { REMITANO_DOMAIN } from "utils/RequestHelper";


class CommonHelper {
  redirectUrl = (isRemiBrowser: boolean, url: string, inHref?: boolean) => {
    if (isRemiBrowser) {
      RemitanoBrowser.openUrl({ url: url });
    } else if (inHref && window.location) {
      window.location.href = `${REMITANO_DOMAIN}${url}`;
    } else {
      window.open(`${REMITANO_DOMAIN}${url}`, "_blank");
    }

    return;
  };
}

export default new CommonHelper();
