import moment from "moment";

const runningTasks = new Map();

class TimeHelper {
  state = {
    runOnceInTimestamp: 0,
  };

  waitDelay = (miliseconds: number) => new Promise((resolve) => {
    setTimeout(() => {
      resolve(true);
    }, miliseconds);
  });

  runOnceIn = (id = "null", miliseconds: number) => (func: any) => {
    const isRunning = runningTasks.get(id);
    if (!isRunning) {
      runningTasks.set(id, true);
      setTimeout(() => {
        runningTasks.delete(id);
      }, miliseconds);
      if (typeof func === "function") { return func(); }
    }
  };

  getTimeAgo = (time?: number) => {
    if (!time) return "N/A";
    const _momentTime = moment(String(time).length < 13 ? time * 1000 : time);
    if (moment().diff(_momentTime, "hour") > 12) {
      return _momentTime.format("DD/MM/YYYY HH:mm");
    }
    return _momentTime.fromNow();
  };
}

export default new TimeHelper();
