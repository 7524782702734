import { ReactComponent as _aura } from "./aura.svg";
import { ReactComponent as _aura2 } from "./aura2.svg";
import { ReactComponent as _btc } from "./btc.svg";
import { ReactComponent as _btnactive } from "./btn_active.svg";
import { ReactComponent as _btninactive } from "./btn_inactive.svg";
import { ReactComponent as _buttoncheckreward } from "./button-check-reward.svg";
import { ReactComponent as _buttonplay } from "./button_play.svg";
import { ReactComponent as _buttonplayhover } from "./button_play_hover.svg";
import { ReactComponent as _buttonplaypress } from "./button_play_press.svg";
import { ReactComponent as _copy } from "./copy.svg";
import { ReactComponent as _doge } from "./doge.svg";
import { ReactComponent as _goodluck } from "./good_luck.svg";
import { ReactComponent as _heart } from "./heart.svg";
import { ReactComponent as _icarrowright } from "./ic_arrow-right.svg";
import { ReactComponent as _icback } from "./ic_back.svg";
import { ReactComponent as _icclose } from "./ic_close.svg";
import { ReactComponent as _icclose2 } from "./ic_close2.svg";
import { ReactComponent as _icemojistart } from "./ic_emoji_start.svg";
import { ReactComponent as _icgamehub } from "./ic_gamehub.svg";
import { ReactComponent as _ichandbook } from "./ic_handbook.svg";
import { ReactComponent as _icheart } from "./ic_heart.svg";
import { ReactComponent as _icinfo } from "./ic_info.svg";
import { ReactComponent as _icleaderboard } from "./ic_leaderboard.svg";
import { ReactComponent as _icmission } from "./ic_mission.svg";
import { ReactComponent as _icmybag } from "./ic_mybag.svg";
import { ReactComponent as _icquizhome } from "./ic_quiz_home.svg";
import { ReactComponent as _icshare } from "./ic_share.svg";
import { ReactComponent as _icsharequiz } from "./ic_share_quiz.svg";
import { ReactComponent as _kai } from "./kai.svg";
import { ReactComponent as _keyboard } from "./keyboard.svg";
import { ReactComponent as _medal1 } from "./medal1.svg";
import { ReactComponent as _medal2 } from "./medal2.svg";
import { ReactComponent as _medal3 } from "./medal3.svg";
import { ReactComponent as _mysterybox } from "./mystery-box.svg";
import { ReactComponent as _newbtnactive } from "./new_btn_active.svg";
import { ReactComponent as _newbtninactive } from "./new_btn_inactive.svg";
import { ReactComponent as _newbuttonplay } from "./new_button_play.svg";
import { ReactComponent as _newbuttonplaypress } from "./new_button_play_press.svg";
import { ReactComponent as _newdoge } from "./new_doge.svg";
import { ReactComponent as _newgoodluck } from "./new_good_luck.svg";
import { ReactComponent as _newicgamehub } from "./new_ic_gamehub.svg";
import { ReactComponent as _newicleaderboard } from "./new_ic_leaderboard.svg";
import { ReactComponent as _newicmybag } from "./new_ic_mybag.svg";
import { ReactComponent as _newicshare } from "./new_ic_share.svg";
import { ReactComponent as _newkai } from "./new_kai.svg";
import { ReactComponent as _newmorebutton } from "./new_more_button.svg";
import { ReactComponent as _newmysterybox } from "./new_mystery-box.svg";
import { ReactComponent as _newnext } from "./new_next.svg";
import { ReactComponent as _newprev } from "./new_prev.svg";
import { ReactComponent as _newshib } from "./new_shib.svg";
import { ReactComponent as _newspin } from "./new_spin.svg";
import { ReactComponent as _newtoken } from "./new_token.svg";
import { ReactComponent as _newusdt } from "./new_usdt.svg";
import { ReactComponent as _newvoucher } from "./new_voucher.svg";
import { ReactComponent as _next } from "./next.svg";
import { ReactComponent as _otherrank } from "./other_rank.svg";
import { ReactComponent as _prevdisable } from "./prev-disable.svg";
import { ReactComponent as _prev } from "./prev.svg";
import { ReactComponent as _rand } from "./rand.svg";
import { ReactComponent as _rank1 } from "./rank1.svg";
import { ReactComponent as _rank2 } from "./rank2.svg";
import { ReactComponent as _rank3 } from "./rank3.svg";
import { ReactComponent as _rank4 } from "./rank4.svg";
import { ReactComponent as _rank5 } from "./rank5.svg";
import { ReactComponent as _remitanologo } from "./remitano-logo.svg";
import { ReactComponent as _shareDefault } from "./shareDefault.svg";
import { ReactComponent as _shib } from "./shib.svg";
import { ReactComponent as _spin } from "./spin.svg";
import { ReactComponent as _stone } from "./stone.svg";
import { ReactComponent as _tableheader } from "./table-header.svg";
import { ReactComponent as _tokenimota } from "./token-imota.svg";
import { ReactComponent as _token } from "./token.svg";
import { ReactComponent as _usdt } from "./usdt.svg";
import { ReactComponent as _voucher } from "./voucher.svg";
import { ReactComponent as _wheel } from "./wheel.svg";

const Svgs: any = {
  "aura": _aura,
  "aura2": _aura2,
  "btc": _btc,
  "btn_active": _btnactive,
  "btn_inactive": _btninactive,
  "button-check-reward": _buttoncheckreward,
  "button_play": _buttonplay,
  "button_play_hover": _buttonplayhover,
  "button_play_press": _buttonplaypress,
  "copy": _copy,
  "doge": _doge,
  "good_luck": _goodluck,
  "heart": _heart,
  "ic_arrow-right": _icarrowright,
  "ic_back": _icback,
  "ic_close": _icclose,
  "ic_close2": _icclose2,
  "ic_emoji_start": _icemojistart,
  "ic_gamehub": _icgamehub,
  "ic_handbook": _ichandbook,
  "ic_heart": _icheart,
  "ic_info": _icinfo,
  "ic_leaderboard": _icleaderboard,
  "ic_mission": _icmission,
  "ic_mybag": _icmybag,
  "ic_quiz_home": _icquizhome,
  "ic_share": _icshare,
  "ic_share_quiz": _icsharequiz,
  "kai": _kai,
  "keyboard": _keyboard,
  "medal1": _medal1,
  "medal2": _medal2,
  "medal3": _medal3,
  "mystery-box": _mysterybox,
  "new_btn_active": _newbtnactive,
  "new_btn_inactive": _newbtninactive,
  "new_button_play": _newbuttonplay,
  "new_button_play_press": _newbuttonplaypress,
  "new_doge": _newdoge,
  "new_good_luck": _newgoodluck,
  "new_ic_gamehub": _newicgamehub,
  "new_ic_leaderboard": _newicleaderboard,
  "new_ic_mybag": _newicmybag,
  "new_ic_share": _newicshare,
  "new_kai": _newkai,
  "new_more_button": _newmorebutton,
  "new_mystery-box": _newmysterybox,
  "new_next": _newnext,
  "new_prev": _newprev,
  "new_shib": _newshib,
  "new_spin": _newspin,
  "new_token": _newtoken,
  "new_usdt": _newusdt,
  "new_voucher": _newvoucher,
  "next": _next,
  "other_rank": _otherrank,
  "prev-disable": _prevdisable,
  "prev": _prev,
  "rand": _rand,
  "rank1": _rank1,
  "rank2": _rank2,
  "rank3": _rank3,
  "rank4": _rank4,
  "rank5": _rank5,
  "remitano-logo": _remitanologo,
  "shareDefault": _shareDefault,
  "shib": _shib,
  "spin": _spin,
  "stone": _stone,
  "table-header": _tableheader,
  "token-imota": _tokenimota,
  "token": _token,
  "usdt": _usdt,
  "voucher": _voucher,
  "wheel": _wheel,
};

export default Svgs;
